import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, InterceptorOption, LoadingType } from "../../../types/CommonTypes";
import { useAppSelector } from "./../redux-hooks";
import { getIVRById, getIVRList, saveIVR, updateIVR, submitIVRData, deleteIVR, fetchIvrByPatientId, fetchIvrByPatientInfo, cancelIVR } from "../../../service/IVRService";
import { updateElementValue } from "../../../utils/updateElementValue";
import { fetchProductsByUserRole, fetchProductsIncludingInactiveByUserRole } from "../../../service/ProductService";
import { fetchPracticesByUserRole } from "../../../service/PracticeService";
import { ProductInfo } from "../product/productListSlice";
import { PracticeInfo } from "../practiceSlice";
import { RootState } from "../../store";

export interface IvrListDTO {
  ivrId: number;
  productId: number;
  practiceName: string;
  patientFirstName: string;
  patientMiddleName: string;
  patientLastName: string;
  productName: string;
  providerName: string;
  verificationStatus: string;
  dateOfService: string;
  auditTimestamp: string;
  locationId: number;
  practiceProductId: number;
  practiceId: number;
  patientCode: string;
}

export interface PaginationIvrList {
  content: IvrListDTO[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type SearchIVRListRequest = {
  productId: number[] | null;
  practiceId: number[] | null;
  patientFirstName: string;
  patientLastName: string;
  verificationStatus: string[] | null;
  ivrNumber: string | null;
  //cs-382
  fromDate: string | undefined;
  toDate: string | undefined;
  //
  currentPage: number;
  itemsPerPage: number;
}

export type AdditionalNote = {
  xrefTable: string;
  xrefId: number | undefined;
  noteType: string;
  content: string;
}

export type IVRObjectInfo = {
  ivrId?: number;
  ivrNumber?: string;
  practiceId: number;
  productId: number;
  practiceProductId: number;
  providerId: number;
  locationId: number;
  macId: number;
  dateOfService: string;
  benefitVerificationType: string;
  patient: {
    patientCode: string;
    patientId: number;
    isPatientEdited: boolean;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    gender: string;
    patientInfo: {
      infoType: string;
      isEdited: boolean;
      patientinfoId: number | null;
      patientInfoData?: {
        patientStreetAddress: string;
        patientUnitNumber: string;
        patientCity: string;
        patientState: string;
        patientZip: string;
      };
      primaryInsuranceData?: {
        insuranceName: string;
        policy: string;
        isPolicyUnderDifferentName: boolean;
        policyHolderFirstName: string;
        policyHolderMiddleName: string;
        policyHolderLastName: string;
        policyHolderDOB: string;
        insuranceType: string;
        documentIds: number[];
      };
      secondaryInsuranceData?: {
        secondaryInsuranceName: string;
        secondaryPolicy: string;
        isSecondaryPolicyUnderDifferentName: boolean;
        secondaryPolicyHolderFirstName: string;
        secondaryPolicyHolderMiddleName: string;
        secondaryPolicyHolderLastName: string;
        secondaryPolicyHolderDOB: string;
        secondaryInsuranceType: string;
        documentIds: number[];
      };
      documentIds?: number[];
    }[];
  };
  ivrInfo: {
    patientConsentInfo: {
      isPatientConsentSigned: string;
      patientSignDate: string;
    };
    treatmentInfo: {
      currentlyInNursingFacility: boolean;
      daysAdmittedToFacility: string;
      currentlyUnderSurgicalGlobalPeriod: boolean;
      surgicalProcedureDate: string;
      surgicalProcedureCodes: string;
      placesOfService: string[];
      // productId: number;
      // practiceProductId: number;
      // productSizes: string[];
      products: Product[];
    };
    woundInfo: {
      woundData: {
        woundType: string;
        cpt: string;
        lCode: string;
        eCode: string;
        iCode: string;
        otherDxCodes:string;
        noOfApplications: string;
        length: number;
        width: number;
        depth: number;
        totalSize: string;
      }[];
      totalSizeOfAllWounds: number;
    };
    providerInfo: {
      practiceId: number;
      practiceName: string;
      date: string;
      signature: string;
      name: string;
      provider: {
        providerName: string;
        npi: string;
        ptan: string;
        taxId: string;
      };
    };
    additionalNotes: {
      xrefTable: string;
      xrefId: number | undefined;
      noteType: string;
      content: string;
      isFlag:string|null;
    };
    
  };
};

export type IVRFetchObjectInfo = {
  ivrId?: number;
  practiceId: number;
  productId: number;
  providerId: number;
  locationId: number;
  macId: number;
  dateOfService: string;
  verificationStatus: string;
  benefitVerificationType: string;
  patient: {
    patientCode: string;
    patientId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    gender: string;
    patientInfo: {
      infoType: string;
      patientinfoId: number | null;
      patientinfoData?: {
        patientStreetAddress?: string;
        patientUnitNumber?: string;
        patientCity?: string;
        patientState?: string;
        patientZip?: string;
        ivrDocumentListDTOs?: {
          documentId: number;
          documentName: string;
          documentType: string;
          auditTimestamp: string;
          size: number;
        }[];
        insuranceName?: string;
        policy?: string;
        isPolicyUnderDifferentName?: boolean | string;
        policyHolderFirstName?: string;
        policyHolderMiddleName?: string;
        policyHolderLastName?: string;
        policyHolderDOB?: string;
        insuranceType?: string[];
      };
    }[];
  };
  ivrInfo: {
    patientConsentInfo: {
      isPatientConsentSigned: boolean;
    };
    treatmentInfo: {
      currentlyInNursingFacility: boolean | string;
      currentlyUnderSurgicalGlobalPeriod: boolean | string;
      surgicalProcedureDate: string;
      surgicalProcedureCodes: string;
      placesOfService: string[];
      productId: number;
      productSizes: string[];
    };
    woundInfo: {
      woundData: {
        woundType: string;
        cpt: string;
        lCode: string;
        eCode: string;
        iCode: string;
        otherDxCodes: string;
        totalSize: string;
      }[];
      totalSizeOfAllWounds: number;
    };
    providerInfo: {
      practiceId: number;
      practiceName: string;
      date: string;
      signature: string;
      provider: {
        providerName: string;
        npi: string;
        ptan: string;
        taxId: string;
      };
    };
  };
};

export type IVRFetchObjectInfoWithInfoTypeData = Omit<IVRFetchObjectInfo, 'patient'> & {
  patient: Omit<IVRFetchObjectInfo['patient'], 'patientInfo'> & {
    patientInfo: {
      infoType: string;
      patientinfoId: number | null;
      patientinfoData: {
        documentIds: number[];
        ivrDocumentListDTOs? :{
          size: number;
          documentType: string;
          documentId: number;
          documentName: string
        }[];
      }
      infoTypeData?: {
        patientStreetAddress?: string;
        patientUnitNumber?: string;
        patientCity?: string;
        patientState?: string;
        patientZip?: string;
        ivrDocumentListDTOs?: {
          documentId: number;
          documentName: string;
          type: string;
          auditTimestamp: string;
          size: number;
        }[];
        insuranceName?: string;
        policy?: string;
        isPolicyUnderDifferentName?: boolean | string;
        policyHolderFirstName?: string;
        policyHolderMiddleName?: string;
        policyHolderLastName?: string;
        policyHolderDOB?: string;
        insuranceType?: string[];
      };
    }[];
  };
};

type ProductItem = {
  productItemId: number;
  sku: string;
  size: string;
  units: number;
  isSizeActive: boolean;
  inactiveDate: Date | null;
};

export type ProductListWithItems = {
  productId: number;
  macId: number;
  practiceProductId: number;
  providerId: number;
  label: string;
  sizes: ProductItem[];
}

export type Product = {
  productId: number;
  macId: number;
  practiceProductId: number;
  providerId: number;
  productSizes: string[];
};

type IVRListState = {
  IVRListInfo?: PaginationIvrList | null;
  IVRListFetchStatus?: LoadingType;
  IVRSaveStatus?: LoadingType;
  IVRSaveError?: string[];
  SaveIVRInfo?: IVRObjectInfo;
  IVRListUpdateStatus: LoadingType;
  IVRSubmitStatus?: LoadingType;
  SubmitIVRInfo?: IVRObjectInfo;
  IVRFetchStatus?: LoadingType;
  IVRObject?: IVRFetchObjectInfo;
  IVRDeleteStatus?: LoadingType;
  fetchIVRByPatientNameStatus: LoadingType;
  fetchIVRByPatientIdStatus : LoadingType;
  fetchProductsByRoleStatusCreateIVRSlice: LoadingType,
  productsByUserRoleCreateIVRSlice: ProductInfo[],
  getPracticesByRoleStatusCreateIVRSlice: LoadingType;
  practiceByUserRoleCreateIVRSlice?: PracticeInfo[] | null;
};

  const initialState: IVRListState = {
    IVRListInfo: undefined,
    IVRListFetchStatus: "idle",
    IVRSaveStatus: "idle",
    IVRListUpdateStatus: "idle",
    IVRSaveError: undefined,
    SaveIVRInfo: undefined,
    IVRSubmitStatus: "idle",
    SubmitIVRInfo: undefined,
    IVRFetchStatus: "idle",
    IVRObject: undefined,
    IVRDeleteStatus: "idle",
    fetchIVRByPatientNameStatus: 'idle',
    fetchIVRByPatientIdStatus: 'idle',
    fetchProductsByRoleStatusCreateIVRSlice: "idle",
    productsByUserRoleCreateIVRSlice:[],
    getPracticesByRoleStatusCreateIVRSlice: "idle",
    practiceByUserRoleCreateIVRSlice:[]
  };

  export type UpdateIVRObj = {
    ivrId : number,
    verificationStatus: string,
    note: NoteObj
  }
  
  export type NoteObj = {
    noteType : string,
    content: string,
    attachment: string,
    documentName: string,
    fileType: string
  }

  export type SearchPatientInfo = {
    patientFirstName : string | null,
    patientMiddleName : string | null;
    patientLastName: string | null
  };
  

  export const fetchIVRList = createAsyncThunk("fetchIVRList", async (data: SearchIVRListRequest) => {
    const response = await getIVRList(data);
    const v3Response = response.data;
    return v3Response;
});

export const fetchIVRById = createAsyncThunk("fetchIVRById", async (id: string) => {
  const response = await getIVRById(id);
  const v3Response = response.data;
  return v3Response;
});

export const saveIVRData = createAsyncThunk("saveIVRData", async (data: IVRObjectInfo, { rejectWithValue }) => {
  try{
  const response = await saveIVR(data);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});
// export const saveIVRData = createAsyncThunk<
//   any,
//   { dataObj: IVRObjectInfo; interceptorOption?: InterceptorOption },
//   { state: RootState; rejectValue: string }
// >("saveIVR", async (data, { rejectWithValue }) => {
//   const { dataObj, interceptorOption } = data;
//   try {
//     const response = await saveIVR(dataObj);
//     return response.data;
//   } catch (error) {
//     //@ts-ignore
//     return rejectWithValue(error?.response?.data);
//   }
// });

  export const updateIVRRespond = createAsyncThunk("updateIVRRespond", async(data: UpdateIVRObj | null) => {
    const response = await updateIVR(data)
    const v3Response = response.data;
    return v3Response.data;
  });

  export const submitIVR = createAsyncThunk("submitIVR", async (data: IVRObjectInfo, { rejectWithValue }) => {
    try{
    const response = await submitIVRData(data);
    return response.data;
    }catch(error){
      console.log(error);
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const deleteIVRById = createAsyncThunk("deleteIVR", async (id: string) => {
    const response = await deleteIVR(id);
    const v3Response = response.data;
    return v3Response;
  });

  export const cancelIVRById = createAsyncThunk("cancelIVR", async (id: string) => {
    const response = await cancelIVR(id);
    const v3Response = response.data;
    return v3Response;
  });
  export const searchIVRByPatientInfo = createAsyncThunk("searchIVRByPatientInfo", async (patientInfo: string) => {
    const response = await fetchIvrByPatientInfo(patientInfo);
    const v3Response = response.data;
    return v3Response;
  });

  export const searchIVRByPatientId = createAsyncThunk("searchIVRByPatientId", async (patientId: number) => {
    const response = await fetchIvrByPatientId(patientId);
    const v3Response = response.data;
    return v3Response;
  });

  export const fetchProductListByUserRoleCreateIVRSlice = createAsyncThunk("fetchProductListByUserRoleCreateIVRSlice", async (includeProductPrice: boolean) => {
    const response = await fetchProductsByUserRole(includeProductPrice);
    const v3Response = response.data
    return v3Response.data;
  });

  export const fetchProductListIncludingInactiveByUserRoleCreateIVRSlice = createAsyncThunk("fetchProductListIncludingInactiveByUserRoleCreateIVRSlice", async ({ includeProductPrice, executedOnly }: { includeProductPrice: boolean; executedOnly: boolean }) => {
    const response = await fetchProductsIncludingInactiveByUserRole(includeProductPrice, executedOnly);
    const v3Response = response.data
    return v3Response.data;
  });
  
  export const getPracticeListByUserRoleCreateIVRSlice = createAsyncThunk('getPracticeListByUserRoleCreateIVRSlice', async ( isActivePracticeOnly?: boolean ) => {
    const response = await fetchPracticesByUserRole(isActivePracticeOnly);
    const v3Response = response.data;
    return v3Response.data;
  });

  const IVRListSlice = createSlice({
    name: "IVRList",
    initialState,
    reducers: {
      updateElementInCreateIVRSlice : updateElementValue,
      resetIVRData: (state: IVRListState) => {
        return {
          ...state,
          ...initialState,
        };
      },
      resetIVRList: (state: IVRListState) => {
        state.IVRListInfo = undefined;
        state.IVRListFetchStatus = "idle";
        state.fetchProductsByRoleStatusCreateIVRSlice = "idle";
        state.productsByUserRoleCreateIVRSlice = [];
        state.getPracticesByRoleStatusCreateIVRSlice = "idle";
        state.practiceByUserRoleCreateIVRSlice = [];
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchIVRList.pending, (state) => {
          state.IVRListFetchStatus = "loading";
        })
        .addCase(fetchIVRList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.IVRListFetchStatus = "success";
          state.IVRListInfo = action.payload.data;
        })
        .addCase(fetchIVRList.rejected, (state, action) => {
          state.IVRListFetchStatus = 'error';
        })
        .addCase(saveIVRData.pending, (state) => {
          state.IVRSaveStatus = "loading";
        })
        .addCase(saveIVRData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.IVRSaveStatus = "success";
          state.SaveIVRInfo = action.payload.data;
        })
        .addCase(saveIVRData.rejected, (state, action: any) => {
          state.IVRSaveStatus = 'error';
          state.IVRSaveError = [action.error.message];
        })
        .addCase(fetchIVRById.pending, (state) => {
          state.IVRFetchStatus = "loading";
        })
        .addCase(fetchIVRById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.IVRFetchStatus = "success";
          state.IVRObject = action.payload.data;
        })
        .addCase(fetchIVRById.rejected, (state, action) => {
          state.IVRFetchStatus = 'error';
        })
        .addCase(updateIVRRespond.pending, (state, action) => {
          state.IVRListUpdateStatus = "loading"
        })
        .addCase(updateIVRRespond.fulfilled, (state, action) => {
          state.IVRListUpdateStatus = "success"
        })
        .addCase(updateIVRRespond.rejected, (state, action) => {
          state.IVRListUpdateStatus = "error"
        })
        .addCase(submitIVR.pending, (state, action) => {
          state.IVRSubmitStatus = "loading"
        })
        .addCase(submitIVR.fulfilled, (state, action) => {
          state.IVRSubmitStatus = "success";
          state.SubmitIVRInfo = action.payload.data;
        })
        .addCase(submitIVR.rejected, (state, action) => {
          state.IVRSubmitStatus = "error"
        })
        .addCase(deleteIVRById.pending, (state, action) => {
          state.IVRDeleteStatus = "loading"
        })
        .addCase(deleteIVRById.fulfilled, (state, action) => {
          state.IVRDeleteStatus = "success";
        })
        .addCase(deleteIVRById.rejected, (state, action) => {
          state.IVRDeleteStatus = "error"
        })
        .addCase(cancelIVRById.pending, (state, action) => {
          state.IVRDeleteStatus = "loading"
        })
        .addCase(cancelIVRById.fulfilled, (state, action) => {
          state.IVRDeleteStatus = "success";
        })
        .addCase(cancelIVRById.rejected, (state, action) => {
          state.IVRDeleteStatus = "error"
        })
        .addCase(searchIVRByPatientInfo.pending, (state, action) => {
          state.fetchIVRByPatientNameStatus = "loading"
        })
        .addCase(searchIVRByPatientInfo.fulfilled, (state, action) => {
          state.fetchIVRByPatientNameStatus = "success";
        })
        .addCase(searchIVRByPatientInfo.rejected, (state, action) => {
          state.fetchIVRByPatientNameStatus = "error"
        })
        .addCase(searchIVRByPatientId.pending, (state, action) => {
          state.fetchIVRByPatientIdStatus = "loading"
        })
        .addCase(searchIVRByPatientId.fulfilled, (state, action) => {
          state.fetchIVRByPatientIdStatus = "success";
        })
        .addCase(searchIVRByPatientId.rejected, (state, action) => {
          state.fetchIVRByPatientIdStatus = "error"
        })
        .addCase(fetchProductListByUserRoleCreateIVRSlice.pending, (state) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = "loading";
        })
        .addCase(fetchProductListByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = "success";
          state.productsByUserRoleCreateIVRSlice = action.payload;
        })
        .addCase(fetchProductListByUserRoleCreateIVRSlice.rejected, (state, action) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = 'error';
        })
        .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.pending, (state) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = "loading";
        })
        .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = "success";
          state.productsByUserRoleCreateIVRSlice = action.payload;
        })
        .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.rejected, (state, action) => {
          state.fetchProductsByRoleStatusCreateIVRSlice = 'error';
        })
        .addCase(getPracticeListByUserRoleCreateIVRSlice.pending, (state, action) => {
          state.getPracticesByRoleStatusCreateIVRSlice = 'loading';
        })
        .addCase(getPracticeListByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
          state.getPracticesByRoleStatusCreateIVRSlice = 'success';
          state.practiceByUserRoleCreateIVRSlice = action.payload;
        })
        .addCase(getPracticeListByUserRoleCreateIVRSlice.rejected, (state, action) => {
          state.getPracticesByRoleStatusCreateIVRSlice = 'error';
        });
    },
  });

export const { resetIVRData, resetIVRList, updateElementInCreateIVRSlice } = IVRListSlice.actions;
export const useCreateIVRSlice = () => useAppSelector((state) => state.createIVRSlice);
export default IVRListSlice.reducer;