import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Divider, FormControl, FormGroup, Grid, IconButton, Typography, } from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import Card from "../../common/Card";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../../theme";
import {
  ArrowBack, ArrowForward, ControlPointOutlined, DarkModeOutlined, DeleteOutlineOutlined,
  LightModeOutlined, ModeOutlined, Save,
} from "@mui/icons-material";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { submitPracticeData, usePracticeRegistrationSlice, PracticeInfo, } from "../../../redux/slice/practice/practiceRegistrationSlice";
import PracticeLocationPopup from "./PracticeLocationPopup";
import ProviderPopup from "./ProviderPopup";
import Table from "../../common/CustomTable";
import Signature from "../../common/Signature";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import CustomNametField from "../../common/CustomNametField";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import CustomRadioButton from "../../common/CustomRadioButton";
import CustomAddressTextField from "../../common/CustomAddressTextField";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useCallback, useEffect, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useCodetablesSlice, getCodeTableValuesByTableName, CodeTable, } from "../../../redux/slice/codetableSlice";
import { initialState } from "./PracticeLocationPopup";
import { providerInitialState } from "./ProviderPopup";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { PracticeLocationInfo } from "../../../redux/slice/practice/practiceLocationSlice";
import { PracticeProviderInfo } from "../../../redux/slice/practice/practiceProvidersSlice";
import { fullName } from "../../../utils/Utility";
import CustomCheckboxGroup from "../../common/CustomCheckboxGroup";
import { MainErrorInfo, setMainErrorObj, hideMainErrorPopup, } from "../../../redux/slice/commonSlice";
import LegacyToolBar from "../../common/LegacyToolBar";
import SignaturePad, { SignatureData, } from "../../common/signature/SignaturePad";
import { FAILED_CODE, SUCCESS_CODE } from "../../../service/ServiceConstants";
import AuthorizedSignerPopup, { AuthorizedSignerInfo } from "./AuthorizedSignerPopup";
import CustomIconButton from "../../common/CustomIconButton";

interface StepContent {
  [key: number]: JSX.Element;
}

interface Row {
  providerName: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  ptan: string;
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const PracticeRegistration: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = React.useState(1);
  const totalSteps = 2;
  const { practiceRegistrationInfo } = usePracticeRegistrationSlice();
  const [showPracticesPopup, setShowPracticesPopup] = React.useState(false);
  const [showProviderPopup, setShowProviderPopup] = React.useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState,
    unregister,
  } = useForm<PracticeInfo>();
  const { errors } = formState;
  const [signatureData, setSignatureData] = React.useState("");
  const [provider, setProvider] = React.useState<PracticeProviderInfo[]>([]);
  const recaptchaRef: React.RefObject<any> = React.createRef();
  const [disabled, setDisabled] = React.useState(false);
  const [showCaptcha, setShowCaptcha] = React.useState(false);
  const [isRecaptchaValid, setIsRecaptchaValid] = React.useState(false);
  const [showCaptchaWarning, setShowCaptchaWarning] = React.useState(false);
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } =
    useCodetablesSlice();
  const [woundCareOptions, setWoundCareOptions] = React.useState<CodeTable[]>(
    []
  );
  const [placeOfService, setPlaceOfService] = React.useState<CodeTable[]>([]);
  const isPlaceOfServiceArray = Array.isArray(placeOfService);
  const [locationItemObject, setLocationItemObject] =
    React.useState<PracticeLocationInfo>(initialState);
  const [providerItemObject, setProviderItemObject] =
    React.useState<PracticeProviderInfo>(providerInitialState);
  const [locationIndex, setLocationIndex] = React.useState<
    number | undefined
  >();
  const [providerIndex, setProviderIndex] = React.useState<
    number | undefined
  >();
  const [showBillingAddressOption, setShowBillingAddressOption] =
    React.useState(false);
  const [insurancePayerList, setInsurancePayerList] = React.useState<
    CodeTable[]
  >([]);
  const formRef = React.useRef<HTMLFormElement>(null);
  // const [isBillerNotApplicable, setIsBillerNotApplicable] =
  //   React.useState(false);
  const [isWoundNotApplicable, setIsWoundNotApplicable] = React.useState(false);
  const [showCustomAlert, setShowCustomAlert] = React.useState(false);
  const [customAlertMessage, setCustomAlertMessage] = React.useState("");
  const [showAuthorizedSignerPopup, setShowAuthorizedSignerPopup] = React.useState(false);
  const [authorizedSignerData, setAuthorizedSignerData] = React.useState<AuthorizedSignerInfo>();
  const [officeContactChecked, setOfficeContactChecked]=React.useState<boolean>(false)
  const [accountInformationChecked, setAccountInformationChecked]=React.useState<boolean>(false)
  const defaultValues = useCallback(() => {
    if (practiceRegistrationInfo) {
      reset(practiceRegistrationInfo);
    }
  }, [practiceRegistrationInfo, reset]);

  const insurancePayerOptions = useMemo(() => {
    return insurancePayerList.map((item) => ({
      label: item.displayValue,
      value: item.keyName,
    }));
  }, [insurancePayerList]);

  // unmount
  useEffect(
    () => () => {
      dispatch(hideMainErrorPopup());
    },
    []
  );

  useEffect(() => {
    defaultValues();
  }, [practiceRegistrationInfo]);

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      setWoundCareOptions(
        getCodeTableValuesByTableName(allCodeTablesValuesMap, "WoundCare")
      );
      const sortedStateList = [
        ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "PlaceOfService"),
      ].sort((a, b) => {
        const matchA = a.displayValue.match(/\((\d+)/);
        const numA: number | null = matchA ? parseInt(matchA[1], 10) : null;
      
        const matchB = b.displayValue.match(/\((\d+)/);
        const numB: number | null = matchB ? parseInt(matchB[1], 10) : null;
      
        if (numA === null && numB === null) return 0;
        if (numA === null) return 1;
        if (numB === null) return -1;
        return numA - numB;
      });
      
      setPlaceOfService(sortedStateList);
      setInsurancePayerList(
        getCodeTableValuesByTableName(allCodeTablesValuesMap, "InsurancePayer")
      );
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && currentStep === 1) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [errors, currentStep]);
  //CS-561
  useEffect(()=>{

    const contactFirstName = getValues("contactFirstName")
    const contactLastName = getValues("contactLastName")
    const contactMiddleName = getValues("contactMiddleName")
    const contactPhone = getValues("contactPhone")
    const contactEMail = getValues("contactEMail")

    if(officeContactChecked === true){
      setValue("practiceRequestInfo.billerFirstName",contactFirstName)
      setValue("practiceRequestInfo.billerMiddleName",contactMiddleName)
      setValue("practiceRequestInfo.billerLastName",contactLastName)
      setValue("practiceRequestInfo.billerContactPhone",contactPhone)
      setValue("practiceRequestInfo.billerContactEmail",contactEMail)
    }
  },[officeContactChecked])
  useEffect(()=>{

    const apFirstName = getValues("apFirstName")
    const apLastName = getValues("apLastName")
    const apMiddleName = getValues("apMiddleName")
    const apPhone = getValues("apPhone")
    const apEmail = getValues("apEmail")

    if(accountInformationChecked === true){
      setValue("practiceRequestInfo.billerFirstName",apFirstName)
      setValue("practiceRequestInfo.billerMiddleName",apMiddleName)
      setValue("practiceRequestInfo.billerLastName",apLastName)
      setValue("practiceRequestInfo.billerContactPhone",apPhone)
      setValue("practiceRequestInfo.billerContactEmail",apEmail)
    }
  },[accountInformationChecked])

  //
  const handleSignatureChange = (data: SignatureData) => {
    setSignatureData(data.signature);
    setValue("practiceRequestInfo.customerSignature", data.signature);
    setValue("practiceRequestInfo.customerName", data.typedText);
  };

  const onSubmit = (data: PracticeInfo) => {
    if (!isRecaptchaValid) {
      setShowCaptcha(true);
      setShowCaptchaWarning(true);
      return;
    }

    if (data !== undefined) {
      data.practiceRequestInfo.customerName = "";
      data.practiceRequestInfo.customerSignature = "";
      handleSavePractice(data);
    }
    console.log("Practice Registrations:- ", data);

    // if (currentStep >= totalSteps && !isRecaptchaValid) {
    //   setShowCaptcha(true);
    //   setShowCaptchaWarning(true);
    //   return;
    // }

    // if (currentStep < totalSteps) {
    //   dispatch(hideMainErrorPopup());
    //   setCurrentStep(currentStep + 1);
    //   window.scrollTo(0, 0);
    // }

    // if (currentStep === totalSteps) {
    //   if (data !== undefined) {
    //     handleSavePractice(data);
    //   }
    // }
  };

  const onError = (_errors: any, e: any) => {
    if (currentStep < totalSteps) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const captchaOnChange = useCallback((response: any) => {
    try {
      if (response) {
        setIsRecaptchaValid(true);
        setShowCaptchaWarning(false);
        setDisabled(false);
      } else {
        setIsRecaptchaValid(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSavePractice = async (practiceInfoData: PracticeInfo) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    try {
      const response = await dispatch(submitPracticeData(practiceInfoData));
  
      if (response.payload && response.payload.message === SUCCESS_CODE) {
        navigate("/success");
      } else {
        let errorSentence = '';
        if ((response.payload.data.code) === FAILED_CODE) {
          errorSentence = response.payload.data.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }

        errorObj.title = "Submit Practice Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
      }
    } catch (error) {
      console.log("Error submitting practice data:", error);
      errorObj.title = "Error submitting practice data";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  // const handleBackClick = () => {
  //   if (currentStep > 1) {
  //     setCurrentStep(currentStep - 1);
  //     window.scrollTo(0, 0);
  //   }
  // };

  const clickShowPracticesPopup = () => {
    setShowPracticesPopup(true);
    if (
      getValues("practiceLocations") === undefined ||
      getValues("practiceLocations").length == 0 ||
      locationIndex == 0
    ) {
      setShowBillingAddressOption(true);
    }
  };

  const clickShowAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(true);
  };

  const clickCloseAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(false);
    setAuthorizedSignerData(undefined)

  };

  const clickClosePracticesPopup = () => {
    setShowPracticesPopup(false);
    setLocationItemObject(initialState);
    setLocationIndex(undefined);
    setShowBillingAddressOption(false);
  };

  const clickShowProviderPopup = () => {
    setShowProviderPopup(true);
  };

  const clickCloseProviderPopup = () => {
    setShowProviderPopup(false);
    setProviderItemObject(providerInitialState);
    setProviderIndex(undefined);
  };

  // Add practice start
  const columnsPractice: Column[] = [
    { id: "streetAddress", label: "Street Address", minWidth: 200 },
    { id: "unitNumber", label: "Unit Number", minWidth: 100 },
    { id: "city", label: "City", minWidth: 100 },
    { id: "state", label: "State ", minWidth: 100 },
    { id: "zipCode", label: "Zip", minWidth: 100 },
    { id: "isBillingAddress", label: "Billing Address", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100, align: "center" },
  ];

  const addPractice = (practiceLocation: PracticeLocationInfo) => {
    let practiceLocations = getValues("practiceLocations") || [];

    if (practiceLocation.isBillingAddress === "yes") {
      const hasBillingAddress = practiceLocations.some(
        (location) => location.isBillingAddress === "yes"
      );
    }

    if (locationIndex !== undefined) {
      const oldLocation = practiceLocations[locationIndex];
      const updatedLocation = { ...oldLocation, ...practiceLocation };
      updatePracticeLocation(locationIndex, updatedLocation);
    } else {
      practiceLocations = [...practiceLocations, practiceLocation];
      setValue("practiceLocations", practiceLocations);
    }
    setShowBillingAddressOption(
      practiceLocations.length > 0 &&
        !practiceLocations.some(
          (location) => location.isBillingAddress === "yes"
        )
    );
  };
  
  // Add practice end

  // Update practice location
  const updatePracticeLocation = (
    index: number,
    updatedLocation: PracticeLocationInfo
  ) => {
    let updatedLocations = getValues("practiceLocations");
    updatedLocations[index] = updatedLocation;
    setValue("practiceLocations", updatedLocations);
  };

  const transformPracticeLocations = (
    locations: { isBillingAddress: any }[]
  ) => {
    return locations.map((location: { isBillingAddress: any }) => ({
      ...location,
      isBillingAddress:
        location.isBillingAddress.toLowerCase() === "yes" ? "Yes" : "No",
    }));
  };

  // Add provider start
  const columnsProvider: Column[] = [
    { id: "providerName", label: "Provider Name", minWidth: 200 },
    { id: "npi", label: "Provider NPI", minWidth: 100 },
    { id: "ptan", label: "Provider PTAN", minWidth: 100 },
    { id: "taxId", label: "Provider Tax ID", minWidth: 100 },
    { id: "email", label: "Provider Email", minWidth: 200 },
    { id: "action", label: "Action", minWidth: 100, align: "center" },
  ];

  const handleProviderChange = (name: string, value: string) => {
    setProvider((prevPro) => ({
      ...prevPro,
      [name]: value,
    }));
  };

  const addProvider = (provider: PracticeProviderInfo) => {
    let practiceProviders = getValues("practiceProviders");
    if (providerIndex !== undefined) {
      const oldProvider = practiceProviders[providerIndex];
      const updatedProvider = { ...oldProvider, ...provider };
      updatePracticeProvider(providerIndex, updatedProvider);
    } else {
      if (practiceProviders)
        setValue("practiceProviders", [
          ...getValues("practiceProviders"),
          provider,
        ]);
      else setValue("practiceProviders", [provider]);
    }
  };
  // Add provider end

  const columnsSigner: Column[] = [
    { id: "fullName", label: "Full Name", minWidth: 200 },
    { id: "email", label: "email", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100, align: "center"},
  ];

  const addSigner = (signer: AuthorizedSignerInfo) => {
    let authorizedSigners = getValues("practiceRequestInfo.authorizedSigners");
    console.log(signer.index)
    if (signer.index || signer.index === 0) {
      authorizedSigners[signer.index] = signer;
      setValue("practiceRequestInfo.authorizedSigners", authorizedSigners);
    }
    else if (authorizedSigners)
      setValue("practiceRequestInfo.authorizedSigners", [
        ...getValues("practiceRequestInfo.authorizedSigners"),
        signer,
      ]);
    else setValue("practiceRequestInfo.authorizedSigners", [signer]);
  };

  // Update practice provider
  const updatePracticeProvider = (
    index: number,
    updatedProvider: PracticeProviderInfo
  ) => {
    let updatedProviders = getValues("practiceProviders");
    updatedProviders[index] = updatedProvider;
    setValue("practiceProviders", updatedProviders);
  };

  const handleEditActionLocation = (row: any, rowIndex: number) => {
    if (rowIndex === 0) {
      setShowBillingAddressOption(true);
    } else {
      setShowBillingAddressOption(false);
    }
    const editedRow = {
      ...row,
      isBillingAddress: row.isBillingAddress === "Yes" ? "yes" : "no",
    };
    setLocationIndex(rowIndex);
    setLocationItemObject(editedRow);
    clickShowPracticesPopup();
  };

  const handleDeleteActionLocation = (row: any, rowIndex: number) => {
    let addedPracticeLocations = getValues("practiceLocations");
    if (rowIndex >= 0 && rowIndex < addedPracticeLocations.length) {
      addedPracticeLocations.splice(rowIndex, 1);
      setValue("practiceLocations", addedPracticeLocations);
    } else {
      console.error("Invalid index");
    }
    if (getValues("practiceLocations").length === 0) {
      setShowBillingAddressOption(true);
    }
    setLocationIndex(rowIndex);
  };

  const handleEditActionProvider = (row: any, rowIndex: number) => {
    setProviderIndex(rowIndex);
    setProviderItemObject(row);
    clickShowProviderPopup();
  };

  const handleDeleteActionProvider = (row: any, rowIndex: number) => {
    let addedPracticeProviders = formattedProvidersInfo;
    if (rowIndex >= 0 && rowIndex < addedPracticeProviders.length) {
      addedPracticeProviders.splice(rowIndex, 1);
      // @ts-ignore
      setValue("practiceProviders", addedPracticeProviders);
    } else {
      console.error("Invalid index");
    }
  };

  const handleRowClick = (row: any) => {};

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
  ];

  const actionComponentLocationPopup = (
    row: any,
    rowIndex: number
  ): JSX.Element => {
    return (
      <>
        <Button
          color="info"
          size="small"
          variant="outlined"
          tooltipMessage="Edit"
          tooltipPlacement="top"
          onClick={() => handleEditActionLocation(row, rowIndex)}
        >
          <ModeOutlined fontSize="small" />
        </Button>{" "}
        <Button
          color="error"
          size="small"
          variant="outlined"
          tooltipMessage="Delete"
          tooltipPlacement="top"
          onClick={() => handleDeleteActionLocation(row, rowIndex)}
        >
          <DeleteOutlineOutlined fontSize="small" />
        </Button>
      </>
    );
  };

  const actionComponentProviderPopup = (
    row: any,
    rowIndex: number
  ): JSX.Element => {
    return (
      <>
        <Button
          color="info"
          size="small"
          variant="outlined"
          tooltipMessage="Edit"
          tooltipPlacement="top"
          onClick={() => handleEditActionProvider(row, rowIndex)}
        >
          <ModeOutlined fontSize="small" />
        </Button>{" "}
        <Button
          color="error"
          size="small"
          variant="outlined"
          tooltipMessage="Delete"
          tooltipPlacement="top"
          onClick={() => handleDeleteActionProvider(row, rowIndex)}
        >
          <DeleteOutlineOutlined fontSize="small" />
        </Button>
      </>
    );
  };

  const validatePracticeLocations = () => {
    let practiceLocations = getValues("practiceLocations");
    const hasBillingAddress = practiceLocations.some(
      (location) => location.isBillingAddress === "yes"
    );
    if (hasBillingAddress) {
      return true;
    } else {
      return "At least one practice location must be marked as a billing address.";
    }
  };
  //modify the practice providers object array to display full name
  const formattedProvidersInfo: Row[] = getValues("practiceProviders")
    ? getValues("practiceProviders").map((practice: any) => ({
        providerName: fullName(
          practice.title,
          practice.firstName,
          practice.middleName,
          practice.lastName
        ),
        title: practice.title,
        firstName: practice.firstName,
        middleName: practice.middleName,
        lastName: practice.lastName,
        npi: practice.npi,
        ptan: practice.ptan,
        taxId: practice.taxId,
        email: practice.email,
      }))
    : [];

  // useEffect(() => {
  //   if (isBillerNotApplicable) {
  //     setValue("practiceRequestInfo.billerStreetAddress", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerUnitNumber", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerCity", "", { shouldValidate: false });
  //     setValue("practiceRequestInfo.billerState", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerZipCode", "", {
  //       shouldValidate: false,
  //     });
  //   }
  // }, [isBillerNotApplicable, setValue]);

  useEffect(() => {
    if (isWoundNotApplicable) {
      setValue("practiceRequestInfo.averageWoundsTreatedPerMonth", "", {
        shouldValidate: false,
      });
      setValue("practiceRequestInfo.woundCareFocus", "", {
        shouldValidate: false,
      });
    }
  }, [isWoundNotApplicable, setValue]);

  // const handleBillerNotApplicableChange = (event: any) => {
  //   setIsBillerNotApplicable(event.target.checked);
  // };

  const handleWoundNotApplicableChange = (event: any) => {
    setIsWoundNotApplicable(event.target.checked);
  };

  const getErrorLocationMessage = (field: any, fieldState: any, hasPracticeLocations: any) => {

    if(!hasPracticeLocations){
      return "At least 1 practice location is required.";
    } else {
      let practiceLocations = getValues("practiceLocations");
      const hasBillingAddress = practiceLocations.some(
        (location) => location.isBillingAddress === "yes"
      );
      if (hasBillingAddress) {
        return "";
      } else {
        return "At least one practice location must be marked as a billing address.";
      }
    }
  }

  const signaturePadValidations = {
    name: {
      required: "Name is required.",
    },
    signature: {
      required: "Signature is required.",
    },
  };

  const handleSignerChange = (row: AuthorizedSignerInfo, rowIndex: number) => {
    let obj: AuthorizedSignerInfo = {
      ...row,
      index: rowIndex,
    }
    setShowAuthorizedSignerPopup(true);
    setAuthorizedSignerData(obj);
  };

  const actionComponent = (row: AuthorizedSignerInfo, rowIndex: number): JSX.Element => {
    console.log(row)
    console.log(rowIndex)
    return (
      <>
      <CustomIconButton
        color="info"
        size="small"
        variant="outlined"
        onClick={() => handleSignerChange(row, rowIndex)}
        tooltipMessage="Edit"
        tooltipPlacement="top"
      >
        <ModeOutlined fontSize="small"/>
      </CustomIconButton>
    </>
    )
  }

  const stepContent: StepContent = {
    1: (
      <div>
        <Header
          title="Practice Information"
          subtitle="Enter N/A if information is not available or applicable."
        />
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName="practiceName" label="Practice Name"
              rules={{ 
                required: "Practice Name is required.",
                validate: {
                  customValidation: (value: string) => {
                    if (value?.trim() === "") {
                      return "Practice Name is required.";
                    }
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName="taxId" label="Practice Tax ID" maxLength={10}
              rules={{
                required: "Practice Tax ID is required.",
                validate: {
                  customValidation: (value: string) => {
                    if (value?.trim() === "") {
                      return "Practice Tax ID is required.";
                    }
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName="npi" label="Practice NPI" maxLength={10}
              rules={{
                required: "NPI is required.",
                validate: {
                  customValidation: (value: string) => {
                    if (value?.trim() === "") {
                      return "NPI is required.";
                    }
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName="ptan" label="Practice PTAN" maxLength={10}
              rules={{
                required: "PTAN is required.",
                validate: {
                  customValidation: (value: string) => {
                    if (value?.trim() === "") {
                      return "PTAN is required.";
                    }
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={12} xs={12}>
            <CustomNametField control={control} context="Contact" xs={12} md={4} isRequired={true} />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName="contactEMail" label="Contact Email"
              rules={{
                required: "Contact Email is required.",
                pattern: {
                  value: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                  message: "Invalid email.",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomPatternTextField control={control} label="Contact Phone" name="contactPhone"
              rules={{
                required: "Contact Phone is required.",
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: "Invalid phone number.",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomPatternTextField control={control} label="Contact Fax" name="contactFax"
              rules={{
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: "Invalid fax number.",
                },
              }}
            />
          </Grid>
          <Grid item pt={1}>
            <FormControl>
              <label>How do you bill? *</label>
              <CustomRadioButton control={control} controllerName="billingType" xs={12}
                options={[
                  { value: "INDIVIDUAL", label: "Individual NPI" },
                  { value: "GROUP", label: "Group NPI" },
                ]}
                rules={{ required: "Billing type is required." }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <Controller name="practiceLocations" control={control}
              rules={{
                required: "At least 1 practice location is required.",
                validate: { validatePracticeLocations },
              }}
              render={({ field, fieldState }) => {
                const hasPracticeLocations =
                  getValues("practiceLocations")?.length > 0;
                return (
                  <>
                    <p>How many practice locations do you have?</p>
                    <Grid container justifyContent="flex-start" sx={{ mt: 1, flexWrap: "wrap" }} >
                      <Grid item xs={12} sm="auto">
                        <Button size="large" fullWidth startIcon={<ControlPointOutlined />}
                          color={
                            fieldState.error && !hasPracticeLocations
                              ? "error"
                              : "info"
                          }
                          onClick={clickShowPracticesPopup}
                        >
                          Add Practice Location
                        </Button>
                      </Grid>
                    </Grid>
                    {fieldState.error && (
                      <Typography color="error" variant="body2" style={{ marginTop: "8px" }} >
                        { getErrorLocationMessage(field, fieldState, hasPracticeLocations)}
                      </Typography>
                    )}

                    {hasPracticeLocations && (
                      <Box pt={1}>
                        <LegacyToolBar items={legacyItems} />
                        <Table
                          columns={columnsPractice}
                          rows={transformPracticeLocations(
                            getValues("practiceLocations")
                          )}
                          onRowClick={handleRowClick}
                          actionComponent={(row: any, rowIndex: number) =>
                            actionComponentLocationPopup(row, rowIndex)
                          }
                          {...PracticeLocationPopup}
                          disablePagination={true}
                          borderBox={true}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Grid>

          <Grid item md={12}>
            <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: 0 }} />
            <Controller name="practiceProviders" control={control}
              rules={{ required: "At least 1 provider is required." }}
              render={({ field, fieldState }) => {
                const hasPracticeProviders =  getValues("practiceProviders")?.length > 0;
                return (
                  <>
                    <p>How many providers do you have?</p>
                    <Grid container justifyContent="flex-start" sx={{ mt: 1, flexWrap: "wrap" }} >
                      <Grid item xs={12} sm="auto">
                        <Button size="large" startIcon={<ControlPointOutlined />}
                          color={
                            fieldState.error && !hasPracticeProviders
                              ? "error"
                              : "info"
                          }
                          onClick={clickShowProviderPopup}
                          fullWidth
                        >
                          Add Providers
                        </Button>
                      </Grid>
                    </Grid>
                    {fieldState.error && !hasPracticeProviders && (
                      <Typography color="error" variant="body2" style={{ marginTop: "8px" }} >
                        {fieldState.error.message}
                      </Typography>
                    )}

                    {hasPracticeProviders && (
                      <Box pt={1}>
                        <LegacyToolBar items={legacyItems} />
                        <Table
                          columns={columnsProvider}
                          rows={formattedProvidersInfo}
                          onRowClick={handleRowClick}
                          actionComponent={(row: any, rowIndex: number) =>
                            actionComponentProviderPopup(row, rowIndex)
                          }
                          {...ProviderPopup}
                          disablePagination={true}
                          borderBox={true}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: 0 }} />
          </Grid>
        </Grid>

        <div style={{ height: "30px" }}></div>
        <Header title="Account Payable Information" subtitle="" />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <CustomNametField control={control} context="ap" label="AP Contact" xs={12} md={4} isRequired={true} />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomPatternTextField control={control} name={"apPhone"} label="AP Contact Phone"
              rules={{
                required: "AP Contact Phone is required.",
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: "Invalid phone number.",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName={"apEmail"} label="AP Contact Email"
              rules={{
                required: "AP Contact Email is required.",
                pattern: {
                  value: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                  message: "Invalid email.",
                },
              }}
            />
          </Grid>
        </Grid>

        <div style={{ height: "30px" }}></div>
        <Header title="Biller Information" subtitle="" />
        <Grid container spacing={2}>
        {/* CS-561 */}
        <Grid container ml={0.5}>
          <Grid item xs="auto">
            <CustomCheckbox
              control={control}
              label={"Same as Primary Contact"}
              checked={officeContactChecked}
              isDisabled={accountInformationChecked}
              onChange={() => {
                setOfficeContactChecked(!officeContactChecked);
                setAccountInformationChecked(false)
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <CustomCheckbox
              control={control}
              label={"Same as Account Payable Information"}
              checked={accountInformationChecked}
              onChange={() => {
                setAccountInformationChecked(!accountInformationChecked)
                setOfficeContactChecked(false)
              }}
              isDisabled={officeContactChecked}
            />
          </Grid>
          </Grid>
          {/* // */}
          <Grid item md={12}>
            <CustomNametField control={control} context="biller" name={"practiceRequestInfo"}
              label="Biller Contact" xs={12} md={4} isRequired={true}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomPatternTextField control={control} label="Biller Contact Phone"
              name={`practiceRequestInfo.${"billerContactPhone"}`}
              rules={{
                required: "Biller Contact Phone is required.",
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: "Invalid phone number.",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName={`practiceRequestInfo.${"billerContactEmail"}`}
              label="Biller Contact Email"
              rules={{
                required: "Biller Contact Email is required.",
                pattern: {
                  value: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                  message: "Invalid email.",
                },
              }}
            />
          </Grid>
          {/* <Grid item md={12}>
            <CustomCheckbox id={"12"} control={control} controllerName={`practiceRequestInfo.${"billerNotApplicable"}`}
              label="Upload address information later and save the draft" checked={isBillerNotApplicable} onChange={handleBillerNotApplicableChange} />
          </Grid>
          <Grid item md={12}>
            <CustomAddressTextField control={control} context="Biller" name={isBillerNotApplicable ? "" : "practiceRequestInfo"}
              isRequired={!isBillerNotApplicable} isDisabled={isBillerNotApplicable} />
          </Grid> */}
          <Grid item md={12}>
            <Controller name={`practiceRequestInfo.${"authorizedSigners"}`} control={control}
              rules={{
                required: "At least 1 Authorized Signer is required.",
              }}
              render={({ field, fieldState }) => {
                const hasAuthorizedSigners =
                  getValues("practiceRequestInfo.authorizedSigners")?.length > 0;
                return (
                  <>
                    <p>Authorized Signer(s)</p>
                    <Grid container justifyContent="flex-start" sx={{ mt: 1, flexWrap: "wrap" }} >
                      <Grid item xs={12} sm="auto">
                        <Button size="large" fullWidth startIcon={<ControlPointOutlined />}
                          color={
                            fieldState.error && !hasAuthorizedSigners
                              ? "error"
                              : "info"
                          }
                          onClick={clickShowAuthorizedSignerPopup}
                        >
                          Add Authorized Signer
                        </Button>
                      </Grid>
                    </Grid>
                    {fieldState.error && (
                      <Typography color="error" variant="body2" style={{ marginTop: "8px" }} >
                        {fieldState.error.message}
                      </Typography>
                    )}

                    {hasAuthorizedSigners && (
                      <Grid item md={6} xs={12}>
                      <Box pt={1}>
                        <Table
                          columns={columnsSigner}
                          rows={getValues("practiceRequestInfo.authorizedSigners")}
                          actionComponent={(row: any, rowIndex: number) => actionComponent(row, rowIndex)}
                          onRowClick={handleRowClick}
                          disablePagination={true}
                          borderBox={true}
                        />
                      </Box>
                      </Grid>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>

        <div style={{ height: "30px" }}></div>
        <Header title="Wound Care Information" subtitle="" />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <CustomCheckbox id={"12"} control={control} controllerName={`practiceRequestInfo.${"woundNotApplicable"}`}
              label="Not Applicable" checked={isWoundNotApplicable} onChange={handleWoundNotApplicableChange} />
          </Grid>
          <Grid item md={4} xs={12}>
            <div style={{ fontSize: "medium" }}>Wound Care Focus</div>
            <FormControl>
              <CustomRadioButton control={control} controllerName={isWoundNotApplicable ? "" : `practiceRequestInfo.${"woundCareFocus"}`}
                xs={12} isDisabled={isWoundNotApplicable}
                options={
                  woundCareOptions
                    ? woundCareOptions.map((option) => ({
                      value: option.keyName,
                      label: option.displayValue,
                    }))
                    : []
                }
                rules={{
                  required: isWoundNotApplicable ? false : "Wound care focus is required.",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField control={control} controllerName={ isWoundNotApplicable ? "" : `practiceRequestInfo.${"averageWoundsTreatedPerMonth"}` }
              label="Average Wounds Treated Per Month" isDisabled={isWoundNotApplicable} isEmpty={isWoundNotApplicable}
              rules={{
                required: isWoundNotApplicable ? false : "Average Wounds Treated Per Month is required.",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a valid number.",
                },
              }}
            />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <div style={{ fontSize: "medium" }}>Place(s) of Service</div>
            <br />
            <FormControl>
              <Grid container spacing={1} style={{ position: "relative", left: "10px" }} >
                <CustomCheckboxGroup control={control} controllerName={`practiceRequestInfo.${"placesOfService"}`} xs={6}
                  options={
                    placeOfService
                      ? placeOfService.map((option) => ({
                        value: option.keyName,
                        label: option.displayValue,
                      }))
                      : []
                  }
                  rules={{
                    required: "Place(s) of Service is required.",
                  }}
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ height: "30px" }}></div>
        <Grid container spacing={2}></Grid>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: "20px 0" }} />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomTextField control={control} controllerName={`practiceRequestInfo.${"repOrDistributorName"}`}
                label="Please provide the name of the Representative or Distributor that brought you here" multiline rows={4} maxRows={4} />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField control={control} controllerName={`practiceRequestInfo.${"anyQuestions"}`}
                label="Please add any notes or specific requests that will help us serve you properly" multiline rows={4} maxRows={4} />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "30px" }}></div>
        {/* <Header title="Insurance Payer Mix" subtitle="" /> */}
        <Grid container spacing={2}>
          {/* <Grid item md={4} xs={12}>
            <CustomDropdownField label="#1 Insurance Payer" name={`practiceRequestInfo.${"insurancePayer1"}`}
              options={insurancePayerOptions} control={control}
              // rules={{ required: "#1 Insurance Payer is required." }}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomDropdownField label="#2 Insurance Payer" name={`practiceRequestInfo.${"insurancePayer2"}`}
              options={insurancePayerOptions} control={control}
              // rules={{ required: "#2 Insurance Payer is required." }}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomDropdownField label="#3 Insurance Payer" name={`practiceRequestInfo.${"insurancePayer3"}`}
              options={insurancePayerOptions} control={control}
              // rules={{ required: "#3 Insurance Payer is required." }}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid> */}
          <Grid item md={12}>
            <p style={{ textAlign: "center" }}>
              It is crucial to provide all the requested information as it
              directly impacts our ability to fulfill your needs effectively.
              Without the necessary details, we may encounter delays or
              limitations in providing you with the best possible service. Your
              cooperation in furnishing complete and accurate information is
              vital to ensuring a smooth and efficient process. Thank you for
              your attention to this matter.
            </p>
          </Grid>
        </Grid>
      </div>
    ),
    2: (
      <>
        <Header title="Business Associate Agreement" subtitle="" />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <p style={{ textAlign: "justify" }}>
              This Business Associate Agreement ("Agreement") is by and between
              V3 Biomedical, LLC., a Delaware LLC having its principal place of
              business in Frisco, Texas ("V3"), and the Customer ("Customer")
              named in the Provider Registration Form under the applicable
              Services Agreement (as defined below The parties desire through
              this Agreement to amend the applicable Services Agreement
              consistent with the requirements of the Health Insurance
              Portability and Accountability Act of 1996, as it may be amended
              from time to time ("HIPAA"), including the regulatory revisions
              implemented pursuant to the Health Information Technology for
              Economic and Clinical Health Act (the "HITECH Act" This Agreement
              becomes effective on the date that this Agreement is signed by the
              last of the two parties to sign it (as indicated by the date
              associated with that party's signature) ("Effective Date" This
              Agreement replaces any earlier business associate agreement(s)
              entered into between the parties.
            </p>
            <p style={{ textAlign: "justify" }}>
              WHEREAS, Customer and V3 are parties to a services & support, or
              similar agreement or "terms of use" (collectively, the "Services
              Agreement," as further defined below) pursuant to which V3
              provides to Customer, through hosted software and other
              professional management services, access to and use of certain
              workflow management software tools as well as ongoing support and
              customer service, as ordered by Customer from time to time under
              the Services Agreement (the "Services," as further defined below);
            </p>
            <p style={{ textAlign: "justify" }}>
              WHEREAS, the parties desire to ensure that their respective rights
              and responsibilities under the Services Agreement reflect
              applicable federal statutory and regulatory requirements relating
              to the access, use, and disclosure of health information,
              including, without limitation, the Standards for Privacy of
              Individually Identifiable Health Information, and the Security
              Standards, collectively codified at 45 CFR Parts 160, 162, and 164
              (respectively the "Privacy Standards" and "Security Standards")
              under HIPAA;
            </p>
            <p style={{ textAlign: "justify" }}>
              WHEREAS, because Customer is a Covered Entity or Business
              Associate under HIPAA, the Privacy Standards and Security
              Standards require Customer to obtain adequate written assurances
              from contractors or subcontractors that create, receive, access,
              maintain, use, or disclose PHI for or on behalf of such Customer:
              and
            </p>
            <p style={{ textAlign: "justify" }}>
              WHEREAS, the online Services offered by V3 may be used by Customer
              to store certain PHI (though typically not electronic medical
              records or Designated Record Sets): and WHEREAS, the Customer
              wishes to have V3 manage all aspects of their service needs
              relating to acute and chronic wound care products. This includes
              the preferred utilization of "Patientflows," a HIPAA secure
              workflow management system used to streamline processes for
              products and services involving PHI; and
            </p>
            <p style={{ textAlign: "justify" }}>
              WHEREAS, V3 and Customer agree to the business associate terms set
              forth below in order to facilitate Customer's access and
              transmission of information to and from the Patientflows-hosted
              systems provided as part of the Services, as authorized by, and
              under certain other conditions described in the Services
              Agreement.
            </p>
            <p style={{ textAlign: "justify" }}>
              NOW, THEREFORE, for good and valuable consideration, the receipt
              and sufficiency of which is hereby acknowledged, the parties
              hereby amend their Services Agreement by agreement to the
              following:
            </p>
            <p style={{ textAlign: "left" }}>
              <b>1. DEFINITIONS</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) General. Capitalized terms used in this Agreement and not
              otherwise defined herein shall have the same meanings as defined
              in the Privacy Standards or Security Standards and corresponding
              official materials published, issued, or promulgated by the
              Secretary of the Department of Health and Human Services.
              "Protected Health Information" (or "PHI") shall have the same
              meaning as the term "protected health information" in 45 CFR §
              160.103, limited to the information actually received by V3 from
              or on behalf of the Covered Entity in connection with the Services
              Agreement.
            </p>
            <p style={{ textAlign: "justify" }}>
              (b) Specific Definitions. As used herein:
            </p>
            <p style={{ textAlign: "justify" }}>
              "Services" means specifically the instance of the V3 web
              application, identified as "Patienflows by Patientflows LLC,"
              "V3," or similar services in the Customer's signature block, along
              with Patientflows mobile software applications used by the
              Customer therewith. For clarification, such Services do not
              include
            </p>
            <table style={{ width: "100%", textAlign: "justify" }}>
              <tbody>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (i) any general obligation to supervise, oversee, or consult
                    with Customer for the purposes of advising Customer on or
                    ensuring Customer's compliance with HIPAA, the HITECH Act,
                    and HIPAA Regulations, <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (ii) any services offered by any third party, including V3
                    partners, <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (iii) any services offered in the Patientflows Marketplace
                    by V3, <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (iv) web and mobile applications, nor <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (v) the email, SMS, and VolP functionalities within the
                    Patientflows web or mobile applications.
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ textAlign: "justify" }}>
              "Services Agreement" means the current or any future agreement
              between Customer and V3, under which V3 provides the Services to
              Customer, which involve the use or disclosure of Protected Health
              Information, particularly the "terms of use" or any subscription
              or partner agreements, as such agreement(s) may be amended,
              modified, or renamed in the future.
            </p>
            <p style={{ textAlign: "left" }}>
              <b>2. OBLIGATIONS AND ACTIVITIES OF V3 BIOMED</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) Use and Disclosure To the extent (if any) that V3 creates,
              transmits, maintains, or receives any PHI on behalf of Customer,
              including any Electronic PHI, V3 agrees to:
            </p>
            <table style={{ width: "100%", textAlign: "justify" }}>
              <tbody>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (i) maintain the privacy and security of such PHI and not
                    use or disclose PHI other than as permitted or required to
                    satisfy its obligations under the Services Agreement, as
                    permitted herein, or as required by law; <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (ii) use appropriate safeguards, consistent with the
                    requirements of Subparts B and C of 45 CFR $164.504(e)(2){" "}
                    <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (iii) (with respect to Electronic PHI to prevent the use or
                    disclosure of the PHI other than as permitted under this
                    Addendum: <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (iv) implement or maintain administrative, physical, and
                    technical safeguards that reasonably and appropriately
                    protect the confidentiality, integrity, and availability of
                    Electronic PHI; and <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (v) to promptly report to Customer any use or disclosure of
                    PHI not permitted by this Addendum of which V3 becomes aware
                    (including Breaches of Unsecured PHI as required at 45 CFR §
                    164.410) and any Security Incident that V3 becomes aware;
                    provided, however, that the parties acknowledge and agree
                    that this Section 2(a) constitutes notice by V3 to Customer
                    of the ongoing existence and occurrence or attempts of
                    Unsuccessful Security Incidents for which no additional
                    notice to Customer shall be required. "Unsuccessful Security
                    Incidents" means, without limitation, pings and other
                    broadcast attacks on V3's firewall(s), port scans,
                    unsuccessful log-on attempts, denial of service attacks, and
                    any combination of the above, so long as no such incident
                    results in unauthorized access, use or disclosure of PHI.
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ textAlign: "justify" }}>
              (b) Agents. V3 shall obtain and maintain an agreement with each
              agent or subcontractor that has or will have access to PHI. That
              agreement will require each such agent or subcontractor to be
              bound by the restrictions, terms, and conditions that are at least
              as stringent as those that apply to V3 with respect to such PHI.
              Further, each agent or subcontractor will agree to report to V3
              any instances in which it is aware of a violation of the agreement
              with respect to PHI.
            </p>
            <p style={{ textAlign: "justify" }}>
              (c) Access to Designated Record Sets. To the extent (if any) that
              V3 possesses and maintains a Designated Record Set for Customer,
              V3 agrees to:
            </p>
            <table style={{ width: "100%", textAlign: "justify" }}>
              <tbody>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (i) provide access, at the request of Customer, and in the
                    time and manner mutually agreed between Wilco and Customer,
                    to PHI in a Designated Record Set, to Customer or, as
                    directed by Customer, to an Individual in order to satisfy
                    Customer's obligations under 45 CFR § 164.524; and <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (ii) to make any amendment(s) to PHI in a Designated Record
                    Set as directed or agreed to by the Customer pursuant to 45
                    CFR § 164.526, and in the time and manner mutually agreed
                    between V3 and Customer (provided that the amendment of an
                    Individual's PHI and all decisions related thereto shall be
                    the sole responsibility of Customer).
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ textAlign: "justify" }}>
              (d) Accounting. V3 agrees to make available to Customer
              information regarding disclosures made by V3 for which an
              accounting is required under 45 CFR § 164,528 so Customer can meet
              its requirements to provide an accounting to an individual in
              accordance with 45 CFR § 164,528.
            </p>
            <p style={{ textAlign: "justify" }}>
              (e) Access to Books and Records. V3 agrees to make its internal
              practices, books, and records relating to the use and disclosure
              of PHI available to the Secretary for purposes of determining
              compliance with the HIPAA Rules.
            </p>
            <p style={{ textAlign: "left" }}>
              <b>3. PERMITTED USES AND DISCLOSURES BY V3 BIOMED</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) Services Agreement Except as otherwise limited by this
              Agreement, V3 may use or disclose PHI as necessary to perform the
              Services for Customer as specified in the Services Agreement,
              provided that such use or disclosure would not violate the Privacy
              Rule if done by Customer and complies with the principle of
              "minimum necessary use and disclosure" consistent with 45 CFR §
              164.514(d).
            </p>
            <p style={{ textAlign: "justify" }}>
              (b) Disclosure for Administration of V3. Except as otherwise
              limited by this Agreement, V3 may disclose PHI for the proper
              management and administration of V3, provided that
            </p>
            <table style={{ width: "100%", textAlign: "justify" }}>
              <tbody>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (i) disclosures are required by law or <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "97%" }}>
                    (ii)V3 obtains reasonable assurances from the person to whom
                    the information is disclosed that it will remain
                    confidential and used or further disclosed only as Required
                    by Law or for the purpose for which it was disclosed to the
                    person. The person notifies V3 of any instances of which it
                    is aware in which the confidentiality of the information has
                    been breached
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ textAlign: "justify" }}>
              (c) Reporting Violations. V3 may use PHI to report violations of
              law to appropriate Federal and State authorities, consistent with
              45 CFR § 164.502(1).
            </p>
            <p style={{ textAlign: "left" }}>
              <b>4. OBLIGATIONS OF CUSTOMER</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) Limitations in Notice of Privacy Practices. Customer shall
              notify V3 of any limitation(s) in the notice of privacy practices
              of Customer under 45 CFR § 164,520 to the extent that such
              limitation may affect Patientflows use or disclosure of PHI.
              Granted, however, the Customer shall not agree to any restriction
              requests or place any restrictions in any notice of privacy
              practices that would cause V3 to violate this Addendum or any
              applicable law.
            </p>
            <p style={{ textAlign: "justify" }}>
              (b) Restrictions to the Use or Disclosure of PHI. The customer
              shall notify V3 of any restriction regarding the use or disclosure
              of PHI that the Customer has agreed to in accordance with 45 CFR 5
              164.522, to the extent that such restriction may affect
              Patientflows' use or disclosure of PHI.
            </p>
            <p style={{ textAlign: "justify" }}>
              (c) Permissible Use Requests. Except for the permitted uses set
              forth in Section 3, Customer will not request V3 to use or
              disclose PHI in any manner that would not be permissible under
              Subpart E of 45 CFR $164,504 if done by Customer.
            </p>
            <p style={{ textAlign: "justify" }}>
              (d) Access Requests from Data Subjects. V3 will notify the
              Customer of any request from a data subject for access to, or
              correction, erasure, blocking, or amendment of, the data subject's
              PHI if legally permitted. V3 will ask the data subject to submit
              its request to the Customer. The customer is responsible for
              responding to the request.
            </p>
            <p style={{ textAlign: "left" }}>
              <b>5. TERM AND TERMINATION</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) Term and Termination The term of this Agreement shall begin on
              the Effective Date. It shall terminate on the date that the
              Services Agreement expires or is otherwise terminated for any
              reason or the date Customer terminates this Agreement for cause as
              authorized in this Section, or the date either party terminates
              this Agreement for convenience, whichever is earliest.
            </p>
            <p style={{ textAlign: "justify" }}>
              (b) Termination for Cause. Upon Customer's knowledge of a material
              breach of this Agreement by V3, Customer shall notify V3 of the
              breach in writing and shall provide an opportunity for V3 to cure
              the breach or end the violation within thirty (30) business days
              of such notification: provided, that if V3 fails to cure the
              breach or end the violation within such time period to the
              satisfaction of Customer, Customer shall have the right to
              immediately terminate this Agreement and the Services Agreement
              upon written notice to V3. In the event that termination of this
              Agreement is not feasible as mutually agreed to by V3 and
              Customer, V3 hereby acknowledges that Customer shall have the
              right to report the breach to the Secretary.
            </p>
            <p style={{ textAlign: "justify" }}>
              (c) Termination for Convenience. Either party has the right to
              terminate this Agreement for any reason, and without terminating
              the Services Agreement, upon 60 days prior written notice to the
              other party. Where Customer terminates this Agreement for
              convenience, the Customer represents and warrants that it has
              removed all PHI from the Services.
            </p>
            <p style={{ textAlign: "justify" }}>
              (d) Effect of Termination. Following the termination or expiration
              of this Agreement for any reason, V3 shall comply with the
              request(s) of the Customer that V3 returns or destroy all PHI
              received from Customer or received by V3 on behalf of Customer.
              This provision shall also apply to PHI that is in the possession
              of subcontractors or agents of V3. V3 shall retain no copies of
              the PHI. Notwithstanding the foregoing, in the event that V3
              determines that returning or destroying the PHI is infeasible, V3
              shall provide to Customer notification of the conditions that make
              return or destruction infeasible. Further, in consideration of the
              fact that V3 maintains a procedure for replicating or backing up
              Customer data to help ensure the integrity and availability of
              PHI, the parties agree that it is infeasible for V3 to return or
              destroy PHI that is contained in any and all such data backups or
              data replications. Where return or destruction of PHI is
              infeasible, V3 shall extend the protections of this Addendum to
              such PHI and limit further uses and disclosures of such PHI to
              those purposes that make the return or destruction infeasible for
              so long as V3 maintains such PHI.
            </p>
            <p style={{ textAlign: "justify" }}>
              (e) Survival. The obligations of V3 under this Section shall
              survive the termination of this Agreement.
            </p>
            <p style={{ textAlign: "left" }}>
              <b>6. MISCELLANEOUS</b>
            </p>
            <p style={{ textAlign: "justify" }}>
              (a) Regulatory References. A reference in this Agreement to a
              section in the HIPAA Rules means the section is in effect or as
              amended or modified from time to time.
            </p>
            <p style={{ textAlign: "justify" }}>
              (b) Amendment No alteration, amendment, or modification of the
              terms of this Addendum shall be valid or effective unless in
              writing and signed by Wilco and Customer.
            </p>
            <p style={{ textAlign: "justify" }}>
              (c) Miscellaneous The terms of this Agreement are hereby
              incorporated into the Services Agreement. In the event of a
              conflict between the terms of this Agreement and the terms of the
              Services Agreement, the terms of this Agreement will prevail. Any
              ambiguity in this Agreement shall be interpreted to permit
              compliance with the HIPAA Rules.
            </p>
          </Grid>
          <Grid item md={12}>
            <div style={{ fontSize: "medium" }}>Customer Signature</div>
          </Grid>
          <Grid item md={6} xs={12}>
            <SignaturePad onSignatureChange={handleSignatureChange} name={`practiceRequestInfo.${"customerSignature"}`} textName={`practiceRequestInfo.${"customerName"}`} control={control}
              validationRules={signaturePadValidations}
            />
          </Grid>
          <Grid item md={12}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", }} >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY + ""}
                onChange={captchaOnChange}
                onExpired={() => {
                  setIsRecaptchaValid(false);
                  recaptchaRef.current.reset();
                }}
              />
            </div>
          </Grid>
        </Grid>
      </>
    ),
  };

  return (
    <>
      <PracticeLocationPopup
        onClose={clickClosePracticesPopup}
        open={showPracticesPopup}
        addPractice={addPractice}
        practiceLocations={getValues("practiceLocations") || []}
        billingAddressPanel={showBillingAddressOption}
        practiceLocationObject={locationItemObject}
      />
      <ProviderPopup
        onClose={clickCloseProviderPopup}
        open={showProviderPopup}
        addProvider={addProvider}
        onChange={handleProviderChange}
        practiceProviderObject={providerItemObject}
        billingType={getValues("billingType")}
      />
      {showAuthorizedSignerPopup &&
        <AuthorizedSignerPopup
          onClose={clickCloseAuthorizedSignerPopup}
          open={showAuthorizedSignerPopup}
          addAuthorizedSigner={addSigner}
          authorizedSignerData={authorizedSignerData}
        />
      }
      <Box p="50px">
        <Box>
          <Grid container spacing={2} p={2}>
            <Grid item sm={4}></Grid>
            <Grid item sm={4} style={{ textAlign: "center" }}>
              <img alt="logo" src={require("../../../assets/V3_logo-gradient.png")} />
            </Grid>
            <Grid item sm={4} style={{ textAlign: "end" }}>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <LightModeOutlined />
                ) : (
                  <DarkModeOutlined />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Card>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
            <FormGroup>
              {stepContent[currentStep]}
              <Grid container justifyContent="flex-end" sx={{ mt: 1, flexWrap: "wrap" }} spacing={1} >
                {/* <Grid item xs={12} sm="auto">
                  {currentStep > 1 && (
                    <Button onClick={handleBackClick} sx={{ mr: 1 }} startIcon={<ArrowBack />} fullWidth >
                      Back
                    </Button>
                  )}
                </Grid> */}
                <Grid item xs={12} sm="auto">                  
                  {/* <Button sx={{ mr: 1 }} disabled={currentStep >= totalSteps && !isRecaptchaValid} type="submit" fullWidth
                    startIcon={ currentStep >= totalSteps ? <Save /> : <ArrowForward /> } >
                    {currentStep >= totalSteps ? "Submit" : "Next"}
                  </Button> */}
                  <Grid item md={12} mb={2}>
                    
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", }} >
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY + ""}
                        onChange={captchaOnChange}
                        onExpired={() => {
                          setIsRecaptchaValid(false);
                          recaptchaRef.current.reset();
                        }}
                      />
                    </div>
                  </Grid>
                  <Button sx={{ mr: 1 }} disabled={!isRecaptchaValid} type="submit" fullWidth
                    startIcon={ <Save /> } >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </Card>
      </Box>
    </>
  );
}

export default PracticeRegistration;