import { Box, Grid, Collapse, useTheme } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { ArchiveOutlined, Checklist, ModeOutlined, PersonAddAltOutlined, SearchOutlined, TrackChanges, VisibilityOutlined } from "@mui/icons-material";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { usePracticeSlice } from "../../../redux/slice/practiceSlice";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useForm } from "react-hook-form";
import { fullName, hideElement, sortNames } from "../../../utils/Utility";
import { SingleAlertInfo } from "../../../redux/slice/commonSlice";
import CustomIconButton from "../../common/CustomIconButton";
import { CAN_ACCESS_APPLICATION, PROVIDER_LIST_ACTIONS } from "../../../constants/applicationConstants";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { PracticeInfo } from "../../../redux/slice/practice/practiceRegistrationSlice";
import CustomSingleAlert from "../../common/CustomSingleAlert";
import { getPracticesByUserRoleUserSlice, useUserReducer } from "../../../redux/slice/userSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { fetchUsedPracticeProviderIDs, resetSearchedProviderList, SearchProviderRequest, searchProviders, usePracticeProvidersSlice } from "../../../redux/slice/practice/practiceProvidersSlice";
import CustomTextField from "../../common/CustomTextField";
import OptionsPopup from "../../common/OptionsPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left"; // Adjust the align property type
  format?: (value: number) => string;
}

type Option = {
  value: string,
  label: string
};

// Define columns and rows data
const columns: Column[] = [
  { id: "practiceName", label: "Practice", minWidth: 100 },
  { id: "providerName", label: "Provider Name", minWidth: 100 },
  // { id: "lastName", label: "last Name", minWidth: 100 },
  { id: "npi", label: "NPI", minWidth: 100 },
  { id: "ptan", label: "Ptan", minWidth: 100 },
  { id: "taxId", label: "Tax ID", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 50, align: "left", },
];

const ITEMS_PER_PAGE = 20;

const ProviderList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { control, handleSubmit, reset, setValue } = useForm<SearchProviderRequest>();

  const [showCard, setShowCard] = useState(false);
  const [baaAlert, setBaaAlert] = useState<{ show: boolean, obj: SingleAlertInfo }>();

  const [searchCriteria, setSearchCriteria] = useState<SearchProviderRequest>({
    practiceIds: [],
    firstName: "",
    lastName: "",
    email: "",
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const { practiceInfo } = usePracticeSlice();
  const { userInfo } = useAuthSlice();
  const { practiceByUserRoleUserSlice } = useUserReducer();
  const { searchProviderList, usedPracticeProviderIds } = usePracticeProvidersSlice();
  const [createProviderUser, setCreateProviderUser] = useState<{
    message: string;
    show: boolean;
    row: any;
  }>({ message: "", show: false, row: "" });

  const canCreateProviderUser = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProviderActions", PROVIDER_LIST_ACTIONS.CREATE_PROVIDER_USER )(state));
  const canViewProvider = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProviderActions", PROVIDER_LIST_ACTIONS.VIEW )(state));

  //Mount
  useEffect(() => {
  }, []);

  //Unmount
  useEffect(() => () => {
    dispatch(resetSearchedProviderList());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId);
    if ((location.state && location.state.practiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
      if (location.state && location.state.withSearchHistory) {
        //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
        navigate("/practice_list", { state: { practiceListSearchCriteria: criteria } });
        return;
      }
      let searchCriteria: SearchProviderRequest = {
        practiceIds: [],
        firstName: "",
        lastName: "",
        email: "",
        currentPage: 0,
        itemsPerPage: 0
      };
      if (location.state && location.state.practiceListSearchCriteria) {
        searchCriteria = location.state.practiceListSearchCriteria;
      } else {
        searchCriteria = criteria ? criteria : searchCriteria;
      }
      setSearchCriteria(searchCriteria);
      setValuesToCriteriaFields(searchCriteria);

      searchPracticesList(searchCriteria);
    } else {
      loadInitialPracticeList(1);
    }
  }, [location.state]);

  const setValuesToCriteriaFields = (searchCriteria: SearchProviderRequest) => {
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("firstName", searchCriteria.firstName);
    setValue("lastName", searchCriteria.lastName);
    setValue("email", searchCriteria.email);
  }

  const loadInitialPracticeList = (pNumber: number = 1) => {
    const data: SearchProviderRequest = {
      practiceIds: [],
      firstName: "",
      lastName: "",
      email: "",
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);

    searchPracticesList(data);
  }

  const searchPracticesList = (searchCriteria: SearchProviderRequest) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PROVIDER + "SearchCriteria" + userInfo.userId, searchCriteria);
    dispatch(searchProviders(searchCriteria));
  }

  const onSubmit = (data: SearchProviderRequest) => {
    const data2: SearchProviderRequest = {
      practiceIds: data.practiceIds,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/provider_list", { state: { practiceListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/provider_list", { state: { practiceListSearchCriteria: data } });
  }

  const formattedProviderRows = useMemo(() => {
    if (!searchProviderList?.content) return [];
    
    return searchProviderList.content.map(provider => {
      const isExistingUserProfile = usedPracticeProviderIds?.includes(provider.providerId);
      
      return {
        ...provider,
        providerName: fullName(provider.title, provider.firstName, provider.middleName, provider.lastName),
        isExistingUserProfile
      };
    });
  }, [searchProviderList, usedPracticeProviderIds]);


  const actionComponent = (row: any): JSX.Element => {
    return (
      <>
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="View"
          color="warning"
          size="small"
          variant="outlined"
          onClick={() => console.log(row)}
          style={{
            display: `${hideElement(
              !(canViewProvider)
            )}`,
          }}
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>

        <CustomIconButton
          color="success"
          size="small"
          variant="outlined"
          tooltipMessage="Create User Account"
          tooltipPlacement="top"
          onClick={() => { handleCreateProviderUser(row) }}
          style={{
            display: `${hideElement(
              !(canCreateProviderUser && !row.isExistingUserProfile && row.approvedStatus === "APPROVED")
            )}`,
          }}
        >
          <PersonAddAltOutlined fontSize="small" />
        </CustomIconButton>
      </>
    );
  }

  const handleCreateProviderUser = async (row: any) => {
    setCreateProviderUser({
      message:
        "Do you wish to create a user account for this practice provider? ",
      show: true,
      row: row,
    });
  };

  const confirmCreateProviderUser = () => {
    handleNavigateToCreateUser(createProviderUser.row);
    setCreateProviderUser({ message: "", show: false, row: "" });
  };

  const handleNavigateToCreateUser = (row: any) => {
    navigate("/user_create", { state: { providerData: { providerId: row.providerId, entityIds: row.practiceId }}});
  };
  const cancelCreateProviderUser = () => {
    setCreateProviderUser({ message: "", show: false, row: "" });
  };

  const practiceOptions = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRoleUserSlice && practiceByUserRoleUserSlice.length > 0) {
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleUserSlice, 'practiceName');
      sortedPracticeInfo?.map(practice => {
        options.push({ value: practice.practiceId, label: practice.practiceName });
      });
    }
    return options;
  }, [practiceByUserRoleUserSlice]);

  useEffect(() => {
    dispatch(getPracticesByUserRoleUserSlice(false));
  }, [dispatch]);

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <Checklist color="success" />, text: "Approve" },
    // { icon: <NoteAddOutlined color="info" />, text: "View Practice Request" }
    { icon: <ArchiveOutlined color="info" />, text: "View Archive Practice" }
  ];

    useEffect(()=>{
      dispatch(fetchUsedPracticeProviderIDs());
    },[])

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Provider List" subtitle="Provider Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown control={control} name="practiceIds" label="Practice Name" options={practiceOptions} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="firstName" label="First Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="lastName" label="Last Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="email" label="Email" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px", }} type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
                {/* {isMobile ?<LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              rows={formattedProviderRows || []}
              actionComponent={(row: any) => actionComponent(row)}
              emptyText="No Provider(s) Found"
              pageNumber={handlePagination}
              totalPages={searchProviderList?.totalPages}
              totalElementText={"Records: " + (searchProviderList?.totalElements ? searchProviderList?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
              showMenu={true}
            />
          </Card>
        </Box>
      </Box>
      {
        baaAlert?.show &&
        <CustomSingleAlert
          open={baaAlert?.show}
          singleAlertObj={baaAlert.obj}
          autoHideDuration={20000}
          onClose={() => setBaaAlert({ show: false, obj: { message: "", alertType: "info" } })}
        />
      }
       <OptionsPopup
        open={createProviderUser && createProviderUser.show}
        onClose={() =>
          setCreateProviderUser({ message: "", show: false, row: "" })
        }
        variant="confirm"
        message={createProviderUser.message}
        buttons={[
          {
            name: "Confirm",
            color: "primary",
            onClick: confirmCreateProviderUser,
          },
          {
            name: "Cancel",
            color: "secondary",
            onClick: cancelCreateProviderUser,
          },
        ]}
      />
    </>
  );

}

export default ProviderList;