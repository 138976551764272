import { Box, Grid, Collapse, useTheme } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { AddCircleOutlineOutlined, SearchOutlined, TrackChanges } from "@mui/icons-material";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { usePracticeSlice } from "../../../redux/slice/practiceSlice";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useForm } from "react-hook-form";
import { formatAddress, fullName, sortNames } from "../../../utils/Utility";
import { SingleAlertInfo } from "../../../redux/slice/commonSlice";
import CustomIconButton from "../../common/CustomIconButton";
import { CAN_ACCESS_APPLICATION } from "../../../constants/applicationConstants";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { PracticeInfo } from "../../../redux/slice/practice/practiceRegistrationSlice";
import { getPracticesByUserRoleUserSlice, useUserReducer } from "../../../redux/slice/userSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import CustomTextField from "../../common/CustomTextField";
import { PatientInfo, resetSearchPatientList, SearchPatientRequest, searchPatients, usePatientReducer } from "../../../redux/slice/patient/patientSlice";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left"; // Adjust the align property type
  format?: (value: number) => string;
}

type Option = {
  value: string,
  label: string
};

const columns: Column[] = [
  { id: "patientName", label: "Patient Name", minWidth: 100 },
  { id: "practiceName", label: "Practice Name", minWidth: 100 },
  { id: "gender", label: "Male", minWidth: 100 },
  { id: "birthDate", label: "Birth Date", minWidth: 100 },
  {
    id: "address",
    label: "Address",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action", // Rename the column to "Action"
    minWidth: 170, // Align the button to the right
  },
];


const ITEMS_PER_PAGE = 20;

const PatientsList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { control, handleSubmit, reset, setValue } = useForm<SearchPatientRequest>();

  const [showCard, setShowCard] = useState(false);

  const [searchCriteria, setSearchCriteria] = useState<SearchPatientRequest>({
    practiceIds: [],
    firstName: "",
    lastName: "",
    primaryInsurance: "",
    secondaryInsurance: "",
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const { userInfo } = useAuthSlice();
  const { practiceByUserRoleUserSlice } = useUserReducer();
  const { searchPatientList } = usePatientReducer();

  //Mount
  useEffect(() => {
  }, []);

  //Unmount
  useEffect(() => () => {
    dispatch(resetSearchPatientList());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId);
    if ((location.state && location.state.practiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
      if (location.state && location.state.withSearchHistory) {
        //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
        navigate("/practice_list", { state: { practiceListSearchCriteria: criteria } });
        return;
      }
      let searchCriteria: SearchPatientRequest = {
        practiceIds: [],
        firstName: "",
        lastName: "",
        primaryInsurance: "",
        secondaryInsurance: "",
        currentPage: 0,
        itemsPerPage: 0
      };
      if (location.state && location.state.practiceListSearchCriteria) {
        searchCriteria = location.state.practiceListSearchCriteria;
      } else {
        searchCriteria = criteria ? criteria : searchCriteria;
      }
      setSearchCriteria(searchCriteria);
      setValuesToCriteriaFields(searchCriteria);

      searchPracticesList(searchCriteria);
    } else {
      loadInitialPracticeList(1);
    }
  }, [location.state]);

  const setValuesToCriteriaFields = (searchCriteria: SearchPatientRequest) => {
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("firstName", searchCriteria.firstName);
    setValue("lastName", searchCriteria.lastName);
    setValue("primaryInsurance", searchCriteria.primaryInsurance);
    setValue("secondaryInsurance", searchCriteria.secondaryInsurance);
  }

  const loadInitialPracticeList = (pNumber: number = 1) => {
    const data: SearchPatientRequest = {
      practiceIds: [],
      firstName: "",
      lastName: "",
      primaryInsurance: "",
      secondaryInsurance: "",
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);

    searchPracticesList(data);
  }

  const searchPracticesList = (searchCriteria: SearchPatientRequest) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PATIENT + "SearchCriteria" + userInfo.userId, searchCriteria);
    dispatch(searchPatients(searchCriteria));
  }

  const onSubmit = (data: SearchPatientRequest) => {
    const data2: SearchPatientRequest = {
      practiceIds: data.practiceIds,
      firstName: data.firstName,
      lastName: data.lastName,
      primaryInsurance: data.primaryInsurance,
      secondaryInsurance: data.secondaryInsurance,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/patients", { state: { practiceListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/patients", { state: { practiceListSearchCriteria: data } });
  }

  const formattedPatientRows = useMemo(() => {
    const rows: PatientInfo[] = []
    searchPatientList?.content.forEach(patient => {
      const patientAddressInfo = patient.addressInfo;
      const row = {
        ...patient,
        patientName: fullName(null, patient.firstName, patient.middleName, patient.lastName),
        address: formatAddress(
          patientAddressInfo?.patientStreetAddress,
          patientAddressInfo?.patientUnitNumber,
          patientAddressInfo?.patientCity,
          patientAddressInfo?.patientState,
          patientAddressInfo?.patientZip
        )
      }
      rows.push(row)
    })
    return rows;
  }, [searchPatientList])


  const actionComponent = (row: any): JSX.Element => {
    return (
      <>
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="View"
          color="warning"
          size="small"
          variant="outlined"
          onClick={() => console.log(row)}
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>
      </>
    );
  }

  const practiceOptions = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRoleUserSlice && practiceByUserRoleUserSlice.length > 0) {
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleUserSlice, 'practiceName');
      sortedPracticeInfo?.map(practice => {
        options.push({ value: practice.practiceId, label: practice.practiceName });
      });
    }
    return options;
  }, [practiceByUserRoleUserSlice]);

  useEffect(() => {
    dispatch(getPracticesByUserRoleUserSlice(false));
  }, [dispatch]);

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Patient List" subtitle="Patient Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown control={control} name="practiceIds" label="Practice Name" options={practiceOptions} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="firstName" label="First Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="lastName" label="Last Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="primaryInsurance" label="Primary Insurance Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="secondaryInsurance" label="Secondary Insurance Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px", }} type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
                {/* {isMobile ?<LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="secondary"
                  startIcon={<AddCircleOutlineOutlined />}
                  fullWidth
                  onClick={() => console.log("Will Implement later.")}
                >
                  Add Patient
                </Button>
              </Grid>

            </Grid>
            <Table
              columns={columns}
              rows={formattedPatientRows || []}
              actionComponent={(row: any) => actionComponent(row)}
              emptyText="No Patient(s) Found"
              pageNumber={handlePagination}
              totalPages={searchPatientList?.totalPages}
              totalElementText={"Records: " + (searchPatientList?.totalElements ? searchPatientList?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
              showMenu={true}
            />
          </Card>
        </Box>
      </Box>
    </>
  );

}

export default PatientsList;