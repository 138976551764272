import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Divider,
  useMediaQuery,
  Tooltip,
  Link,
  IconButton,
} from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import Header from "../../common/Header";
import Button from "../../common/Button";
import Card from "../../common/Card";
import {
  OrderInfo,
  getOrderItemsByOrderId,
  TissueTrackingDetail,
  useOrderSlice,
  OrderItemDTO,
  fetchOrderDocumentById,
  orderItemInvoice,
  fetchOrderDocumentsByIds,
} from "../../../redux/slice/order/OrderSlice";
import { useForm } from "react-hook-form";
import {
  AssignmentOutlined,
  Cancel,
  CommentOutlined,
  NoteAddOutlined,
  WallpaperOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../../common/CustomCheckBox";
import { tokens } from "../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import dayjs from "dayjs";
import CustomIconButton from "../../common/CustomIconButton";
import CustomAlert from "../../common/CustomAlert";
import CustomSingleAlert from "../../common/CustomSingleAlert";
import { useCommonReducer } from "../../../redux/slice/commonSlice";
import PDFViewer from "../../common/pdf/PDFViewer";
import CustomTextField from "../../common/CustomTextField";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomItemList from "../../common/CustomItemList";
import CustomCommentPreview from "../../common/CustomCommentPreview";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsValueFoundInToken } from "../../../redux/slice/authSlice";
import { CAN_ACCESS_APPLICATION } from "../../../constants/applicationConstants";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomCommentPopup from "../../common/CustomCommentPopup";
import { priceFormatter } from "../../../utils/Utility";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  select: any;
  orderedGrafts: string;
  serialNumber: string;
  id: number;
  tissueTrackingId: number;
  productReturnId: number;
  orderStatus: string;
  comment: string;
  tissueTrackingDocumentId: number;
  tissueTrackingInfo: {
    barcodes: number[];
    imagesOfWounds: number[];
    medicalNotes: number[];
  };
  returnDocumentId: number;
  invoice: orderItemInvoice;
  invoiceId: number;
  uploadedProductPackageIds: number[];
  appRetDate: string | JSX.Element;
  invoiceDate: string | JSX.Element;
}

const Details = () => {
  const { control, setValue } = useForm<TissueTrackingDetail>({
    defaultValues: {
      patient: "",
      product: "",
      order: "",
      po: "",
      dateOfService: "",
      shippingCarrier: "",
      trackingNumber: "",
      orderStatus: "",
      providerName: "",
      practiceName: "",
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderInfo>();
  const { orderItemsListInfo, orderDocumentInfo } = useOrderSlice();
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [commentData, setCommentData] = useState<{ title: string; body: string }>({
    title: "",
    body: "",
  });

  const [showCommentAlert, setShowCommentAlert] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [showPDF, setShowPDF] = useState<{
    isShow: boolean;
    productName: string;
    fileName: string;
  }>({ isShow: false, productName: "", fileName: "" });
  const [serialNumbers, setSerialNumbers] = useState<{ [key: number]: string }>(
    {}
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const canAccessApplication = useSelector((state: RootState) =>
    selectIsValueFoundInToken(
      "canAccessApplication",
      CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT
    )(state)
  );

  const canViewCOGSPrice = useSelector((state: RootState) => selectIsValueFoundInToken("canViewCOGSPrice", CAN_ACCESS_APPLICATION.ORDER_MANAGEMENT )(state));

  // Mounting
  useEffect(() => {
    const orderDetails: OrderInfo = location?.state?.orderDetails;
    setOrder(orderDetails);
    if (orderDetails) {
      setInitialValues(orderDetails);
      dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    }
  }, [location?.state?.orderDetails, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event: { key: string }) => {
      if (event.key === "Escape") {
        setShowCommentAlert(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const setInitialValues = (orderDetails: OrderInfo) => {
    setValue("order", orderDetails.orderNumber);
    if (orderDetails.patientId) {
      setValue("patient", orderDetails.patientName);
    }
    setValue("product", orderDetails.productName);
    setValue("po", orderDetails.poNumber);
    setValue(
      "dateOfService",
      dayjs(orderDetails.dateOfService).format("MM/DD/YYYY")
    );
    setValue("shippingCarrier", orderDetails.shippingCarrier);
    setValue("trackingNumber", orderDetails.trackingNumber);
    setValue("orderStatus", orderDetails.orderStatus);
    setValue(
      "providerName",
      orderDetails.providerName || orderDetails?.orderInfo?.provider
    );
    setValue("practiceName", orderDetails.practiceName);
  };

  const getComment = (item: OrderItemDTO) => {
    if (item.tissueTrackingInfo && item.tissueTrackingInfo.comment) {
      return item.tissueTrackingInfo.comment;
    } else if (item?.returnTrackingInfo?.additionalContext) {
      return item.returnTrackingInfo.additionalContext;
    }
    return "";
  };

  const handlePreviewAction = async (row: Row) => {
    let documentId = row.tissueTrackingDocumentId
      ? row.tissueTrackingDocumentId
      : row.returnDocumentId;
    const actionResult = await dispatch(fetchOrderDocumentById(documentId));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({
        isShow: true,
        productName:
          order?.productName +
          (orderItemStatus(row) === `Returned (#${row.productReturnId})`
            ? ` - RETURN`
            : ` - TISSUE TRACKING`),
        fileName:
          orderItemStatus(row) === `Returned (#${row.productReturnId})`
            ? `RETURN_${order?.orderNumber}`
            : `TISSUE_TRACKING_${order?.orderNumber}`,
      });
    }

    handleMenuClose(row.id.toString());
  };

  const handleImageViewAction = async (row: Row) => {
    let documentIds =
      row.tissueTrackingInfo?.imagesOfWounds?.length > 0
        ? row.tissueTrackingInfo.imagesOfWounds
        : row.uploadedProductPackageIds?.length > 0
        ? row.uploadedProductPackageIds
        : [];
    const actionResult = await dispatch(fetchOrderDocumentsByIds(documentIds));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({
        isShow: true,
        productName:
          row.uploadedProductPackageIds?.length > 0
            ? order?.productName + " - PRODUCT PACKAGING IMAGES"
            : order?.productName +
              (orderItemStatus(row) === `Returned (#${row.productReturnId})`
                ? ` - RETURN`
                : ` - TISSUE TRACKING`),
        fileName:
          row.uploadedProductPackageIds?.length > 0
            ? `PRODUCT_PACKAGING_IMAGES_${order?.orderNumber}`
            : orderItemStatus(row) === `Returned (#${row.productReturnId})`
            ? `RETURN_${order?.orderNumber}`
            : `TISSUE_TRACKING_${order?.orderNumber}`,
      });
    }
    handleMenuClose(row.id.toString());
  };

  const handleViewMedicalNotesAction = async (row: Row) => {
    let documentIds = row.tissueTrackingInfo?.medicalNotes ?? [];
    const actionResult = await dispatch(fetchOrderDocumentsByIds(documentIds));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({
        isShow: true,
        productName: order?.productName + " - TISSUE TRACKING",
        fileName: `TISSUE_TRACKING_MEDICAL_NOTES_${order?.orderNumber}`,
      });
    }
    handleMenuClose(row.id.toString());
};

  const handleSerialNumberChange = (orderItemId: number, value: string) => {
    setSerialNumbers((prev) => ({ ...prev, [orderItemId]: value }));
  };

  // const checkSerialNumberPresent = (row: OrderItemDTO) => {
  //   const serialNumber = row.serialNumber;

  //   if (row?.tissueTrackingId || row?.productReturnId) {
  //     return true;
  //   } else {
  //     if (serialNumber === undefined || serialNumber === null || serialNumber.trim() === "") {
  //       return false;
  //     } else
  //       return true;
  //   }
  // };

  const dates = (row: OrderItemDTO) => {
    if (row.tissueTrackingId && row.appliedDate) {
      return dayjs(row.appliedDate).format("MM/DD/YYYY");
    } else if (row.productReturnId && row.returnedDate) {
      return dayjs(row.returnedDate).format("MM/DD/YYYY");
    } else {
      return "-";
    }
  };

  const orderItemRow: Row[] = useMemo(() => {
    let orderItems: Row[] = [];
    if (orderItemsListInfo) {
      orderItems = orderItemsListInfo
        .map((item: OrderItemDTO) => {
          return {
            select: <CustomCheckbox control={control} name="select" />,
            orderedGrafts: item.size,
            serialNumber: item.serialNumber,
            id: item.orderItemId,
            tissueTrackingId: item.tissueTrackingId,
            productReturnId: item.productReturnId,
            orderStatus: location?.state?.orderDetails?.orderStatus,
            comment: getComment(item),
            tissueTrackingDocumentId: item.tissueTrackingDocumentId,
            tissueTrackingInfo: {
              barcodes: item.tissueTrackingInfo?.barcodes || [],
              imagesOfWounds: item.tissueTrackingInfo?.imagesOfWounds || [],
              medicalNotes: item.tissueTrackingInfo?.medicalNotes || [],
            },
            returnDocumentId: item.returnDocumentId,
            invoice: item.invoice,
            invoiceId: item.invoiceId,
            uploadedProductPackageIds:
              item.returnTrackingInfo?.productBoxPictures || [],
            appRetDate: dates(item) || "N/A",
            invoiceDate: item.invoicedDate
              ? dayjs(item.invoicedDate).format("MM/DD/YYYY")
              : "-",
            units: item.units,
            extendedPrice: item.extendedPrice,
            invoiceNumber: item.mfgInvoiceNumber,
            quantity: item.quantity,
            hasSKUPrice: item.hasSKUPrice
          };
        })
        .sort((a, b) => a.units - b.units);
    }
    return orderItems;
  }, [orderItemsListInfo, control, location?.state?.orderDetails?.orderStatus]);

  // const orderItemStatus = (row: Row) => {
  //   if (row?.tissueTrackingId) {
  //     return `Applied (${row.invoice})`;
  //   } else if (row?.productReturnId) {
  //     return "Returned";
  //   }
  //   return row?.orderStatus === "DELIVERED" ? "Available" : "Pending";
  // };

  const orderItemStatus = useCallback((row: Row) => {
    if (row?.tissueTrackingId) {
      return `Applied (#${row.tissueTrackingId})`;
    } else if (row?.productReturnId) {
      return `Returned (#${row.productReturnId})`;
    } else if (row?.invoiceId) {
      return "Available";
    }
    // else if (row?.orderStatus === "DELIVERED") {
    //   return row?.invoiceId ? "Available (Invoiced)" : "Available";
    // }
    return "Pending";
  }, []);

  const detailsColumns = [
    { id: "orderedGrafts", label: "Ordered Size" },
    { id: "serialNumber", label: "Serial Number" },
    { id: "status", label: "Status" },
    { id: "appRetDate", label: "App/Ret Date" },
    { id: "invoiceDate", label: "Invoice" },
    { id: "action", label: "Action" },
    // { id: "images", label: "Images" },
    // { id: "comment", label: "Comment"}
  ];

  const [menuAnchor, setMenuAnchor] = useState<{
    [key: string]: null | HTMLElement;
  }>({});

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setMenuAnchor((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId: string) => {
    setMenuAnchor((prev) => ({ ...prev, [rowId]: null }));
  };

  const showCustomCommentPopup = (row: any) => {
    const title = `Comment ${row.tissueTrackingId ? "Record product usage" : "Return product"}`; 
    const body = row.comment || "";
    setCommentData({ title, body });
    setShowCommentPopup(true);
    handleMenuClose(row.id.toString());
  };

  const hideCustomCommentPopup = () =>{
    setShowCommentPopup(false);
  }

  const detailsRows = orderItemRow?.map((row: any) => {
    const hasMenuItems =
      row.tissueTrackingDocumentId ||
      row.returnDocumentId ||
      row.tissueTrackingInfo.imagesOfWounds?.length ||
      row.tissueTrackingInfo.medicalNotes?.length ||
      row.uploadedProductPackageIds?.length ||
      row.comment;
  
    return {
      ...row,
      status: orderItemStatus(row),
      action: hasMenuItems ? (
        <Box display="flex" alignItems="center" gap={0.5}>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.id.toString())}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchor[row.id]}
            open={Boolean(menuAnchor[row.id])}
            onClose={() => handleMenuClose(row.id.toString())}
          >
            {row.tissueTrackingDocumentId || row.returnDocumentId ? (
              <MenuItem onClick={() => handlePreviewAction(row)}>
                <CustomIconButton
                  tooltipPlacement="top"
                  color="info"
                  size="small"
                  variant="outlined"
                >
                  <AssignmentOutlined fontSize="small" />
                </CustomIconButton>
                <Typography ml={1}>Document</Typography>
              </MenuItem>
            ) : null}
  
            {row.tissueTrackingInfo.imagesOfWounds?.length ||
            row.uploadedProductPackageIds?.length ? (
              <MenuItem onClick={() => handleImageViewAction(row)}>
                <CustomIconButton
                  tooltipPlacement="top"
                  color="info"
                  size="small"
                  variant="outlined"
                >
                  <WallpaperOutlined fontSize="small" />
                </CustomIconButton>
                <Typography ml={1}>Image</Typography>
              </MenuItem>
            ) : null}

            { row.tissueTrackingId && row.tissueTrackingInfo.medicalNotes?.length ? (
              <MenuItem onClick={() => handleViewMedicalNotesAction(row)}>
                <CustomIconButton
                  tooltipPlacement="top"
                  color="info"
                  size="small"
                  variant="outlined"
                >
                  <NoteAddOutlined fontSize="small" />
                </CustomIconButton>
                <Typography ml={1}>Medical Note</Typography>
              </MenuItem>
            ) : null}

            {row.comment ? (
              <MenuItem onClick={() => showCustomCommentPopup(row)}>
                <CustomIconButton
                  tooltipPlacement="top"
                  color="info"
                  size="small"
                  variant="outlined"
                >
                  <CommentOutlined fontSize="small" />
                </CustomIconButton>
                <Typography ml={1}>
                  Comment{" "}
                  {row.tissueTrackingId
                    ? "Record product usage"
                    : "Return product"}
                </Typography>
              </MenuItem>
            ) : null}
          </Menu>
        </Box>
      ) : null,
      invoiceDate: (
        <Box display="flex" alignItems="center" gap={1}>
          {row.invoiceNumber && canAccessApplication ? (
            <>
              <Typography sx={{ color: "#42A5F5", cursor: "pointer" }}>
                <Link
                  component="button"
                  underline="hover"
                  variant="body2"
                  onClick={() =>
                    navigate("/invoice_list", {
                      state: { orderNumber: order?.orderNumber },
                    })
                  }
                  color={colors.info[300]}
                >
                  {row.invoiceNumber}
                </Link>
              </Typography>
              <Typography>
                {row.invoiceDate && <span>{row.invoiceDate}</span>}
              </Typography>
            </>
          ) : (
            <Typography>
              {row.invoiceNumber && row.invoiceDate
                ? `${row.invoiceNumber} ${row.invoiceDate}`
                : "-"}
            </Typography>
          )}
        </Box>
      ),
    };
  });
  

  const handleShipmentTracking = (order: OrderInfo) => {
    const carrierUrls: { [key: string]: string } = {
      FEDEX: `https://fedex.com/fedextrack/?trknbr=${order.trackingNumber}`,
      USPS: `https://tools.usps.com/go/TrackConfirmAction?tLc=3&text28777=&tLabels=${order.trackingNumber}%2C%2C`,
      DHL: `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${order.trackingNumber}&submit=1&inputsource=marketingstage`,
      UPS: `https://www.ups.com/track?tracknum=${order.trackingNumber}&loc=en_US&requester=QUIC/`,
    };

    if (order.trackingNumber && order.shippingCarrier in carrierUrls) {
      window.open(carrierUrls[order.shippingCarrier], "_blank");
    } else {
      console.warn("Unsupported carrier or missing tracking number", order);
    }
  };

  const totalPrice = detailsRows
  .filter((row) => !row.productReturnId)
  .reduce((sum, row) => {
    if (row.extendedPrice != null && row.units != null) {
      return sum + (row.hasSKUPrice ? row.extendedPrice : row.extendedPrice * row.units);
    }
    return sum;
  }, 0);

  return (
    <Box
      sx={{
        margin: {
          xs: "20px 10px",
          sm: "20px",
        },
      }}
    >
      <Header title="Order Details" subtitle="Order Details Management" />
      {showPDF && (
        <PDFViewer
          isOpen={showPDF.isShow}
          onClose={() =>
            setShowPDF({ isShow: false, productName: "", fileName: "" })
          }
          title={showPDF.productName}
          fileName={showPDF.fileName}
          base64String={orderDocumentInfo?.content || ""}
        />
      )}

      <Box
        sx={{
          border: 1,
          p: 2,
          pt: 4,
          borderRadius: 3,
          borderColor: colors.grey[700],
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={9} xs={12} sx={{ order: { xs: 2, md: 1 } }}>
            <Card sx={{ padding: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: colors.success[300],
                      marginBottom: 2,
                      padding: "10px 25px",
                    }}
                  >
                    Details
                  </Typography>

                  {detailsRows.map((row, index) => (
                    <Box key={index}>
                      <Box sx={{ padding: "2px 25px" }}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          sx={{
                            paddingY: 2,
                            flexWrap: {
                              xs: "wrap",
                              sm: "nowrap"
                            },
                            overflowX: "auto",
                          }}
                        >
                          <Grid item md={2} xs={3}>
                            <Typography color={colors.grey[500]}>
                              Ordered Size
                            </Typography>
                            <Typography>{row.orderedGrafts}</Typography>
                          </Grid>
                          <Grid item md={2} xs={5}>
                            <Typography color={colors.grey[500]}>
                              Serial Number
                            </Typography>
                            <Typography>{row.serialNumber}</Typography>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={4}
                            sx={{
                              textAlign: {
                                xs : "left",
                                sm : "center"
                              },
                            }}
                          >
                            <Typography color={colors.grey[500]}>
                              Status
                            </Typography>
                            <Chip
                              label={row.status}
                              size="small"
                              sx={{
                                backgroundColor:
                                  theme.palette.mode === "dark"
                                    ? "#2C3E50"
                                    : colors.grey[700],
                                padding: "1px 10px",
                                fontWeight: "bold",
                                border: "1px solid ",
                                borderColor:
                                  theme.palette.mode === "dark"
                                    ? "#34495E"
                                    : colors.grey[700],
                              }}
                            />
                          </Grid>
                          <Grid item md={2} xs={5}>
                            <Typography color={colors.grey[500]}>
                              App/ Ret Date
                            </Typography>
                            <Typography>{row.appRetDate}</Typography>
                          </Grid>
                          <Grid item md={2} xs={7}>
                            <Typography color={colors.grey[500]}>
                              Invoice
                            </Typography>
                            <Typography>{row.invoiceDate}</Typography>
                          </Grid>
                          {row.quantity != null && (
                              <Grid item md={1} xs={2}>
                                <Typography color={colors.grey[500]}>
                                  QTY
                                </Typography>
                                <Typography>
                                  {(row.quantity)}
                                </Typography>
                              </Grid>
                            )}
                          {canViewCOGSPrice &&
                            row.extendedPrice != null &&
                            row.units != null && (
                              <Grid item md={2} sx={{ textAlign: "right" }} xs={row.action ? 8 : 10}>
                                <Typography color={colors.grey[500]} textAlign="right">
                                  Order Amount
                                </Typography>
                                <Typography
                                  sx={{
                                    textDecoration: row.productReturnId ? "line-through red" : "none",
                                    textDecorationThickness: row.productReturnId ? "2px" : "initial",
                                  }}
                                >
                                  {priceFormatter(row.hasSKUPrice ? row.extendedPrice : row.extendedPrice * row.units)}
                                </Typography>
                              </Grid>

                            )}
                           
                            <Grid item md={1} xs={2}>
                              <Typography>{row.action}</Typography>
                            </Grid>
                         
                        </Grid>
                      </Box>
                      {index < detailsRows.length - 1 && (
                        <Box
                          sx={{
                            borderBottom: "1px dashed",
                            borderColor: colors.grey[700],
                          }}
                        />
                      )}
                    </Box>
                  ))}

                {canViewCOGSPrice &&(
                  <Box
                    sx={{
                      padding: "10px 25px",
                      marginTop: 2,
                      borderTop: "1px solid",
                      borderColor: colors.grey[700],
                    }}
                  >
                    <Grid
                        container
                        spacing={2}
                        sx={{
                          paddingY: 2,
                          flexWrap: "nowrap",
                          overflowX: "auto",
                        }}
                      >
                      <Grid item md={12} xs={6}>
                        <Typography variant="h6" fontWeight="bold">
                          TOTAL
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={6} sx={{ textAlign: "right"}}>
                        <Typography variant="h6" fontWeight="bold">
                          {priceFormatter(totalPrice)}
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                            <Typography></Typography>
                          </Grid>
                    </Grid>
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item md={3} xs={12} sx={{ order: { xs: 1, md: 2 } }}>
            <Card sx={{ padding: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box p={3}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ color: colors.success[300], marginBottom: 2 }}
                    >
                      Info
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Patient
                        </Typography>
                        <Typography>
                          {order?.patientName ||
                            order?.orderInfo?.patient ||
                            "-"}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Product
                        </Typography>
                        <Typography>{`${order?.productName} (${order?.manufacturerName})`}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Provider
                        </Typography>
                        <Typography>
                          {order?.provider || order?.orderInfo?.provider || "-"}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Practice
                        </Typography>
                        <Typography>{order?.practiceName || "-"}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Order #
                        </Typography>
                        <Typography> {order?.orderNumber || "-"}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Date of Service
                        </Typography>
                        <Typography> {order?.dateOfService || "-"}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      borderBottom: "1px dashed",
                      borderColor: colors.grey[700],
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box p={3}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ color: colors.success[300], marginBottom: 2 }}
                    >
                      Delivery
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Carrier
                        </Typography>
                        <Typography>{order?.shippingCarrier || "-"}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Shipping Tracking #
                        </Typography>
                        <Typography>
                          {order?.trackingNumber ? (
                            <Link
                              component="button"
                              underline="hover"
                              variant="body2"
                              onClick={() => handleShipmentTracking(order!)}
                              color={colors.info[300]}
                            >
                              {order.trackingNumber}
                            </Link>
                          ) : (
                            "No tracking number available"
                          )}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>Status</Typography>
                        <Typography> {order?.orderStatus || "-"}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>PO #</Typography>
                        <Typography> {order?.poNumber || "-"}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Grid display="flex" justifyContent="flex-end">
        <Grid item>
          <Button
            color="error"
            startIcon={<Cancel />}
            onClick={() => navigate(-1)}
            sx={{ mt: 2, mb: 4 }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <CustomCommentPopup
        open={showCommentPopup}
        onClose={hideCustomCommentPopup}
        title={commentData.title}
        commentBody={commentData.body}
      />
    </Box>
  );
};

export default Details;
