import { Box, Divider, FormControlLabel, Grid, InputLabel, Switch, switchClasses, Typography, useTheme, } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import {
  AddCircleOutlineOutlined, CancelOutlined, DeleteOutlineOutlined, DoDisturbAltOutlined, Download,
  ModeOutlined, Restore, SaveOutlined, ToggleOffOutlined, ToggleOnOutlined, Upgrade,
  UploadFileOutlined,
} from "@mui/icons-material";
import BorderedSection from "../../common/BorderedSection";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProductItemPopup from "./ProductItemPopup";
import ProductItemPricePricePopup from "./ProductItemPricePopup";
import ProductItemsPricePopup from "./ProductItemsPricePopup";
import {
  DocumentItemInfo, ProductInfo, ProductItemInfo, ProductItemPriceInfo, ProductItemPriceRequest, ProductItemsPriceInfo, createProduct, deleteProductPriceObj, fetchProductDocumentsByProductId, getProduct,
  inactivateProductPrice, inactiveProductDocument, initialValuesOfProductItem, initialValuesOfProductItemPrice, initialValuesOfProductItemsPrice, resetCreateProductSlice,
  resetFetchStatus, saveAllProductItemsPrices, saveProductItem, saveProductLogo, saveProductPriceItem, updateElementInProductSlice, updateProductDetails, useProductReducer
} from "../../../redux/slice/product/createProductSlice";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import CustomDatePicker from "../../common/CustomDatePicker";
import moment from "moment";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomTable from "../../common/CustomTable";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { calculateValue, hideElement } from "../../../utils/Utility";
import { fetchMac, fetchManufacturersList, useCommonReducer, setSingleAlertObj, SingleAlertInfo, hideSingleAlert } from "../../../redux/slice/commonSlice";
import { PRODUCTS_DOCUMENT_TYPE, PRODUCT_DOCUMENT_STATUS, PRODUCT_DOCUMENT_TYPES, png } from "../../../constants/applicationConstants";
import { FileUploader } from "../../common/FileUploader";
import ProductItemHistoryPopup from "./ProductItemHistoryPopup";
import ProductItemPriceHistoryPopup from "./ProductItemPriceHistoryPopup";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import DatePickerPopup from "../../common/DatePickerPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import { fetchDocumentById, fetchTemplateById } from "../../../redux/slice/practiceSlice";
import IconDetailToolBar from "../../common/IconDetailToolBar";
import { tokens } from "../../../theme";
import CustomIconButton from "../../common/CustomIconButton";
import dayjs from "dayjs";
import CustomSwitchButton from "../../common/CustomSwitchButton";
import ProductDocumentPopup from "./ProductDocumentPopup";
import CustomCommentPreview from "../../common/CustomCommentPreview";
import ProductDocumentHistory from "./ProductDocumentHistory";
import UpdateProductDocumentStatusPopup from "./UpdateProductDocumentStatusPopup";
import ProductAgreementPopup from "./ProductAgreementPopup";
import { styled } from '@mui/material/styles';


type Column = {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

export type ProductDocumentListInfo = {
  entityId: string,
  productTemplateId: number;
  type: string,
  documentType: string,
  entityName: string,
  docName: string,
  description: string
  comments: string | JSX.Element,
  documentURL: string | JSX.Element | null,
  productId: number,
  status: string | JSX.Element
  manufacturer: any;
}

export const SwitchTextTrack = styled(Switch)({
  width: 80,
  height: 48,
  padding: 8,
  [`& .${switchClasses.switchBase}`]: {
    padding: 11,
    color: "#ff6a00",
  },
  [`& .${switchClasses.thumb}`]: {
    width: 26,
    height: 26,
    backgroundColor: "#fff",
  },
  [`& .${switchClasses.track}`]: {
    background: "linear-gradient(to right,rgb(161, 161, 161),rgb(84, 84, 84))",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#fff",
      textAlign: "center",
      fontSize: "0.75rem",
      fontWeight: 500,
    },
    "&:before": {
      content: '"ON"',
      left: 4,
      opacity: 0,
    },
    "&:after": {
      content: '"OFF"',
      right: 4,
    },
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#185a9d",
      transform: "translateX(32px)",
      "&:hover": {
        backgroundColor: "rgba(24,90,257,0.08)",
      },
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: "#fff",
    },
    [`& + .${switchClasses.track}`]: {
      background: "linear-gradient(to right,rgb(0, 196, 88),rgb(0, 97, 29))",
      "&:before": {
        opacity: 1,
      },
      "&:after": {
        opacity: 0,
      },
    },
  },
});

const Product = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [productItemObject, setProductItemObject] = useState<ProductItemInfo>(initialValuesOfProductItem);
  const [priceItemObject, setPriceItemObject] = useState<ProductItemPriceInfo>(initialValuesOfProductItemPrice);
  const [priceItemsObject, setPriceItemsObject] = useState<ProductItemsPriceInfo>(initialValuesOfProductItemsPrice);
  const [tableRowIndex, setTableRowIndex] = useState<number | null>(null);
  const [editForm, setEditForm] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [inactiveOnly, setInactiveOnly] = useState(false);
  const [isProductDocumentPopupOpen, setProductDocumentPopupOpen] = useState(false);

  const [showProductItemPopup, setShowProductItemPopup] = useState(false);
  const [showProductItemPricePopup, setShowProductItemPricePopup] = useState(false);
  const [showProductItemsPricePopup, setShowProductItemsPricePopup] = useState(false);

  const [showProductItemHistoryPopup, setShowProductItemHistoryPopup] = useState(false);
  const [showProductItemPriceHistoryPopup, setShowProductItemPriceHistoryPopup] = useState(false);
  const [showProductAgreementPopup, setShowProductAgreementPopup] = useState(false);

  const [productPriceRows, setProductPriceRows] = useState<any[]>([]);

  const [productItemRows, setProductItemRows] = useState<any[]>([]);

  const [optionPopupShow, setOptionPopupShow] = useState<OptionPopupObjectProps>({ message: "", show: false });

  const [datePickerPopupObj, setDatePickerPopupObj] = useState
                                                  <{label: string, title: string, open: boolean, validationArray: any, submitDate: (date: string)=> void}>
                                                  ({label: "", title: "", open: false, validationArray: [], submitDate: (date: string)=> {}});

  const [showProductDocumentHistoryPopup, setProductDocumentHistoryPopup] = useState(false);
  const [showDocInactivePopup, setShowDocInactivePopup] = useState<{isShow: boolean, productId: number, documentId: number | string}>({isShow: false, productId: 0, documentId: 0 });

  const { control, handleSubmit, getValues, setValue, watch, reset } = useForm<ProductInfo>();

  const { productObject, getProductStatus, saveProductItemStatus, saveProductPriceStatus,
    updateProductStatus, saveProductLogoStatus, productDocumentInfo, createProductStatus, 
    inactivateProductDocumentStatus } = useProductReducer();
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const { macInfoList, manufacturerInfoList } = useCommonReducer();

  const [productCategories, setProductCategories] = useState<CodeTable[]>([]);

  const isDownloading = useRef(false);

  // validate user level authorizations
  const canEditProduct = useSelector((state: RootState) => selectIsTokenAvailable("canEditProduct")(state));
  const canViewProductHistory = useSelector((state: RootState) => selectIsTokenAvailable("canViewProductHistory")(state));
  const canChangeProductDocumentStatus = useSelector((state: RootState) => selectIsTokenAvailable("canChangeProductDocumentStatus")(state));
  const canInactivateProductDocument = useSelector((state: RootState) => selectIsTokenAvailable("canInactivateProductDocument")(state));
  const canViewProductTemplateSection = useSelector((state: RootState) => selectIsTokenAvailable("canViewProductTemplateSection")(state));
  const [isEditing, setIsEditing] = useState(false);
  const [statusData, setStatusData] = useState<any>({
    productId: "",
    documentId: "",
    manufacturerId: null,
    status: "",
    documentType: ""
  });
  const [showUpdateStatusPopup, setUpdateStatusPopup] = useState(false);
  const [originalStatusValue, setOriginalStatusValue] = useState<any>();
  const [productManufacturerId, setProductManufacturerId] = useState<number | null>(null);
  const [productDocumentType, setProductDocumentType] = useState<string>("");
  const [productDocumentName, setProductDocumentName] = useState<string>("");
  const [productDocumentTypeMap, setProductDocumentTypeMap] = useState<Record<string, string>>({});


  const manufacturersList = useMemo(() => {
    let options: { label: string, value: any }[] = [];
    manufacturerInfoList?.map((option) => {
      options.push({ label: option.manufacturerName, value: option.manufacturerId })
    });
    return options;
  }, [manufacturerInfoList]);

  const discounts = useMemo(() => {
    if (allCodeTablesValuesMap)
      return getCodeTableValuesByTableName(allCodeTablesValuesMap, "ProductDiscount")
    return [];
  }, [allCodeTablesValuesMap]);

  // added discount prices to the ProductItemPrice table data
  const generateProductItemPriceRows = useCallback((productPrices: any) => {
    let modifiedArray: any[] = [];
  
    // Ensure productPrices is an array
    if (Array.isArray(productPrices)) {
      // Use forEach to modify and push into the array directly
      productPrices.forEach((obj: any) => {
        let modifiedObj = setDiscountPricesToTable(obj, discounts);
        
        if (modifiedObj.priceType === "MAC") {
          let macName = macInfoList?.find(option => option.macId === obj.macId)?.macName;
          modifiedObj["priceType"] = "Local Fee Schedule" + (macName ? " - " + macName : "");
        } else if (modifiedObj.priceType === "NATIONAL") {
          modifiedObj["priceType"] = "National";
        }
  
        // Push the modified object to the array
        modifiedArray.push(modifiedObj);
      });
    } else {
      console.warn("Invalid productPrices:", productPrices); 
    }
  
    return modifiedArray;
  }, [discounts, macInfoList]);
  
  
  // added discount prices to the ProductItemPrice table data
  const generateProductItemPriceRowsOnAdd = useCallback((productPrices: any) => {
  
    // Wrap the entire object in a single-element array if it's defined
    const productPricesArray = productPrices ? [productPrices] : [];
  
    let modifiedArray: any[] = [];
  
    // Use forEach to modify and push into the array directly
    productPricesArray &&  productPricesArray.forEach(obj => {
      let modifiedObj = setDiscountPricesToTable(obj, discounts);
      if (modifiedObj.priceType === "MAC") {
        let macName = macInfoList?.find(option => option.macId === obj.macId)?.macName;
        modifiedObj["priceType"] = "Local Fee Schedule" + (macName ? " - " + macName : "");
      } else if (modifiedObj.priceType === "NATIONAL") {
        modifiedObj["priceType"] = "National";
      }
  
      // Push the modified object to the array
      modifiedArray.push(modifiedObj);
    });
  
    // Ensure you're returning the modified array
    return modifiedArray;
  
  }, [discounts, macInfoList]);

  const generateProductItemsRows = useCallback((productItems: any) => {
    let modifiedArray: any[] = [];
    productItems?.forEach((obj: any) => {
      modifiedArray.push({
        ...obj,
        listPrice: (
          <CustomCurrencyField
            externalValue={obj.listPrice}
            displayType="text"
          />
        ),
        customPrice: (
          <CustomCurrencyField
            externalValue={obj.customPrice}
            displayType="text"
          />
        ),
        allowableUnitPrice: (
          <CustomCurrencyField
            externalValue={obj.allowableUnitPrice}
            displayType="text"
          />
        )
      });
    });
    return modifiedArray;
  }, []);

  const setDiscountPricesToTable = (data: any, discounts: CodeTable[]) => {
    let obj = {
      ...data,
      listPrice: <CustomCurrencyField externalValue={data?.listPrice} displayType="text" />,
      customPrice: <CustomCurrencyField externalValue={data?.customPrice} displayType="text" />,
      allowableUnitPrice: <CustomCurrencyField externalValue={data?.allowableUnitPrice} displayType="text" />,
      inactiveDate: data.inactiveDate  ? data.inactiveDate : "-"

    }
    discounts?.map((discount) => {
      obj[discount.displayValue] = <CustomCurrencyField externalValue={calculateValue(discount.displayValue, data?.allowableUnitPrice)} displayType="text" />
    })
    return obj;
  }

  //mount
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let id = urlSearchParams.get('id');
    if (id) {
      setEditForm(true);
      dispatch(getProduct({ productId: parseInt(id) }));
      dispatch(fetchProductDocumentsByProductId(parseInt(id)));
    } else {
      setValue("isAgreementRequired", true);
      setValue("productStatus", "TBD");
    }
    dispatch(fetchMac());
    dispatch(fetchManufacturersList());
  }, []);

  // unmount
  useEffect(() => () => {
    dispatch(resetCreateProductSlice());
  }, []);

  useEffect(() => {
    if (productObject.productId > 0 && getProductStatus === "success") {
      let productObj = { ...productObject };

      let docObj: DocumentItemInfo = {
        documentId: 0,
        documentName: "",
        type: "",
        fileType: "",
        content: ""
      };

      if (productObj.documents && productObj.documents.length > 0) {
        for (let i: number = 0; i < productObj.documents.length; i++) {
          docObj = productObj.documents[i];
          if (docObj && docObj.type === "PRODUCTLOGO" && docObj.content && docObj.content.length > 0) {
            productObj.productLogo = "data:image/png;base64," + docObj.content;
          }
        }
      }

      reset(productObj);
      setProductItemRows(generateProductItemsRows((productObject.productItems)))
      if(saveProductPriceStatus ==="success"){
        setProductPriceRows(generateProductItemPriceRowsOnAdd(productObject.productPrices?productObject.productPrices.newProductPrice:productObject.productPrices))
      }else{
        setProductPriceRows(generateProductItemPriceRows(productObject.productPrices))
      }
    }
  }, [productObject, getProductStatus,saveProductPriceStatus]);

  useEffect(()=>{
    if (saveProductItemStatus === "success"){
      setValue("productItems", productObject.productItems);
      setProductItemRows(generateProductItemsRows((productObject.productItems)));
    }
  },[saveProductItemStatus])

  useEffect(()=>{
    if (saveProductPriceStatus === "success"){
      setProductPriceRows(generateProductItemPriceRowsOnAdd(productObject.productPrices?productObject.productPrices.newProductPrice:productObject.productPrices))
      setShowProductAgreementPopup(true)
    }
  },[saveProductPriceStatus])

  useEffect(() => {
    if (!showProductItemPopup)
      setProductItemObject(initialValuesOfProductItem);
  }, [showProductItemPopup]);

  useEffect(() => {
    if (!showProductItemPricePopup)
      setPriceItemObject(initialValuesOfProductItemPrice);
  }, [showProductItemPricePopup]);

  useEffect(() => {
    if (createProductStatus === "success") {
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: `Product: ${getValues("productName")} has been created successfully.`,
        alertType: "success",
      }
      dispatch(setSingleAlertObj(Obj));
    }
    else if (createProductStatus === "error") {
      let Obj: SingleAlertInfo = {
        title: "Error",
        message: `Product: ${getValues("productName")} creation unsuccessful.`,
        alertType: "error",
      }
      dispatch(setSingleAlertObj(Obj));
    }
  }, [createProductStatus]);

  useEffect(()=>{
    if (updateProductStatus ==="success"){
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: "Successfully updated the product details",
        alertType:"success",
      }
      dispatch(setSingleAlertObj(Obj));
    }
    if (saveProductLogoStatus ==="success"){
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: "Successfully save the product logo",
        alertType:"success",
      }
      dispatch(setSingleAlertObj(Obj))
    }
    setTimeout(()=>{
      dispatch(hideSingleAlert());
    },5000)
    dispatch(resetFetchStatus());
  },[updateProductStatus, saveProductLogoStatus]);

  useEffect(() => {
    if(inactivateProductDocumentStatus === 'success') {
      setShowDocInactivePopup({
        isShow: false,
        productId: 0,
        documentId: 0
      });
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: "Successfully inactivated the product document",
        alertType:"success",
      }
      dispatch(setSingleAlertObj(Obj));
      dispatch(updateElementInProductSlice({
        elementName: 'inactivateProductDocumentStatus',
        value: 'idle'
      }));
      dispatch(fetchProductDocumentsByProductId(productObject?.productId));
    }
  }, [inactivateProductDocumentStatus]);

  const onConfirmInactivateProductPrice = useCallback(async (obj: { date: string, productPriceId: number }) => {
    setOptionPopupShow({ message: "", show: false });
    continueInactivateFuther(obj.date, obj.productPriceId);
  }, []);

  const continueInactivateFuther = useCallback((date: string, productPriceId: number) => {
    dispatch(inactivateProductPrice({ productPriceId: productPriceId, date: date }));
    closePricingPopup();
  }, []);

  const onInactivePriceClick = useCallback((row: any, rowIndex: number) => {
    setTableRowIndex(rowIndex);
    setEditItem(true);
    setInactiveOnly(true);
    setPriceItemObject(watch("productPrices") ? getValues("productPrices")[rowIndex] : initialValuesOfProductItemPrice);
    setShowProductItemPricePopup(true);
  }, []);

  const onEditClick = useCallback((row: any, rowIndex: number, fieldName: string) => {
    setTableRowIndex(rowIndex);
    setEditItem(true);
    if (fieldName === "productItems") {
      setProductItemObject(row);
      setShowProductItemPopup(true);
    } else if (fieldName === "productPrices") {
      setPriceItemObject(watch("productPrices")?getValues("productPrices")[rowIndex]: initialValuesOfProductItemPrice);
      setShowProductItemPricePopup(true);
    }
  }, []);

  const onDeleteProductItemClick = useCallback((rowIndex: number) => {
    let existingProductItem = getValues("productItems");
    existingProductItem.splice(rowIndex, 1);
    setValue("productItems", existingProductItem);
    setProductItemRows(generateProductItemsRows((existingProductItem)));
  }, []);

  const onDeleteProductPriceClick = useCallback((rowIndex: number, productPriceId: number ,practiceProducts:number) => {
    if (productPriceId ) {
      if(productPriceId> 0 && practiceProducts===0){
      setOptionPopupShow({
        message: "Do you really want to delete this price?",
        show: true,
        variant: "custom",
        title: "Confirm",
        buttons: [{ name: "YES", color: 'primary', onClick: onConfirmDeleteProductPrice }, { name: "NO", color: 'secondary', onClick: cancelOptionPopup }],
        obj: {rowIndex: rowIndex, productPriceId: productPriceId}
      })}else{
         let errorObj: SingleAlertInfo = {
                message: "Product Price can not be deleted as it is associated with agreement!",
                alertType: "error",
              };
              dispatch(setSingleAlertObj(errorObj));
      }
    } else {
      removeProductPriceFromTable(rowIndex);
    }
  }, []);

  const onConfirmDeleteProductPrice = useCallback(async (obj: { rowIndex: number, productPriceId: number }) => {
    setOptionPopupShow({ message: "", show: false });

    try {
      let response = await dispatch(deleteProductPriceObj({ productPriceId: obj.productPriceId }));
      
      if (response.payload && response.payload.code === "V3OK" && response.payload.message === "SUCCESS"
        && (!response.payload.errors || response.payload.errors.length === 0)) {
        let Obj: SingleAlertInfo = {
          title: "Success",
          message: "Successfully deleted the pricing.",
          alertType: "success",
        }
        dispatch(setSingleAlertObj(Obj));
      }
    } catch (error) {
      console.log("response.error");
      console.log(error);
    }
  }, []);

  const removeProductPriceFromTable = useCallback((rowIndex: number) => {
    let existingProductItemPrice = getValues("productPrices");
    existingProductItemPrice.splice(rowIndex, 1);
    setValue("productPrices", existingProductItemPrice);
    setProductPriceRows(generateProductItemPriceRows(existingProductItemPrice));
  }, []);

  const addProductItem = useCallback((productItem: ProductItemInfo) => {
    if (editForm)
      saveProductItems(productItem)
    else {
      let existingProductItem = getValues("productItems");
      if (tableRowIndex !== undefined && tableRowIndex !== null && tableRowIndex >= 0) {
        existingProductItem[tableRowIndex] = productItem;
        setValue("productItems", existingProductItem);
        setProductItemRows((existingProductItem))

      } else if (existingProductItem) {
        setValue("productItems", [...existingProductItem, productItem]);
        setProductItemRows(([...existingProductItem, productItem]))
      } else {
        setValue("productItems", [productItem]);
        setProductItemRows(([productItem]))
      }
    }
    setTableRowIndex(null);
  }, [watch("productItems"), tableRowIndex, editForm, productObject.productId]);

  const addProductItemPrice = useCallback((productItemPrice: ProductItemPriceInfo) => {
    if (editForm) {
      if (inactiveOnly) {
        if ((!productItemPrice.practiceProducts || productItemPrice.practiceProducts.length === 0)) {
          continueInactivateFuther(productItemPrice.inactiveDate, productItemPrice.productPriceId);
        } else {
          setOptionPopupShow({
            message: productItemPrice.practiceProducts.length + " number of agreements will be inactivated effective of " + productItemPrice.inactiveDate + " do you want to continue?",
            show: true,
            variant: "custom",
            title: "Confirm",
            buttons: [{ name: "YES", color: 'primary', onClick: onConfirmInactivateProductPrice }, { name: "NO", color: 'secondary', onClick: cancelOptionPopup }],
            obj: {date: productItemPrice.inactiveDate, productPriceId: productItemPrice.productPriceId}
          });
        }
      } else {
        saveProductPriceItems(productItemPrice);
        closePricingPopup();
      }
    } else {
      let existingProductItemPrice = getValues("productPrices");
      if (tableRowIndex !== undefined && tableRowIndex !== null && tableRowIndex >= 0) {
        existingProductItemPrice[tableRowIndex] = productItemPrice;
        setValue("productPrices", existingProductItemPrice);
        setProductPriceRows(generateProductItemPriceRows(existingProductItemPrice))
      } else if (existingProductItemPrice) {
        setValue("productPrices", [...existingProductItemPrice, productItemPrice]);

        setProductPriceRows(generateProductItemPriceRows([...existingProductItemPrice, productItemPrice]))
      } else {
        setValue("productPrices", [productItemPrice]);

        setProductPriceRows(generateProductItemPriceRows([productItemPrice]))
      }
      closePricingPopup();
    }
  }, [watch("productPrices"), tableRowIndex, productObject.productId, macInfoList]);

  const saveProductItemsPrices = useCallback((productPricesItems: any[]) =>{
    //api call
    const productPriceItems = {
      ProductItemPrices: productPricesItems,
      productId: productObject.productId
    }
    dispatch(saveAllProductItemsPrices({productPriceItems: productPriceItems}));
  }, [productObject])

  const addProductItemsPrice = useCallback((productItemPrice: any) => {
    const transformedData = productItemPrice.productItems.map((item: any) => ({
      ...item,
      effectiveDate: productItemPrice.effectiveDate,
      inactiveDate: productItemPrice.inactiveDate,
      priceType: "NATIONAL"
    }));

    let existingProductItems = getValues("productItems");

    // Update existingProductItems with prices from transformedData
    const updatedProductItems = existingProductItems.map((existingItem: any) => {
      const matchingItem = transformedData.find(
        (transformedItem: any) => transformedItem.sku === existingItem.sku
      );
      if (matchingItem) {
        return {
          ...existingItem,
          listPrice: matchingItem.listPrice,
          customPrice: matchingItem.customPrice,
          allowableUnitPrice: matchingItem.allowableUnitPrice,
          effectiveDate: matchingItem.effectiveDate
        };
      }
      return existingItem;
    });
    
    if (editForm) {
      saveProductItemsPrices(transformedData);
    }
    setValue("productItems", updatedProductItems);
    setProductItemRows(generateProductItemsRows((updatedProductItems)))
    closeItemsPricingPopup();
    
  }, [watch("productPrices"), tableRowIndex, productObject.productId]);

  const closePricingPopup = useCallback(() => {
    setShowProductItemPricePopup(false);
    setTableRowIndex(null);
    setEditItem(false);
    setInactiveOnly(false);
  }, []);

  const closeItemsPricingPopup= useCallback(() => {
    setShowProductItemsPricePopup(false);
    setTableRowIndex(null);
    setEditItem(false);
    setInactiveOnly(false);
  }, []);

  const saveProductItems = useCallback((productItem: ProductItemInfo) =>{
    let Obj: ProductItemInfo= {
      ...productItem,
      inactiveDate: productItem.inactiveDate? moment(productItem.inactiveDate?.toString())?.format("MM/DD/YYYY") : "",
      productId: productObject.productId,
    };
    dispatch(saveProductItem({productItem: Obj}))
  }, [productObject]);

  const saveProductPriceItems = useCallback((productPriceItem: ProductItemPriceInfo) =>{
    let Obj: ProductItemsPriceInfo= {
      ProductItemPrices: [productPriceItem],
      productId: productObject.productId,
    }

    dispatch(saveProductPriceItem({productPriceItem: Obj}));
  }, [productObject])

  const handleDropzoneChange = async (uploadedFiles: File, fieldName: any) => {
    let base64File = "";
    let fileName: string = "";
    if (uploadedFiles) {
      base64File = String(await convertBase64(uploadedFiles));
      fileName = uploadedFiles.name;
    }
    setValue(fieldName, base64File);
    
    let existingdocuments: DocumentItemInfo[] = getValues("documents");

    let docObj : DocumentItemInfo = {
      documentId: 0,
      documentName: "",
      type: "",
      fileType: "",
      content: ""
    };

    if (existingdocuments) {
      
        docObj = existingdocuments[0];
        if (fieldName === "productLogo" && docObj.type === "PRODUCTLOGO") {
          docObj.documentName = fileName;
          docObj.fileType = "png";
        } else {
          docObj.type = "PRODUCTLOGO";
          docObj.documentName = fileName;
          docObj.fileType = "png";

          existingdocuments.push(docObj);
        }
      
    } else {
      existingdocuments = [];
      docObj.type = "PRODUCTLOGO";
      docObj.documentName = fileName;
      docObj.fileType = "png";

      existingdocuments.push(docObj);
    }

    setValue("documents", existingdocuments);
    if (editForm){
      docObj.content = base64File && base64File.length > 23
      ? base64File.substring("data:image/png;base64,".length, base64File.length) : base64File;
      dispatch(saveProductLogo({logoData: {productLogoId: docObj.documentId,productId: productObject.productId, document: docObj}}));
    }
  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const confirmOptionPopup = () => {
    handleSubmit(updateProduct)()
    setOptionPopupShow({ message: "", show: false });
  }

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  }

  const updateProduct = (data: ProductInfo) =>{
    let Obj = {
      productId: data.productId,
      manufacturerId: data.manufacturerId,
      productName: data.productName,
      hcpcsCode: data.hcpcsCode,
      inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : "",
      productDescription: data.productDescription,
      composition: data.composition,
      insuranceCoverage: data.insuranceCoverage,
      isAgreementRequired: data.isAgreementRequired,
      mues: data.mues,
      productCategory: data.productCategory,
      productStatus: data.productStatus,
      hasSKUPrice: data.hasSKUPrice,
    }
    dispatch(updateProductDetails({product: Obj}));
  }

  const openPriceAddingPopup = useCallback(() => {
    let existingProductPriceList = getValues("productPrices");
    if (!editForm && existingProductPriceList && existingProductPriceList.length > 0) {
      for (let i: number = 0; i < existingProductPriceList.length; i++) {
        if (existingProductPriceList[i].priceType === "NATIONAL") {
          let errorObj: SingleAlertInfo = {
            message: "A National pricing is already exists. If you want to change pricing please update existing one or delete it first.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
          return;
        }
      }
    }
    setShowProductItemPricePopup(true);
  }, [editForm, watch("productPrices")]);

  const openItemsPriceAddingPopup = useCallback(() => {
    if (getValues("productItems").length <= 0) {
      let Obj: SingleAlertInfo = {
        title: "warning",
        message: `Please add at least one product item to add pricing`,
        alertType: "warning",
      }
      dispatch(setSingleAlertObj(Obj));
    } else {
      const convertedPriceItmsObj : ProductItemsPriceInfo = {
        productId: productObject?.productId,
        ProductItemPrices: getValues("productItems")
      }
      setPriceItemsObject(watch("productItems")?convertedPriceItmsObj: initialValuesOfProductItemsPrice);
      setShowProductItemsPricePopup(true);
    }
  }, [editForm, watch("productItems")]);

  const updateProductValues = () =>{
    {setOptionPopupShow({message: "Do You Really want to update product details? ", 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:confirmOptionPopup },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]})}
  }

  const onSubmit = useCallback((data: ProductInfo) => {
    const { productDocumentStatus, ...submitObj } = data;

    let obj: ProductInfo = {
      ...submitObj,
      hasSKUPrice: !!getValues("hasSKUPrice"),
      inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : "",
    }

    obj.saveMode = "FULLPRODUCT";

    let docObj : DocumentItemInfo = {
      documentId: 0,
      documentName: "",
      type: "",
      fileType: "",
      content: ""
    };

    if (obj.documents && obj.documents.length > 0) {
      for (let i: number = 0; i < obj.documents.length; i++) {
        docObj = obj.documents[i];
        if (docObj.type === "PRODUCTLOGO") {
          docObj.content = obj.productLogo && obj.productLogo.length > 23
            ? obj.productLogo.substring("data:image/png;base64,".length, obj.productLogo.length) : obj.productLogo;
          obj.productLogo = "";
        }
      }
    }
    dispatch(createProduct({ product: obj, navigate: navigate }));
  }, []);

  // handle product document download
  const handleProductDocumentView = async (documentId: string, entityName: string) => {
    if (isDownloading.current) return;
    isDownloading.current = true;

    try {
      if (entityName && entityName === PRODUCTS_DOCUMENT_TYPE.PRODUCT_DOCUMENT) {
        const actionResult = await dispatch(fetchDocumentById(documentId));
        const documentData = actionResult.payload;
        if (documentData && documentData.content) {
          downloadBase64File(documentData.content, documentData.documentName, documentData.fileType);
        }
      } else if (entityName && entityName === PRODUCTS_DOCUMENT_TYPE.PRODUCT_TEMPLATE) {
        const actionResult = await dispatch(fetchTemplateById(documentId));
        const documentData = actionResult.payload;
        if (documentData && documentData.content) {
          downloadBase64File(documentData.content, documentData.templateFileName, 'image/jpeg');
        }
      } else {
        let errorObj: SingleAlertInfo = {
          message: 'No Document found for the product',
          alertType: 'error'
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    } finally {
      isDownloading.current = false;
    }
  };

  // convert image string to byte array and download file
  const downloadBase64File = (base64String: string, fileName: string, mimeType: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  }

  const productItemActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => onEditClick(row, rowIndex, "productItems")}
          tooltipMessage="Edit Item" tooltipPlacement='bottom'>
          <ModeOutlined fontSize="small" />
        </CustomIconButton>
        {(!row.productItemId || row.productItemId === 0) ?
          <>
            {" "}
            <CustomIconButton color="error" sx={{ pl: 2 }} size="small" variant="outlined" onClick={() => onDeleteProductItemClick(rowIndex)}
              tooltipMessage="Delete Item" tooltipPlacement='bottom'>
              <DeleteOutlineOutlined fontSize="small" />
            </CustomIconButton>
          </>
          : null
        }
      </>
    );
  }, [editForm]);
 

  const priceItemActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    //
 /* {!editForm ? 
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => onEditClick(row, rowIndex, "productPrices")}
         tooltipMessage="Edit" tooltipPlacement='bottom'>
         <ModeOutlined fontSize="small" />
       </CustomIconButton>
        : null} */
 /* {editForm ?
         <>{" "}<CustomIconButton color="warning" size="small" variant="outlined"
           onClick={() => onInactivePriceClick(row, rowIndex)} 
           style={{ display: `${hideElement(row && dayjs(row.inactiveDate).isValid())}` }}
           tooltipMessage="Inactivate" tooltipPlacement='bottom'>
           <DoDisturbAltOutlined fontSize="small" />
         </CustomIconButton></>
         : null
       } */
  //
   return (
     <>
     <CustomIconButton
       color="error"
       size="small"
       variant="outlined"
       onClick={() => onDeleteProductPriceClick(rowIndex, row.productPriceId,row.practiceProducts?.length)}
       tooltipMessage="Delete"
       tooltipPlacement="bottom"
     >
       <DeleteOutlineOutlined fontSize="small" />
     </CustomIconButton>
   </>
)
 }, [editForm]);

  const productDocActionComponent = useCallback((row: ProductDocumentListInfo, rowIndex: number): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleProductDocumentView(row.entityId, row.entityName)}
          tooltipMessage="Download" tooltipPlacement='bottom'>
          <Download />
        </CustomIconButton>
        {canInactivateProductDocument &&(
          <CustomIconButton color="warning" size="small" variant="outlined" onClick={() => {onDocInactiveClick(row)}}
            tooltipMessage="Inactivate" tooltipPlacement='bottom'>
            <DoDisturbAltOutlined />
          </CustomIconButton>
      )}
        {/* <CustomIconButton color="error" size="small" variant="outlined" onClick={() => {}}
          tooltipMessage="Delete" tooltipPlacement='bottom'>
          <DeleteOutlineOutlined fontSize="small" />
        </CustomIconButton> */}
      </>
    );
  }, [editForm]);

  const productTemplateActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleProductDocumentView(row.entityId, row.entityName)}
          tooltipMessage="Download" tooltipPlacement='bottom'>
          <Download />
        </CustomIconButton>
      </>
    );
  }, [editForm]);

  const handleToggleButtonChange = useCallback((e: ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => {
    const newState = e.target.checked;
    const productItems = getValues('productItems');

    let productItem = productItems.find(pi => pi.productItemId === row.productItemId);
    productItem = productItem && {...productItem, isSizeActive: newState}
    productItem && saveProductItems(productItem);

    const alertObj: SingleAlertInfo = {
      message: newState ? "Product Item is now marked as Available." : "Product Item is now marked as Unavailable.",
      alertType: newState ? "success" : "warning",
    };
    dispatch(setSingleAlertObj(alertObj));

  }, [getValues, setValue, productObject]);

  const toggleAvailabilityButton = useCallback((row: any, rowIndex: number) => {
    const isInactivateSwitchToggled = row?.isSizeActive === null ? true : row.isSizeActive;

    return (
      <CustomSwitchButton
        controllerName={`isSizeActive.${rowIndex}`}
        control={control}
        checked={isInactivateSwitchToggled}
        onChange={(e) => handleToggleButtonChange(e, row, rowIndex)}
        // color="success"
        size="small"
        sx={{
          marginLeft: 2,
          "& .MuiSwitch-switchBase": {
            color: theme.palette.error.main, // Use theme color for off state
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: theme.palette.success.main, // Use theme color when checked
          },
          "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: theme.palette.error.main, // Track color for off state
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.success.main, // Track color when checked
          },
        }}
      />
    );
  }, [control, handleToggleButtonChange]);
  
  const columnsItem: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "sku", label: "SKU", minWidth: 100 },
    ]
    if (editForm) {
      columnArray.push({ id: "inactiveDate", label: "Inactive Date", minWidth: 100 },);
    }
    columnArray.push(
      { id: "size", label: "Size", minWidth: 100},
      { id: "units", label: "# of Units", minWidth: 100},
      { id :"productItemDescription", label: "Description", minWidth: 100}
    );
    if (canEditProduct || !editForm) {
      columnArray.push({ id: "action", label: "Action", minWidth: 100 });
    }

    if (canEditProduct && editForm) {
      columnArray.push({ id: "isSizeActive", label: "Availability", minWidth: 100});
       
     }
    return columnArray;
  }, [canEditProduct, editForm]);    

  const columnsItemPrices: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "sku", label: "SKU", minWidth: 100 },
    ]
    columnArray.push(
      { id: "size", label: "Size", minWidth: 100},
      { id: "units", label: "# of Units", minWidth: 100},
      { id :"productItemDescription", label: "Description", minWidth: 100}
    );
    columnArray.push(
      { id: "listPrice", label: "List Price", minWidth: 100 },
      { id: "allowableUnitPrice", label: "Allowable Price", minWidth: 100 },
      { id: "customPrice", label: "Custom Price", minWidth: 100},
    );
    columnArray.push(
      { id: "effectiveDate", label: "Effective Date", minWidth: 100 }
    );
    if (editForm) {
      columnArray.push({ id: "inactiveDate", label: "Inactive Date", minWidth: 100 },);
    }
    if (canEditProduct || !editForm) {
      columnArray.push({ id: "action", label: "Action", minWidth: 100});
    }

    if (canEditProduct && editForm) {
      columnArray.push({ id: "isSizeActive", label: "Availability", minWidth: 100});
       
     }
    return columnArray;
  }, [canEditProduct, editForm]);    

  const columnsPriceItem: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "effectiveDate", label: "Effective Date", minWidth: 100  },
    ]
    if (editForm) {
      columnArray.push({ id: "inactiveDate", label: "Inactive Date", minWidth: 100  });
    }
    columnArray.push(
      { id: "priceType", label: "File Type", minWidth: 100 },
      { id: "listPrice", label: "List Price", minWidth: 100, align : "right"},
      { id: "allowableUnitPrice", label: "Allowable Price", minWidth: 100, align : "right"}
    );
    // discounts?.map((discount) => {
    //   columnArray.push({ id: discount.displayValue, label: discount.displayValue, minWidth: 100, align: "right" });
    // });
    columnArray.push({ id: "customPrice", label: "Custom Price", minWidth: 100, align : "right"});
    if (canEditProduct || !editForm) {
      columnArray.push({ id: "action", label: "Action", minWidth: 100});
    }
    return columnArray;
  }, [discounts, editForm]);

  const columnsTemplateDocs: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "type", label: "Type", minWidth: 80 },
      { id: "docName", label: "Name", minWidth: 100 },
      { id: "description", label: "Description", minWidth: 120 },
      // { id: "status", label: "Status", minWidth: 120 },
      { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
      { id: "action", label: "Download", minWidth: 80 },
    ];
    return columnArray;
  }, [editForm]);

  const legacyItemsProduct = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <ToggleOffOutlined color="error" />, text: "Unavailable" },
    { icon: <ToggleOnOutlined color="success" />, text: "Available" },
  ];

  const legacyItemsPrice = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" }
  ];

  const legacyItemsProductDocuments = [
    // { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" },
    { icon: <Download color="info" />, text: "Download" },
  ];

  const legacyItemsProductTemplates = [
    { icon: <Download color="info" />, text: "Download" },
  ];

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      const sortedProductCategoryList = [
        ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "productCategory"),
      ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      setProductCategories(sortedProductCategoryList);
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  const statusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: "ACTIVE", label: "ACTIVE" },
      { value: "INACTIVE", label: "INACTIVE" },
      { value: "TBD", label: "TBD" },
      { value: "INPROGRESS", label: "IN PROGRESS" },
    ];
    return options;
  }, []);

  const productCategoryOptions = useMemo(() => {
    return productCategories.map(item => ({
      label: item.displayValue,
      value: item.keyName
    }));
  }, [productCategories]);

  useEffect(() => {
    let productItems = getValues("productItems") || [];
    const formattedProductRows = productItems.map(prev => {
      return {
        ...prev,
        isSizeActive : prev.isSizeActive === null ? true : prev.isSizeActive
      }
    });

    const sortedProductRows = formattedProductRows.sort((a, b) => {
      return Number(a.units) - Number(b.units);
    });

    if(JSON.stringify(productItems) !== JSON.stringify(sortedProductRows)) {
      setValue('productItems', sortedProductRows);
    }
  }, [watch('productItems')]);

  
  const columnsDocuments: Column[] = [
    { id: "documentType", label: "Type", minWidth: 100 },
    { id: "docName", label: "Name", minWidth: 150 },
    { id: "manufacturer", label: "Manufacturer", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "effectiveDate", label: "Effective Date", minWidth: 75 },
    { id: "inactiveDate", label: "Inactive Date", minWidth: 75 },
    // { id: "fileName", label: "File Name", minWidth: 100 },
    { id: "comments", label: "Comment", minWidth: 75 },
    // { id: "updatedTime", label: "Updated Time", minWidth: 100, align: "center" },
    { id: "documentURL", label: "Document URL", minWidth: 150, },
    { id: "action", label: "Action" }
  ];

  
  const commentComponent = useCallback((row: any) => {
    return (<>
      <Grid item>
        {
          row.comments ?
            <CustomCommentPreview
              title={`Comment`}
              commentBody={row.comments}
            /> : "N/A"}
      </Grid>
    </>)
  }, [productDocumentInfo]);

  const URLComponent = useCallback((row: any) => {
    const url = typeof row.documentURL === "string" ? row.documentURL : null;
  
    if (!url) {
      return null;
    }
  
    return (
      <>
        <Grid>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: colors.info[300] }}
          >
            {url.length > 30 ? `${url.substring(0, 30)}...` : url}
          </a>
        </Grid>
      </>
    );
  }, [colors.info]);
  

  const onDocInactiveClick = useCallback((row: ProductDocumentListInfo) => {
    setShowDocInactivePopup({
      isShow: true,
      productId: row.productId,
      documentId: row.entityId
    });
  },[productDocumentInfo]);

  const submitDocInactiveDate = (date: string) => {
    const selectedDate = dayjs(date);
    dispatch(inactiveProductDocument({inactiveRequest: {
      productId: showDocInactivePopup?.productId,
      documentId: showDocInactivePopup?.documentId,
      inactiveDate: date
    }}))
  };

  const productDocumentStatusOpts = [ 
    {label: PRODUCT_DOCUMENT_STATUS.PENDING, value: PRODUCT_DOCUMENT_STATUS.PENDING},
    {label: PRODUCT_DOCUMENT_STATUS.FEEDBACK, value: PRODUCT_DOCUMENT_STATUS.FEEDBACK}, 
    {label:PRODUCT_DOCUMENT_STATUS.DEV, value: PRODUCT_DOCUMENT_STATUS.DEV},
    {label:PRODUCT_DOCUMENT_STATUS.STAGE, value: PRODUCT_DOCUMENT_STATUS.STAGE},
    {label:PRODUCT_DOCUMENT_STATUS.LIVE, value: PRODUCT_DOCUMENT_STATUS.LIVE},
    {label:PRODUCT_DOCUMENT_STATUS.INACTIVE, value: PRODUCT_DOCUMENT_STATUS.INACTIVE},
  ];

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      const sortedDocumentList = [
        ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "ProductDocument"),
      ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      const productDocumentTypeMap = Object.fromEntries(
        sortedDocumentList.map(item => [item.keyName, item.displayValue])
      );
      setProductDocumentTypeMap(productDocumentTypeMap)
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  const renderStatusComponent = (status: string, entityId: number) => {
    if (canChangeProductDocumentStatus && isEditing && status) {
      return (
        <>
          <CustomDropdownField
            name={`productDocumentStatus.status${entityId}`}
            control={control}
            onChangeSelect={(data) => {
              const inputData = data?.value;
              if (inputData !== statusData.status) {
                setStatusData({ ...statusData, status: inputData, manufacturerId: productManufacturerId, documentType: productDocumentType, docName: productDocumentName });
                setUpdateStatusPopup(true);
              }
            }}
            options={productDocumentStatusOpts || []}
          />
        </>
      );
    } else {
      return status;
    }
  };

  const productTemplateRows: ProductDocumentListInfo[] = useMemo(() => {
    const rows: ProductDocumentListInfo[] = [];
    productDocumentInfo?.forEach(pd => {
      if (pd.entityName === 'Template') {
        const row = {
          ...pd,
        }
        rows.push(row)
      }
    })
    return rows;
  }, [productDocumentInfo]);

  const statusUpdateDocumentTypes = [
    PRODUCT_DOCUMENT_TYPES.IVR_FORM,
     PRODUCT_DOCUMENT_TYPES.ORDER_FORM, 
     PRODUCT_DOCUMENT_TYPES.SERVICE_AGREEMENT, 
     PRODUCT_DOCUMENT_TYPES.TISSUE_TRACKING_FORM, 
     PRODUCT_DOCUMENT_TYPES.RETURN_FORM
    ];

  const productDocumentRows: any[] = useMemo(() => {
    const rows: any[] = []
    productDocumentInfo?.forEach(pd => {
      if (pd.entityName === 'Document' && pd.docName !== null) {
        const row = {
          ...pd,
          documentType: productDocumentTypeMap[pd.documentType],
          documentURL: URLComponent(pd),
          comments: commentComponent(pd),
          manufacturer: pd.manufacturer?.manufacturerName || "",
          status: statusUpdateDocumentTypes.includes(pd.documentType) ?
          (  
            statusData.documentId === pd.entityId ?
            (pd.status && renderStatusComponent((pd.status).toString(), parseInt(pd.entityId))) :
            (
              <span
                onClick={() => {
                  //@ts-ignore
                  setValue(`productDocumentStatus.status${pd.entityId}`, pd.status)
                  setStatusData({
                    productId: pd.productId,
                    documentId: pd.entityId, 
                    status: pd.status && (pd.status).toString(), 
                    manufacturerId: pd.manufacturer?.manufacturerId,
                    documentType: pd.documentType,
                    docName: pd.docName
                  });
                  setIsEditing(true); 
                  setOriginalStatusValue(pd.status);
                  setProductManufacturerId(pd.manufacturer?.manufacturerId);
                  setProductDocumentType(pd.documentType);
                  setProductDocumentName(pd.docName);
                }}
                style={{ cursor: "pointer", display: "inline-block" }}
              >
                {pd.status && (pd.status).toString()}
              </span>
            )
          ) : <span>{pd.status && (pd.status).toString()}</span>
        }
        rows.push(row);
      }
    })
    return rows;
  }, [URLComponent, commentComponent, productDocumentInfo]);

  return (
    <>
      {showProductItemPopup && <ProductItemPopup
        open={showProductItemPopup}
        onClose={() => { setShowProductItemPopup(false); setTableRowIndex(null); setEditItem(false) }}
        addProductItem={addProductItem}
        productItemDetails={productItemObject}
        isEdit={editItem}
      />}

      {/* {showProductItemPopup && getValues("hasSKUPrice") && <ProductItemsPricesPopup
        open={showProductItemPopup}
        onClose={() => { setShowProductItemPopup(false); setTableRowIndex(null); setEditItem(false) }}
        addProductItem={addProductItem}
        productItemDetails={productItemObject}
        isEdit={editItem}
      />} */}

      {showProductItemsPricePopup && <ProductItemsPricePopup
        open={showProductItemsPricePopup}
        onClose={() => { closeItemsPricingPopup(); }}
        addProductItemsPrice={addProductItemsPrice}
        productItemPriceObject={priceItemsObject}
        editPricing={editItem}
        editProduct={editForm}
        rowIndex={tableRowIndex}
        inactiveOnly={inactiveOnly}
        productItemPriceList={getValues("productPrices")}
        productItemsList={getValues('productItems')}
      />}

      { showProductItemPricePopup && <ProductItemPricePricePopup
        open={showProductItemPricePopup}
        onClose={() => {closePricingPopup();}}
        addProductItemPrice={addProductItemPrice}
        productItemPriceObject={priceItemObject}
        editPricing={editItem}
        editProduct={editForm}
        rowIndex={tableRowIndex}
        inactiveOnly={inactiveOnly}
        productItemPriceList = {getValues("productPrices")}
      /> }
      <ProductItemHistoryPopup
        open={showProductItemHistoryPopup}
        onClose={() => setShowProductItemHistoryPopup(false)}
      />
      {showProductAgreementPopup&&(<>
      <ProductAgreementPopup
        open={showProductAgreementPopup}
        onClose={() => setShowProductAgreementPopup(false)}
      />
      </>)}
      <ProductItemPriceHistoryPopup
        open={showProductItemPriceHistoryPopup}
        onClose={() => setShowProductItemPriceHistoryPopup(false)}
      />
      {showProductDocumentHistoryPopup &&
        <ProductDocumentHistory
          open={showProductDocumentHistoryPopup}
          onClose={() => {setProductDocumentHistoryPopup(false)}}
        />
      }
      <Box m="15px">
        <Header title="Product Management"
          subtitle={(canEditProduct || !editForm) ? "Please enter the information related to the product that you are adding or updating" : ""} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
              <FileUploader
                name="productLogo"
                label={<>Upload Your Logo here <br/>in <InputLabel sx={{ color: 'darkorange', display: 'inline'}}>.png</InputLabel > format</>}
                onFileChange={() => { }}
                onFileChangeTest={handleDropzoneChange}
                allowedFileTypes={png}
                document={productObject?.documents?.[0]}
                optionPopupObject={editForm ? (productObject?.documents?.[0] ? { show: true, message: "Do you really want to change the product logo?" }
                  : { show: true, message: "Do you really want to save the product logo?" }) : undefined}
                disableActions={!(canEditProduct || !editForm)}
              />
            </Box>
            
            <Grid container spacing={2} alignContent={"center"}>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Product Name" controllerName="productName" control={control} rules={{ required: "Product Name is required." }}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="HCPCS Code" controllerName="hcpcsCode" control={control} rules={{required: "HCPCS Code is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Composition" control={control} controllerName="composition" rules={{required: "Composition is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Insurance Coverage" control={control} controllerName="insuranceCoverage" rules={{required: "Insurance Coverage is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="MUEs" controllerName="mues" control={control} rules={{required: "MUEs is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomDropdownField label="Manufacturer" name="manufacturerId" disabled={!(canEditProduct || !editForm)}
                  options={manufacturersList} control={control} rules={{required: "Manufacturer is required."}} />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField label="Product Description" controllerName="productDescription" control={control} rules={{required: "Product Description is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={2} xs={12}>
                <CustomDropdownField name="productCategory" control={control} label="Product Category" options={productCategoryOptions} disabled={!canEditProduct} rules={{ required: "Product Category is required." }} />
              </Grid>
              <Grid item md={2} xs={12}>
                <CustomDropdownField name="productStatus" control={control} label="Status" options={statusOptions} disabled={!canEditProduct} rules={{ required: "Status is required." }} />
              </Grid>
              <Grid item md={2} xs={12}>
                <Controller name={"isAgreementRequired"} control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Switch {...field} name={field.name} checked={field.value}
                            size="medium" disabled={!(canEditProduct || !editForm)} onChange={(e) => { field.onChange(e); }} />
                        }
                        label={"Agreement Required"} labelPlacement="start"
                        sx={{ color: fieldState.error ? theme.palette.error.main : 'inherit',
                          "& .MuiSwitch-switchBase": {
                            color: theme.palette.error.main, // Use theme color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: theme.palette.success.main, // Use theme color when checked
                          },
                          "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                            backgroundColor: theme.palette.error.main, // Track color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: theme.palette.success.main, // Track color when checked
                          },
                         }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Controller name={"hasSKUPrice"} control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormControlLabel
                        control={
                          <SwitchTextTrack {...field} name={field.name} checked={field.value}
                            size="medium" disabled={!(canEditProduct || !editForm)} 
                            onChange={(e) => { 
                              field.onChange(e);
                              setValue("productItems", []);
                              setProductItemRows(generateProductItemsRows(([])));
                            }} 
                          />
                        }
                        disabled={editForm}
                        label={"Has SKU Price"} labelPlacement="start"
                      />

                      {/* <FormControlLabel
                        control={
                          <Switch 
                            {...field} name={field.name} checked={field.value}
                            size="medium" disabled={!(canEditProduct || !editForm)} 
                            onChange={(e) => { 
                              field.onChange(e);
                              setValue("productItems", []);
                              setProductItemRows(generateProductItemsRows(([])));
                            }} 
                           
                          />
                        }
                        disabled={editForm}
                        label={"Has SKU Price"} labelPlacement="start"
                        sx={{ color: fieldState.error ? theme.palette.error.main : 'inherit',
                          "& .MuiSwitch-switchBase": {
                            color: theme.palette.error.main, // Use theme color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: theme.palette.success.main, // Use theme color when checked
                          },
                          "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                            backgroundColor: theme.palette.error.main, // Track color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: theme.palette.success.main, // Track color when checked
                          },
                         }}
                      /> */}
                    </>
                  )}
                />
              </Grid>
            </Grid>
           
            {(canEditProduct && editForm) ?
              <>
                <Grid container justifyContent="flex-end">
                  <Button sx={{ marginTop: 2 }} onClick={updateProductValues} startIcon={<Upgrade />}>Update Product</Button>
                </Grid>
                <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: 0, mt: 2 }} />
              </>
              :
              <></>
            }

            <Box mt={3}>
              <BorderedSection title="Product Items">
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }} spacing={1}>
                    {(canEditProduct || !editForm) &&
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                        <IconDetailToolBar items={legacyItemsProduct} />
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canViewProductHistory && editForm) ?
                        <Button sx={{ marginRight: 2 }} color="info"
                          onClick={() => setShowProductItemHistoryPopup(true)} startIcon={<Restore />} fullWidth>
                          Items History
                        </Button> : <></>
                      }
                      
                    </Grid>
                  <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                    {(canEditProduct || !editForm) ?
                      <Button color="info" startIcon={<AddCircleOutlineOutlined />}
                        onClick={() => setShowProductItemPopup(true)} fullWidth>
                        Add Item
                      </Button>
                      : <></>
                    }
                  </Grid>
                  <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                    {(getValues("hasSKUPrice") && (canEditProduct || !editForm)) ?
                      <Button color="info" startIcon={<AddCircleOutlineOutlined />}
                      onClick={openItemsPriceAddingPopup} fullWidth>
                        Add Items Prices
                      </Button>
                      : <></>
                    }
                  </Grid>
                </Grid>
                {getValues("hasSKUPrice") ?
                  <CustomTable columns={columnsItemPrices} rows={productItemRows}
                    actionComponent={(row: any, rowIndex: number) => productItemActionComponent(row, rowIndex)}
                    availabilitySwitchComponent={(row: any, rowIndex: number) => toggleAvailabilityButton(row, rowIndex)}
                    disablePagination={true} borderBox={true} emptyText="No Product Item(s) Found" showMenu={true} />
                  :
                  <CustomTable columns={columnsItem} rows={getValues('productItems') || []}
                    actionComponent={(row: any, rowIndex: number) => productItemActionComponent(row, rowIndex)}
                    availabilitySwitchComponent={(row: any, rowIndex: number) => toggleAvailabilityButton(row, rowIndex)}
                    disablePagination={true} borderBox={true} emptyText="No Product Item(s) Found" showMenu={true} />}
              </BorderedSection>
            </Box>
            
            {!getValues("hasSKUPrice") &&
            <Box mt={3}>
              <BorderedSection title="Product Pricing">
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }} spacing={1}>
                     {(canEditProduct || !editForm) && 
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                        <IconDetailToolBar items={legacyItemsPrice} />
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canViewProductHistory && editForm) ?
                        <Button sx={{ marginRight: 2 }} color="info"
                          onClick={() => setShowProductItemPriceHistoryPopup(true)} startIcon={<Restore />} fullWidth>
                          Pricing History
                        </Button> : <></>
                      }
                    </Grid>
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canEditProduct || !editForm) ?
                        <Button color="info" startIcon={<AddCircleOutlineOutlined />} onClick={openPriceAddingPopup} fullWidth>
                          Add Pricing
                        </Button> : <></>
                      }
                    </Grid>
                </Grid>
                <CustomTable columns={columnsPriceItem} rows={productPriceRows}
                  actionComponent={(row: any, rowIndex: number) => priceItemActionComponent(row, rowIndex)}
                  disablePagination={true} borderBox = {true} emptyText="No Product Pricing(s) Found" showMenu={true}/>
              </BorderedSection>
            </Box>
            }

            {editForm &&
              <Box mt={3}>
                <BorderedSection title="Product Templates">
                  {(productTemplateRows && productTemplateRows.length > 0) ?
                    <CustomTable columns={columnsTemplateDocs} rows={productTemplateRows || []}
                      actionComponent={(row: any, rowIndex: number) => productTemplateActionComponent(row, rowIndex)}
                      disablePagination={true} borderBox={true}  showMenu={true}/>
                    :
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center" sx={{ fontSize: '16px', fontWeight: 'bold', fontFamily: 'Arial' }}>
                        No product templates found
                      </Typography>
                    </Grid>
                  }
                </BorderedSection>
              </Box>
            }

            <Box mt={3}>
              <BorderedSection title="Product Document">
              {(canEditProduct || !editForm) && 
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }} spacing={1}>
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                        <IconDetailToolBar items={legacyItemsProductDocuments} />
                      </Grid>
                <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                    <Button color="info" 
                      startIcon={<UploadFileOutlined />} 
                      onClick={() => setProductDocumentHistoryPopup(true)} 
                      style={{ display: `${hideElement(!editForm)}` }} 
                      fullWidth>
                       Product Document History
                    </Button>
                  </Grid>
                  <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                    <Button color="info" 
                      startIcon={<UploadFileOutlined />} 
                      onClick={() => setProductDocumentPopupOpen(true)} 
                      style={{ display: `${hideElement(!editForm)}` }} 
                      fullWidth>
                       Upload Document
                    </Button>
                  </Grid>
                </Grid>
                }
                {((productDocumentRows && productDocumentRows.length > 0)) ?
                <CustomTable columns={columnsDocuments} rows={ productDocumentRows || []}
                  actionComponent={(row: any, rowIndex: number) => productDocActionComponent(row, rowIndex)}
                  disablePagination={true} borderBox={true}  showMenu={true}/>
                  :
                <Grid item xs={12}>
                  <Typography variant="body2" align="center" sx={{ fontSize: '16px', fontWeight: 'bold', fontFamily: 'Arial' }}>
                    {editForm ? "No product documents found" : "Please upload the product documents in product edit"}
                    </Typography>
                </Grid>
                }
              </BorderedSection>
            </Box>

            <Grid container justifyContent={{ xs: 'center', md: 'flex-end' }} spacing={2} pt={2}>
              <Grid item xs={12} md="auto">
                {!editForm ?
                  <Button color="success" startIcon={<SaveOutlined />} type="submit" fullWidth={true}>
                    Save Product
                  </Button> : <></>
                }
              </Grid>
              <Grid item xs={12} md="auto">
                <Button color="error" startIcon={<CancelOutlined />} onClick={() => navigate("/products_list", {state: {withSearchHistory: true}})} fullWidth={true}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
      
      {optionPopupShow.show &&
        <OptionsPopup
          open={optionPopupShow.show}
          onClose={() => setOptionPopupShow({ message: "", show: false })}
          variant={optionPopupShow.variant}
          message={<>{optionPopupShow.message}</>}
          buttons={optionPopupShow.buttons}
          title={optionPopupShow.title}
          obj={optionPopupShow.obj}
        />
      }
      {datePickerPopupObj.open &&
        <DatePickerPopup
          open={datePickerPopupObj.open}
          title={datePickerPopupObj.title}
          label={datePickerPopupObj.label}
          submitDate={(date: string) => datePickerPopupObj.submitDate(date)}
          validationArray={datePickerPopupObj.validationArray}
          onClose={() => setDatePickerPopupObj({ label: "", title: "", open: false, validationArray: [], submitDate: (date: string) => { } })}
        />
      }
      {isProductDocumentPopupOpen && <ProductDocumentPopup open={isProductDocumentPopupOpen} onClose={() => setProductDocumentPopupOpen(false)} />}

       {showDocInactivePopup &&
                <DatePickerPopup
                open={showDocInactivePopup?.isShow}
                onClose={() => setShowDocInactivePopup({ isShow: false, productId: 0, documentId: 0 }) }
                title={"Set Inactive Date"} label={""}
                submitDate={submitDocInactiveDate}
              />
       } 

      {showUpdateStatusPopup && (
        <UpdateProductDocumentStatusPopup
          open={showUpdateStatusPopup}
          statusData={statusData}
          originalStatusValue={originalStatusValue}
          onClose={() => {
            setUpdateStatusPopup(false);
            setIsEditing(false);
            setStatusData("");
          }}
        />
      )}
    </>
  );
};

export default Product;