// dropzone file extensions - start

// application types
export const pdf = {"application/pdf": [".pdf"]}
export const doc = {"application/msword": [".doc"]}
export const docx = {"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]}
export const xls = {"application/vnd.ms-excel": [".xls"]}
export const xlsx = {"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]}
export const zip = {"application/zip": [".zip"]}
//text types
export const csv = {"text/csv": [".csv"]}
export const txt = {"text/plain": [".txt"]}
export const rtf = {"text/rtf": [".rtf"]}
export const html = {"text/html": [".html", ".htm"]}
//audio types
export const mp3 = {"audio/mpeg": [".mp3"]}
export const wma = {"audio/x-ms-wma": [".wma"]}
//video types
export const mpg = { "video/mpeg": [".mpg", ".mpeg"] };
export const flv = { "video/x-flv": [".flv"] };
export const avi = { "video/x-msvideo": [".avi"] };
export const mp4 = { "video/mp4": [".mp4"] };
export const ogv = { "video/ogg": [".ogv"] };
export const ts = { "video/mp2t": [".ts"] };
export const webm = { "video/webm": [".webm"] };
export const mkv = { "video/x-matroska": [".mkv"] };
export const mov = { "video/quicktime": [".mov"] };
export const wmv = { "video/x-ms-wmv": [".wmv"] };
export const video3gp = { "video/3gpp": [".3gp"] };
export const video3g2 = { "video/3gpp2": [".3g2"] };
export const m4v = { "video/x-m4v": [".m4v"] };
export const asf = { "video/x-ms-asf": [".asf"] };
export const f4v = { "video/mp4": [".f4v"] }; // F4V is a variant of MP4
export const rm = { "application/vnd.rn-realmedia": [".rm"] };
export const rmvb = { "application/vnd.rn-realmedia-vbr": [".rmvb"] };
export const mxf = { "application/mxf": [".mxf"] };
export const vob = { "video/dvd": [".vob"] };
export const dv = { "video/dv": [".dv"] };
export const amv = { "video/x-amv": [".amv"] };
export const mpeg = { "video/mpeg": [".mpeg"] }; // Alternative for MPEG
//image types
export const jpeg = {"image/jpeg": [".jpg", ".jpeg"]}
export const png = {"image/png": [".png"]}
export const gif = {"image/gif": [".gif"]}

// dropzone file extensions - end

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const CAN_ACCESS_APPLICATION = {
  DASHBOARD : "DASHBOARD",
  IVR_MANAGEMENT : "IVR_MANAGEMENT",
  ORDER_MANAGEMENT : "ORDER_MANAGEMENT",
  USER_MANAGEMENT : "USER_MANAGEMENT",
  USER_CREATE : "USER_CREATE",
  PRACTICE : "PRACTICE",
  PATIENT: "PATIENT",
  PRODUCTS : "PRODUCTS",
  TEMPLATES : "TEMPLATES",
  PROVIDER : "PROVIDER",
  PRODUCT_AGREEMENT : "PRODUCT_AGREEMENT",
  AGENCY_MANAGEMENT : "AGENCY_MANAGEMENT",
  INVOICE_MANAGEMENT : "INVOICE_MANAGEMENT",
  DOCUMENT_MANAGEMENT : "DOCUMENT_MANAGEMENT",
  BAA_MANAGEMENT : "BAA_MANAGEMENT",
  CONFIG_MANAGEMENT:"CONFIG_MANAGEMENT",
  AUDITLOG_MANAGEMENT:"AUDITLOG_MANAGEMENT",
  MANUFACTURER_MANAGEMENT:"MANUFACTURER_MANAGEMENT",
  HEALING_BIOLOGIX: "HEALING_BIOLOGIX",
  PRODUCT_SEARCH : "PRODUCT_SEARCH",
  PROVIDER_MANAGEMENT: "PROVIDER_MANAGEMENT"
}

export const USER_ROLES = {
  ROLE_ADMIN : "ROLE_ADMIN",
  ROLE_PRACTICEADMIN : "ROLE_PRACTICEADMIN",
  ROLE_MANUFACTURERADMIN : "ROLE_MANUFACTURERADMIN",
  ROLE_MANUFACTURERSTAFF : "ROLE_MANUFACTURERSTAFF",
  ROLE_MANUFACTURERIVR: "ROLE_MANUFACTURERIVR",
  ROLE_MANUFACTURERSERVICE: "ROLE_MANUFACTURERSERVICE",
  ROLE_PRACTICESTAFF : "ROLE_PRACTICESTAFF",
  ROLE_BILLER : "ROLE_BILLER",
  ROLE_AGENCYADMIN : "ROLE_AGENCYADMIN",
  ROLE_AGENCYSTAFF : "ROLE_AGENCYSTAFF",
  ROLE_ACCOUNTEXECUTIVE : "ROLE_ACCOUNTEXECUTIVE",
  ROLE_ONLY_CREATE: "ONLY_CREATE",
  ROLE_PROVIDER: "ROLE_PROVIDER"
};

export const PRACTICE_USER = "Practice Users";
export const AGENCY_USER = "Agency Users";
export const MANUFACTURE_USER = "Manufacturer Users";

export const AGREEMENT_STATUS = {
  INITIATED : "INITIATED",
  PRACTICE_SIGNED : "PRACTICE SIGNED",
  EXECUTED : "EXECUTED",
  INACTIVE : "INACTIVE",
  DELETED : "DELETED",
  HELD : "HELD"
};

export const AGREEMENT_ACTIONS = {
  DELETE : "DELETE",
  PREVIEW : "PREVIEW",
  INITIATE : "INITIATE",
  INACTIVATE : "INACTIVATE",
  AGREEMENT: "AGREEMENT"
}

export const PRODUCT_STATUS = {
  PLACED: "PLACED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED",
  PROCESSING: "PROCESSING",
};

export const ORDER_INVOICE_STATUS = {
  PENDING: "PENDING",
  PARTIAL: "PARTIAL",
  INVOICED: "INVOICED",
};

export const ORDER_IVR_STATUS = {
  WITH_IVR : "WITH_IVR",
  WITHOUT_IVR : "WITHOUT_IVR"
}

export const ORDERTYPE = {
  DIRECT_ORDER : "Direct Purchase Order",
  CONSIGNMENT_ORDER : "Consignment Order",
  DIRECT_ORDER_WITHOUT_IVR: "Direct Order Without Ivr",
  PAST_ORDER: "Past Order"
};

export const BENEFIT_SUMMARY_STATUS = {
  DENIED : "DENIED",
  NEED_MORE_INFO: "NEEDS_MORE_INFO",
  VERIFIED: "VERIFIED"
}

export const PRODUCTS_DOCUMENT_TYPE = {
  PRODUCT_DOCUMENT : "Document",
	PRODUCT_TEMPLATE : "Template",
};

export const PRODUCT_DOCUMENT_STATUS = {
  PENDING: "PENDING",
  FEEDBACK: "FEEDBACK",
  DEV: "DEV",
  STAGE: "STAGE",
  LIVE: "LIVE",
  INACTIVE: "INACTIVE"
};

export const PRODUCT_DOCUMENT_TYPES = {
  IVR_FORM: "IVR_FORM",
  ORDER_FORM: "ORDER_FORM",
  SERVICE_AGREEMENT: "SERVICE_AGREEMENT",
  TISSUE_TRACKING_FORM: "TISSUE_TRACKING_FORM",
  RETURN_FORM: "RETURN_FORM"
};

export const PRODUCT_DOCUMENT_TYPES_TITLE_CASE = {
  IVR_FORM: "Ivr Form",
  ORDER_FORM: "Order Form",
  SERVICE_AGREEMENT: "Service Agreement",
  TISSUE_TRACKING_FORM: "Tissue Tracking Form",
  RETURN_FORM: "Return Form"
};

export const IVR_STATUS = {
  NEEDS_MORE_INFO : "NEEDS MORE INFO",
  SUBMITTED : "SUBMITTED",
  DENIED : "DENIED",
  VERIFIED : "VERIFIED",
  DRAFTED : "DRAFTED",
  RESPOND: "RESPOND",
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  VIEW: "VIEW",
  NOTE: "NOTE",
  COPY: "COPY",
  DUPLICATE: "DUPLICATE",
  CANCEL: "CANCEL",
  CANCELED: "CANCELED",
  EMAIL_SETTINGS:"EMAIL_SETTINGS"
};
export const AUDIT_ENTITYS={
  IVRAUDIT: "IVRAUDIT",
  ORDERAUDIT: "ORDERAUDIT",
  USERAUDIT:"USERAUDIT",
  PRACTICEAUDIT:"PRACTICEAUDIT",
  MANUFACTUREAUDIT:"MANUFACTUREAUDIT",
}

export const ORDER_ACTIONS = {
  RETURN: "RETURN",
  TRACKING: "TRACKING",
  DETAILS: "DETAILS",
  FULFILL: "FULFILL",
  BILLING: "BILLING",
  REORDER: "REORDER",
  CANCEL: "CANCEL",
};

export const ORDER_STATUS = {
  PLACED : "PLACED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED"
};

export const AGENCY_STATUS = {
  EDIT: "EDIT",
  INACTIVATE: "INACTIVATE"
};

export const PRACTICE_STATUS = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  PREVIEW: "PREVIEW",
  APPROVE: "APPROVE",
  ACHIEVE: "ACHIEVE",
  IMPORT_BAA: "IMPORT_BAA"
}

export const COMMISSION_STATUS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
}

export const INVOICE_STATUS = {
  INVOICE: "INVOICE",
  COMMISSION: "COMMISSION",
  AGENCY: "AGENCY",
  OVERRIDE: "OVERRIDE",
}

export const INVOICE_SEARCH_FIELDS = {
  INVOICE_NUMBER: "INVOICE_NUMBER",
  ORDER_NUMBER: "ORDER_NUMBER",
  INVOICE_STATUS: "INVOICE_STATUS",
  INVOICE_DATE_RANGE: "INVOICE_DATE_RANGE",
  MANUFACTURE: "MANUFACTURE",
  PRACTICE: "PRACTICE",
  AGENCY: "AGENCY",
  V3_PAY_STATUS: "V3_PAY_STATUS",
  AGY_PAY_STATUS: "AGY_PAY_STATUS",
  OVR_AGY_PAY_STATUS: "OVR_AGY_PAY_STATUS",
  COMMISSION_PERIOD: "COMMISSION_PERIOD",
}

export const INVOICE_ACTION={
  CREDIT_MEMO:'CREDIT_MEMO',
  ADD_PAYMENT:'RECORD_PAYMENT',
  EDIT_INVOICE:'EDIT_INVOICE'
}

export const EXPORTABLE_ITEMS = {
  INVOICE: "INVOICE",
  ORDER: "ORDER",
  PRACTICE: "PRACTICE",
  AGREEMENT: "AGREEMENT",
  AGENCY: "AGENCY",
  IVR: "IVR",
  ORDER_SSV: "ORDER_SALES_SHEET_VERSION"
}

export const BAA_STATUS = {
  SIGNED : "SIGNED",
}

export const BAA_CONTRACT_TYPES = {
  PRACTICE : "PRACTICE",
  USER : "USER" 
}

export const BAA_ACTIONS = {
  SIGN : "SIGN",
  PREVIEW : "PREVIEW"
}

export const EXPORT_ACTIONS = {
  EXPORT_SS_DETAIL : "EXPORT_SS_DETAIL",
}

export const PROVIDER_LIST_ACTIONS = {
  CREATE_PROVIDER_USER: "CREATE_PROVIDER_USER",
  VIEW: "VIEW",
}

 export const adminRoleId = 1;
 export const practiceAdminRoleId = 2;
 export const practiceStaffRoleId = 3;
 export const billerRoleId = 4;
 export const agencyAdminRoleId = 5;
 export const accountExecutiveRoleId = 6;
 export const manufacturerAdminRoleId = 7;
 export const manufacturerStaffRoleId = 8;
 export const manufacturerIvrRoleId = 9;
 export const manufacturerServiceId = 10;
 export const providerRoleId = 11;

 export const TWO_FACTOR_METHOD_TYPE_EMAIL = "EMAIL";
export const TWO_FACTOR_METHOD_TYPE_MOBILE = "MOBILE";
export const TWO_FACTOR_METHOD_TYPE_GOOGLE_AUTH = "GOOGLE_AUTH";
export const TWO_FACTOR_METHOD_TYPE_MICROSOFT_AUTH = "MICROSOFT_AUTH";
export const TWO_FACTOR_REGISTRATION = "REGISTRATION";
export const TWO_FACTOR_AUTHENTICATION = "AUTHENTICATION";
export const TWO_FACTOR_VERIFICATION_CODE_LENGTH = 6;
export const TWO_FACTOR_SIGNIN = "REGISTRATION";
export const TWO_FACTOR_VERIFICATION = "VERIFICATION";


export const IVR_STATUS_LIST = "IVRStatus";
export const PRODUCT_AGREEMENT_STATUS_LIST = "AgreementStatus";
export const NOTIFICATION_TYPE_LIST = "notificationType";
export const NOTIFICATION_TYPE = "EMAIL";
export const ACTION_TYPE = "ActionType";
export const ENTITY_TYPE = "EntityName";
export const INVOICE_DUE_DATE = "InvoiceDueDate";
export const INVOICE_DUE_DAYS = "InvoiceDueDays";


export const FETCH_TYPE_SUCCESS = "success";

export const RESPONSE_CODES = {
  SUCCESS_CODE : "V3OK",
  FAILED_CODE : "V3ER001"
};

export const SHIFT_KEY = 9;
export const SHIFT_TEXT = "V3BioMed";

export const MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND = "There is an active agreement already exists for the given inputs.";

export const MSG_NO_AGENCY_FOUND = "No Agency Found";
export const MSG_NO_USER_FOUND = "No User Found";
export const MSG_NOT_AN_ACTIVE_EXECUTIVE = "Given Practice Executive is not active.";
export const MSG_PRACTICE_EXECUTIVE_NOT_FOUND = "No Practice Executive Found";

export const MSG_PRACTICE_BILLER_NOT_FOUND = "No Practice Biller Found";
export const MSG_NOT_AN_ACTIVE_BILLER = "Given Practice Billler is not active.";
export const MSG_BILLER_NOT_FOUND = "No Biller Found";
export const MSG_ORDER_NOT_COMPLETED= "Orders are still pending to be Invoiced.";