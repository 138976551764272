import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import Button from "../../common/Button";
import CustomDatePicker from "../../common/CustomDatePicker";
import { Controller, useForm } from "react-hook-form";
import { ProductItemPriceInfo, ProductItemPriceRequest, ProductItemsPriceInfo, initialValuesOfProductItemPrice } from "../../../redux/slice/product/createProductSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import dayjs, { Dayjs } from "dayjs";
import { Cancel, EditOutlined, ErrorOutlineRounded } from "@mui/icons-material";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import CustomTable from "../../common/CustomTable";
import BorderedSection from "../../common/BorderedSection";

interface ProductItemsPricePriceProps {
  open: boolean;
  onClose: () => void;
  addProductItemsPrice: (productItemPrice: any) => void;
  productItemPriceObject: ProductItemsPriceInfo;
  editPricing: boolean;
  editProduct: boolean;
  rowIndex: number | null;
  inactiveOnly: boolean;
  productItemPriceList: ProductItemPriceInfo[];
  productItemsList: any;
}

type Column = {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let erroredFields: string[] = [];

const ProductItemsPricePopup: React.FC<ProductItemsPricePriceProps> = ({
  open,
  onClose,
  addProductItemsPrice,
  productItemPriceObject,
  editPricing,
  editProduct,
  rowIndex,
  inactiveOnly,
  productItemPriceList,
  productItemsList,
}) => {
  const showInlineErrorMinLength: number = 60;
  const customTopErrorString: string = "-custom top error";

  const { control, handleSubmit, reset, setError, watch, getValues, setValue, formState } = useForm<ProductItemPriceInfo>({ defaultValues: initialValuesOfProductItemPrice });
  const { errors } = formState;
  const [productItemPriceRows, setProductItemPriceRows] = useState<any[]>([]);

  const listPriceComponent = (
    productItem: any,
    index: number
  ): JSX.Element => {
    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
            {/* <Grid item md={6} xs={12}> */}
            <CustomCurrencyField
              label="List Price"
              controllerName={`listPrice${productItem.sku}-${index}`}
              control={control}
              showErrorMinLength={showInlineErrorMinLength}
              rules={{
                required: "List Price is required.",
                validate: {
                  validateAmount: (value: string) => {
                    return validateAmount(value, "Enter a valid List Price.");
                  }
                }
              }}
            />
            {/* </Grid> */}
          </Grid>
        </Grid>
      </>
    );
  }

  const allowablePriceComponent = (
    productItem: any,
    index: number
  ): JSX.Element => {

    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
            {/* <Grid item md={6} xs={12}> */}
            <CustomCurrencyField
              label="Price"
              controllerName={`allowableUnitPrice${productItem.sku}-${index}`}
              control={control}
              showErrorMinLength={showInlineErrorMinLength}
              rules={{
                required: "Price is required.",
                validate: {
                  validateAmount: (value: string) => {
                    return validateAmount(value, "Enter a valid Price.");
                  }
                }
              }}
            />
            {/* </Grid> */}
          </Grid>
        </Grid>
      </>
    );
  }

  const customPriceComponent = (
    productItem: any,
    index: number
  ): JSX.Element => {

    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
            {/* <Grid item md={6} xs={12}> */}
            <CustomCurrencyField
              label="Custom Price"
              controllerName={`customPrice${productItem.sku}-${index}`}
              control={control}
            />
            {/* </Grid> */}
          </Grid>
        </Grid>
      </>
    );
  }

  const errorMessages = useMemo(() => {
    let errorMsgs: JSX.Element[] = [];
    if (errors) {
      let objKeys: string[] = Object.keys(errors);
      let errMsg: string | undefined = undefined;
      if (objKeys && objKeys.length > 0) {
        objKeys.map((key) => {
          // @ts-ignore
          errMsg = errors[key].message;
          if (errMsg && (errMsg.length >= showInlineErrorMinLength || errMsg.includes(customTopErrorString))) {
            errMsg = errMsg.replace(customTopErrorString, "");
            errorMsgs.push(<Box sx={{ display: 'flex', justifyContent: 'flex-start', color: 'error.main' }} >
              <Box sx={{ pr: 0.5, }} ><ErrorOutlineRounded /></Box>
              <Box>{errMsg}</Box>
            </Box>);
          }
        });
      }
    }
    return errorMsgs;
  }, [formState]);

    const options = useMemo(() => {
      return [
        { label: "MAC", value: "MAC" },
        { label: "National", value: "NATIONAL" },
      ]
    }, []);

  const columnsPriceItem: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "sku", label: "sku", minWidth: 100 },
    ]
    columnArray.push(
      { id: "listPrice", label: "List Price", minWidth: 100, align: "center" },
      { id: "allowableUnitPrice", label: "Allowable Price", minWidth: 100, align: "center" }
    );
    columnArray.push({ id: "customPrice", label: "Custom Price", minWidth: 100, align: "center" });
    return columnArray;
  }, [productItemsList]);

  useEffect(() => {
    if (open) {
      // Transform the ProductItemPrices array
      const transformedObject = productItemPriceObject.ProductItemPrices.reduce(
        (acc: any, productItemPriceObject: any, index: number) => {
          const transformedItem = {
            [`allowableUnitPrice${productItemPriceObject?.sku}-${index}`]: productItemPriceObject?.allowableUnitPrice,
            [`customPrice${productItemPriceObject?.sku}-${index}`]: productItemPriceObject?.customPrice,
            [`listPrice${productItemPriceObject?.sku}-${index}`]: productItemPriceObject?.listPrice,
          };
          return { ...acc, ...transformedItem };
        },
        {}
      );

      // Include the effectiveDate if it exists
      if (productItemPriceObject?.ProductItemPrices[0]?.effectiveDate) {
        transformedObject.effectiveDate = productItemPriceObject?.ProductItemPrices[0]?.effectiveDate;
      }

      // Include the inactiveDate if it exists
      if (productItemPriceObject?.ProductItemPrices[0]?.inactiveDate) {
        transformedObject.inactiveDate = productItemPriceObject?.ProductItemPrices[0]?.inactiveDate;
      }

      reset(transformedObject);

    }
    if (!open) {
      setDefaultValues();
    }
  }, [open]);
  

  useEffect(() => {
    setValue("priceType","NATIONAL")
    setProductItemPriceRows((prevRows) => {
      return productItemsList?.map((row: any, index: number) => {
        return {
          ...row,
          listPrice: listPriceComponent(row, index),
          allowableUnitPrice: allowablePriceComponent(row, index),
          customPrice: customPriceComponent(row, index)
        };
      });
    });
  }, [productItemsList])

  const validateOthers = useCallback(() => {

    if (inactiveOnly) {
      return validateInactivate();
    }

    if (!productItemPriceList || productItemPriceList?.length === 0) {
      return true;
    }

    let priceList: ProductItemPriceInfo[] = productItemPriceList;
    if (editPricing) {
      priceList = productItemPriceList.filter((item: ProductItemPriceInfo, index: number) => {
        if (index !== rowIndex) {
          return true;
        }
        return false;
      });
    }

    let fileType: string = getValues('priceType');
    let macId: number = getValues('macId');
    let effectiveDateLocal: Dayjs = dayjs(getValues('effectiveDate'));

    if (editProduct) {
      let currentDate: Dayjs = dayjs(dayjs().format('MM/DD/YYYY'));
      // let sameTypePriceMap: Map<string | number, ProductItemPriceInfo[]> = new Map<string, ProductItemPriceInfo[]>();
      // let sameTypePriceList: ProductItemPriceInfo[] | undefined = [];
      // let mapKey: string | number = "";

      for (let i: number = 0; i < priceList.length; i++) {
        // if (priceList[i].priceType === "NATIONAL") {
        //   mapKey = "NATIONAL";
        // } else {
        //   mapKey = priceList[i].macId;
        // }

        // if (sameTypePriceMap.has(mapKey)) {
        //   sameTypePriceList = sameTypePriceMap.get(mapKey);
        //   sameTypePriceList?.push(priceList[i]);
        // } else {
        //   sameTypePriceList = [];
        //   sameTypePriceList.push(priceList[i]);
        //   sameTypePriceMap.set(mapKey, sameTypePriceList);
        // }

        // if (!dayjs(priceList[i].inactiveDate).isValid()
        //   && (fileType !== priceList[i].priceType || fileType === "NATIONAL" || (fileType === "MAC" && macId === priceList[i].macId))) {
        //   let macName = fileType === "MAC" ? macInfoList?.find(option => option.macId === priceList[i].macId)?.macName : "";
        //   if (currentDate.isBefore(dayjs(priceList[i].effectiveDate))) {
        //     return priceList[i].priceType === "NATIONAL"
        //       ? "A future active National pricing is exists. Please delete it or set a suitable inactive date before adding a new pricing." + customTopErrorString
        //       : fileType === "NATIONAL" ? "Future active Local pricing/pricings is/are exists. Please delete it/them or set (a) suitable inactive date(s) before adding a new pricing." + customTopErrorString
        //         : "A future active " + macName + " pricing is exists. Please delete it or set a suitable inactive date before adding a new pricing." + customTopErrorString;
        //   } else {
        //     return priceList[i].priceType === "NATIONAL"
        //       ? "An active National pricing is exists. Please set a suitable inactive date before adding a new pricing." + customTopErrorString
        //       : fileType === "NATIONAL" ? "An active Local pricing/pricings is/are exists. Please set (a) suitable inactive date(s) before adding a new pricing." + customTopErrorString
        //         : "An active " + macName + " pricing is exists. Please set a suitable inactive date before adding a new pricing." + customTopErrorString;
        //   }
        // }

      }

      for (let i: number = 0; i < priceList.length; i++) {
        if (dayjs(priceList[i].inactiveDate).isValid() && effectiveDateLocal.isValid()
          && (effectiveDateLocal.isSame(dayjs(priceList[i].inactiveDate)) || effectiveDateLocal.isBefore(dayjs(priceList[i].inactiveDate)))
          && (fileType === "NATIONAL" || priceList[i].priceType === "NATIONAL" || macId === priceList[i].macId)) {
          return "Entered effective date is conflicted with a active period of a existing pricing." + customTopErrorString;
        }
      }

    } else {
      for (let i: number = 0; i < priceList.length; i++) {
        if ("MAC" === priceList[i].priceType && fileType === "NATIONAL") {
          erroredFields.push("priceType");
          return "Mac pricing is/are already exists." + customTopErrorString;
        } else if (fileType === priceList[i].priceType && fileType === "NATIONAL") {
          erroredFields.push("priceType");
          return "A National pricing is already exists." + customTopErrorString;
        } else if (macId === priceList[i].macId) {
          erroredFields.push("macId");
          return "This MAC pricing is already exists." + customTopErrorString;
        }
      }
    }
    return true;
  }, [productItemPriceList, watch('priceType'), watch('macId'), watch('effectiveDate')]);

  const validateInactivate = useCallback(() => {
    let fileType: string = getValues('priceType');
    let macId: number = getValues('macId');
    let effectiveDateLocal: Dayjs = dayjs(getValues('effectiveDate'));
    let inactiveDateLocal: Dayjs = dayjs(getValues('inactiveDate'));

    let priceList: ProductItemPriceInfo[] = productItemPriceList;
    if (editPricing) {
      priceList = productItemPriceList.filter((item: ProductItemPriceInfo, index: number) => {
        if (effectiveDateLocal.isBefore(dayjs(item.effectiveDate))) {
          return true;
        }
        return false;
      });
    }

    for (let i: number = 0; i < priceList.length; i++) {
      if (inactiveDateLocal.isValid()
        && (inactiveDateLocal.isSame(dayjs(priceList[i].effectiveDate)) || inactiveDateLocal.isAfter(dayjs(priceList[i].effectiveDate)))
        && (fileType === "NATIONAL" || priceList[i].priceType === "NATIONAL" || macId === priceList[i].macId)) {
        return "Entered inactive date is conflicted with a active period of a existing pricing." + customTopErrorString;
      }
    }

  }, [productItemPriceList, watch('priceType'), watch('macId'), watch('inactiveDate')]);

  const validateEffectiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
      return "Invalid date format."
    }

    return true;
  }, [productItemPriceList, watch('effectiveDate'), watch('macId')]);

  const validateInactiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
      return "Invalid date format."
    }

    if (dayjs(value, "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isAfter(dayjs(value, "MM/DD/YYYY", true))) {
      return "Inactive date should be a past date or euqal to effective date."
    }

    return true;
  }, [productItemPriceList, watch('inactiveDate'), watch('macId')]);

  const validateOptionalInactiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    // if (value !== null) {
    //   if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
    //     return "Invalid date format.";
    //   }
    // }

    if (dayjs(value, "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isAfter(dayjs(value, "MM/DD/YYYY", true))) {
      return "The inactive date should be the same as or after the effective date."
    }

    return true;
  }, [productItemPriceList, watch('inactiveDate'), watch('macId')]);

  const setDefaultValues = useCallback(() => {
    reset();
  }, []);

  const onSubmit = useCallback((data: any) => {
    // Normalize input data keys
    const normalizedData = Object.keys(data).reduce((acc: Record<string, any>, key) => {
      const normalizedKey = key.replace(/\s+/g, ""); // Remove all spaces
      acc[normalizedKey] = data[key];
      return acc;
    }, {});

    // Create a normalized mapping of productItemsList
    const normalizedProductItemsList = productItemsList.reduce((acc: Record<string, any>, item: any) => {
      const normalizedSku = item.sku.replace(/\s+/g, ""); // Normalize the SKU
      acc[normalizedSku] = item; // Map normalized SKU to the product item
      return acc;
    }, {});

    // Build productItems array
    const productItems = Object.keys(normalizedData)
      .filter((key) => key.match(/listPrice[\w\d-_.@]+-\d+/))
      .map((key) => {
        const match = key.match(/listPrice([\w\d-_.@]+)-(\d+)/);
        const sku = match?.[1];
        const index = match?.[2];
        const normalizedSku = sku?.replace(/\s+/g, ""); // Normalize SKU to match normalized mapping
        //@ts-ignore
        const productItem = normalizedProductItemsList[normalizedSku];

        return {
          productItemId: productItem?.productItemId ? parseInt(productItem.productItemId, 10) : null,
          productPriceId: productItem?.productPriceId || null,
          sku: productItem?.sku || null,
          listPrice: normalizedData[`listPrice${sku}-${index}`],
          allowableUnitPrice: normalizedData[`allowableUnitPrice${sku}-${index}`],
          customPrice: normalizedData[`customPrice${sku}-${index}`] || "",
        };
      });

    // Construct the final object
    const obj: { effectiveDate: string; inactiveDate: string; productItems: any[] } = {
      effectiveDate: normalizedData.effectiveDate ? dayjs(normalizedData.effectiveDate).format("MM/DD/YYYY") : "",
      inactiveDate: normalizedData.inactiveDate ? dayjs(normalizedData.inactiveDate).format("MM/DD/YYYY") : "",
      productItems,
    };

    addProductItemsPrice(obj);
  }, [open]);

  const onError = (_errors: any, e: any) => {
    erroredFields.forEach((fieldName: string, index: number) => {
      // @ts-ignore
      setError(fieldName, {});
    });
    erroredFields = [];
  };

  const validateAmount = (value: string, message: string) => {
    let val: number = value !== "" ? parseFloat(value) : 0;
    if (val === 0 || val === 0.0) {
      return message;
    }
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <DialogTitle>{editPricing ? "Update Product Pricing" : "Add Product Pricing"}</DialogTitle>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <DialogContent>
            {errorMessages.length > 0 &&
              <Box pb={1} ml={0.5}>
                {errorMessages && errorMessages}
              </Box>
            }
            <Box p={1}>
              {!inactiveOnly &&
                <>
                  <Grid container spacing={2}>

                    <Grid item md={4} xs={12}>
                      <CustomDatePicker
                        label="Effective Date"
                        openTo="day"
                        controllerName="effectiveDate"
                        control={control}
                        showErrorMinLength={showInlineErrorMinLength}
                        format="MM/DD/YYYY"
                        rules={{
                          required: "Effective Date is required.",
                          validate: {
                            isValidDate: (
                              value:
                                | string
                                | number
                                | Date
                                | dayjs.Dayjs
                                | null
                                | undefined
                            ) =>
                              validateEffectiveDate(value),
                          },
                        }}
                      />
                    </Grid>
                    {editProduct?
                    <Grid item md={4} xs={12}>
                    <CustomDatePicker
                        label="Inactive Date"
                        openTo="day"
                        controllerName="inactiveDate"
                        control={control}
                        showErrorMinLength={showInlineErrorMinLength}
                        format="MM/DD/YYYY"
                        rules={{
                          validate: {
                            isValidDate: (
                              value:
                                | string
                                | number
                                | Date
                                | dayjs.Dayjs
                                | null
                                | undefined
                            ) =>
                              validateOptionalInactiveDate(value),
                          },
                        }}
                      />
                    </Grid>
                    :<></>}
                    <Grid item md={4} xs={12}>
                    <CustomDropdownField
                      label="File Type"
                      name="priceType"
                      options={options}
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      // rules={{ 
                      //   required: "File Type is required.",
                      //   }}
                      disabled={true}
                    />
                  </Grid>
                    <Box mt={3}>
                      <BorderedSection title="Product Item Prices">
                        <CustomTable columns={columnsPriceItem} rows={productItemPriceRows || []}
                          actionComponent={(row, rowIndex) => {
                            console.log("Row:", row, "Index:", rowIndex);
                            return <div>Action Component</div>; // Replace this with your actual component
                          }}
                          disablePagination={true} borderBox={true} emptyText="No Product Item(s) Found" showMenu={true} />
                      </BorderedSection>
                    </Box>
                  </Grid>
                </>
              }

              {watch("productPriceId") && inactiveOnly ?
                <Grid container spacing={2}>
                  <Grid container item spacing={2}>
                    <Grid item md={8} xs={12}>
                      <CustomDatePicker
                        label="Inactive Date"
                        openTo="day"
                        controllerName="inactiveDate"
                        control={control}
                        showErrorMinLength={showInlineErrorMinLength}
                        format="MM/DD/YYYY"
                        rules={{
                          validate: {
                            isValidDate: (
                              value:
                                | string
                                | number
                                | Date
                                | dayjs.Dayjs
                                | null
                                | undefined
                            ) =>
                              validateInactiveDate(value),
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                : <></>
              }

              {/* <Controller
                name="fakeValidationPurposeAttribute"
                control={control}
                rules={{
                  validate: { validateOthers }
                }}
                render={({ field, fieldState }) => {
                  return (<></>)
                }}
              /> */}
            </Box>
          </DialogContent>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <DialogActions>
            <Grid
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              spacing={2}
            >
              <Grid item xs={12} md="auto">
                <Button type="submit" startIcon={<EditOutlined />} fullWidth>{editPricing ? "Update Price Item" : "Add Price Item"}</Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  onClick={onClose}
                  startIcon={<Cancel />}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductItemsPricePopup;