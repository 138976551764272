import { SearchPatientRequest } from "../redux/slice/patient/patientSlice";
import { GET_PATIENT_LIST_BY_USER_ROLE, SEARCH_PATIENT_LIST_URL } from "./URL";
import { http } from "./http";

export const fetchPatientsByUserRole = async () => {
    try {
        const response = await http.get(GET_PATIENT_LIST_BY_USER_ROLE);
        return response;
    } catch (error) {
        throw error;
    }
};

export const patientSearchService = async (patientRequest: SearchPatientRequest) => {
    try {
        const response = await http.post(SEARCH_PATIENT_LIST_URL, patientRequest);
        return response;
    } catch (error) {
        throw error;
    }
};