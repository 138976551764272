import React, { useCallback, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, Grid } from "@mui/material";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { PracticeProviderInfo } from "../../../redux/slice/practice/practiceProvidersSlice";
import CustomNametField from "../../common/CustomNametField";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice, } from "../../../redux/slice/codetableSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { AddCircleOutline, Cancel } from "@mui/icons-material";

interface ProviderProps {
  open: boolean;
  onClose: () => void;
  addProvider: (provider: PracticeProviderInfo) => void;
  onChange: (name: string, value: string) => void;
  practiceProviderObject: PracticeProviderInfo;
  billingType?: string;
}

export const providerInitialState: any = {
  providerId: undefined,
  practiceId: undefined,
  title: "",
  firstName: "",
  lastName: "",
  middleName: "",
  npi: "",
  ptan: "",
  taxId: "",
  email: "",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProviderPopup: React.FC<ProviderProps> = ({
  open,
  onClose,
  addProvider,
  onChange,
  practiceProviderObject,
  billingType,
}) => {
  const [providerInfo, setProviderInfo] = React.useState({
    providerName: "",
    providerNPI: "",
    providerPTAN: "",
  });
  const { control, handleSubmit, reset } = useForm<PracticeProviderInfo>();
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const [personTitleList, setPersonTitleList] = React.useState<CodeTable[]>([]);

  const personTitleListOptions = useMemo(() => {
    return personTitleList.map((item) => ({
      label: item.displayValue,
      value: item.keyName,
    }));
  }, [personTitleList]);

  React.useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      setPersonTitleList(getCodeTableValuesByTableName(allCodeTablesValuesMap, "PersonTitle"));
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  useEffect(() => {
    if (open) {
      reset(practiceProviderObject);
    }
    if (!open) {
      setDefaultValues();
    }
  }, [open]);

  const setDefaultValues = useCallback(() => {
    reset();
  }, []);

  const onSubmit = (data: PracticeProviderInfo) => {
    addProvider(data);
    reset(providerInitialState);
    onClose();
  };

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted
        onClose={onClose} aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Manage Provider</DialogTitle>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={6}>
                  <CustomDropdownField control={control} options={personTitleListOptions} label="Title"
                    name="title" disableSearch={true} />
                </Grid>
                <Grid item md={12}>
                  <CustomNametField control={control} context="" label="Provider" xs={12} md={4} isRequired={true} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextField control={control} controllerName="npi" label="Provider NPI" maxLength={10}
                    rules={billingType === "GROUP" ? {}
                      : {
                        required: "NPI is required.",
                        validate: {
                          customValidation: (value: string) => {
                            if (value?.trim() === "") {
                              return "NPI is required.";
                            }
                          }
                        }
                      }
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextField control={control} controllerName="ptan" label="Provider PTAN" maxLength={10}
                    rules={billingType === "GROUP" ? {}
                      : {
                        required: "PTAN is required.",
                        validate: {
                          customValidation: (value: string) => {
                            if (value?.trim() === "") {
                              return "PTAN is required.";
                            }
                          }
                        }
                      }
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextField control={control} controllerName="taxId" label="Provider Tax ID" maxLength={10}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <CustomTextField control={control} controllerName="email" label="Provider Email"
                    rules={{
                      validate: (value: string) => {
                        if (!value) return true;
                        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value) || "Invalid email address.";
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" sx={{ mt: 1, flexWrap: "wrap" }} spacing={1} >
              <Grid item xs={12} sm="auto">
                <Button type="submit" startIcon={<AddCircleOutline />} fullWidth >
                  Add Provider
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" onClick={onClose} startIcon={<Cancel />} fullWidth >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ProviderPopup;