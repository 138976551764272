import React, { useCallback, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { ProductItemInfo, initialValuesOfProductItem } from "../../../redux/slice/product/createProductSlice";
import { Cancel, EditOutlined } from "@mui/icons-material";
import CustomDatePicker from "../../common/CustomDatePicker";

interface ProductItemProps {
  open: boolean;
  onClose: () => void;
  addProductItem: (productItem: ProductItemInfo, ) => void;
  productItemDetails: ProductItemInfo;
  isEdit: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProductItemPopup: React.FC<ProductItemProps> = ({
  open,
  onClose,
  addProductItem,
  productItemDetails,
  isEdit,
}) => {

  const { control, handleSubmit, reset, watch} = useForm<ProductItemInfo>({defaultValues:initialValuesOfProductItem});

  useEffect(()=>{
    if (open){
      reset(productItemDetails)
    }
    if (!open){
      setDefaultValues();
    }
  },[open]);

  const setDefaultValues = useCallback(()=>{
    reset();
  },[]);

  const onSubmit = useCallback((data: ProductItemInfo) => {
    let obj: ProductItemInfo = {
      ...data,
      allowableUnitPrice: "",
      listPrice: "",
      customPrice: "",
      inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : "",
    }
    addProductItem(obj);
    onClose();
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {isEdit ? "Update Product Item" : "Add Product Item"}
          </DialogTitle>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <CustomTextField
                    label="SKU #"
                    controllerName="sku"
                    control={control}
                    rules={{ required: "SKU # is required." }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextField
                    label="Size"
                    controllerName="size"
                    control={control}
                    rules={{ required: "Size is required." }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextField
                    label="# of Units"
                    controllerName="units"
                    control={control}
                    rules={{ required: "# of Units is required." }}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <CustomTextField
                    label="Description"
                    controllerName="productItemDescription"
                    control={control}
                    rules={{ maxLength: { value: 50, message: "Maximum length of description is 50" }}}
                  />
                </Grid>
                {/* {watch("productItemId") ? (
                  <Grid item md={4} xs={12}>
                    <CustomDatePicker
                      label="Inactive Date"
                      openTo="day"
                      controllerName="inactiveDate"
                      control={control}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                ) : (
                  <></>
                )} */}
              </Grid>
            </Box>
          </DialogContent>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <DialogActions>
            <Grid
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              spacing={2}
            >
              <Grid item xs={12} md="auto">
                <Button type="submit" startIcon={<EditOutlined />} fullWidth>
                  {isEdit ? "Update Product Item" : "Add Product Item"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  onClick={onClose}
                  startIcon={<Cancel />}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductItemPopup;
