import React, { useEffect, useMemo, useState } from 'react'
import { Box, Collapse, Divider, Grid, Pagination, useTheme } from "@mui/material";
import Header from "../../common/Header";
import { AddCircleOutlineOutlined, QrCodeOutlined, SearchOutlined } from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from 'react-hook-form';
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import CustomIconButton from "../../common/CustomIconButton";
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProductsForProductsSearch, fetchProductsList, ProductInfo, productLogo, searchParamsProdcutsList, useProductListReducer } from '../../../redux/slice/product/productListSlice';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import CustomMultipleDropDown from '../../common/CustomMultipleDropDown';
import { sortNames } from '../../../utils/Utility';
import { fetchManufacturersList, showHideLoadingDialog, useCommonReducer } from '../../../redux/slice/commonSlice';
import ProductCard from './ProductCard';
import { tokens } from "../../../theme";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from '../../../redux/slice/codetableSlice';
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from '../../../utils/StorageUtils';
import { selectIsTokenAvailable, useAuthSlice } from '../../../redux/slice/authSlice';
import { CAN_ACCESS_APPLICATION } from '../../../constants/applicationConstants';
import ToggleView from '../../common/ToggleView';
import { useUserReducer } from '../../../redux/slice/userSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface Row {
    productId: number,
    productName: string,
    hcpcsCode: string,
    inactiveDate: string,
    manufacturer: any,
    productLogo: productLogo,
    productCategory: string
}

interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
}

type Option = {
    value: string,
    label: string
};

const ProductsList = () => {

    const ITEMS_PER_PAGE = 20;
    const theme = useTheme();
    const [showCard, setShowCard] = useState(false);
    const { control, handleSubmit, watch, reset, setValue, getValues } = useForm<any>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productSearchList, productsByUserRole } = useProductListReducer();
    const { manufacturerInfoList } = useCommonReducer();
    const [searchCriteria, setSearchCriteria] = useState<searchParamsProdcutsList>({
        productIds: [],
        mfgIds: [],
        status: ['ACTIVE'],
        productCategories: [],
        currentPage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
    });
    const location = useLocation();
    const [isGridView, setIsGridView] = useState(true);
    const colors = tokens(theme.palette.mode);
    const [productCategories, setProductCategories] = React.useState<CodeTable[]>([]);
    const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
    const { userInfo } = useAuthSlice();
    const { userPreferenceData } = useUserReducer();
    const canAccessAddProduct = useSelector((state: RootState) => selectIsTokenAvailable("canAccessAddProduct")(state));

    useEffect(() => {
        const preferredViewMode = userPreferenceData.find(
            (pref: { preferenceName: string }) => pref.preferenceName === "preferredViewMode"
        );
        if (preferredViewMode) {
            if (preferredViewMode.preferenceValue === "GRID") {
                setIsGridView(true);
            } else {
                setIsGridView(false);
            }
        }
    }, [])

    const fetchInitialLists = async () => {
        dispatch(showHideLoadingDialog(true));
        await dispatch(fetchManufacturersList());
        await dispatch(fetchProductsForProductsSearch(true));
        dispatch(showHideLoadingDialog(false));
    }

    useEffect(() => {
        fetchInitialLists();
    }, [dispatch]);

    useEffect(() => {
        let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_SEARCH + "SearchCriteria" + userInfo.userId);
        if ((location.state && location.state.productsListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
            if (location.state && location.state.withSearchHistory) {
                //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
                navigate("/products_list", { state: { productsListSearchCriteria: criteria } });
                return;
            }
            if (criteria?.isGridView === true) {
                setIsGridView(true);
            } else if (criteria?.isGridView === false) {
                setIsGridView(false);
            }
            let gridView = criteria?.isGridView

            let searchCriteria: searchParamsProdcutsList = {
                productIds: [],
                mfgIds: [],
                status: [],
                productCategories: [],
                currentPage: 1,
                itemsPerPage: ITEMS_PER_PAGE,
            };
            if (location.state && location.state.productsListSearchCriteria) {
                searchCriteria = location.state.productsListSearchCriteria;
            } else {
                searchCriteria = criteria ? criteria : searchCriteria;
            }
            setSearchCriteria(searchCriteria);
            setValuesToCriteriaFields(searchCriteria);
            searchProductsList(searchCriteria, gridView);
        } else {
            const initialSearchCriteria = {
                ...searchCriteria
            };
            setSearchCriteria(initialSearchCriteria);
            setValuesToCriteriaFields(searchCriteria);
            const preferredViewMode = userPreferenceData.find(
                (pref: { preferenceName: string }) => pref.preferenceName === "preferredViewMode"
            );
            if (preferredViewMode) {
                searchProductsList(initialSearchCriteria, preferredViewMode.preferenceValue === "GRID"?true:false);
            }else{
                searchProductsList(initialSearchCriteria, true);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
            const sortedProductCategoryList = [
                ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "productCategory"),
            ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
            setProductCategories(sortedProductCategoryList);
        }
    }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

    const setValuesToCriteriaFields = (searchCriteria: searchParamsProdcutsList) => {
        setValue("productIds", searchCriteria.productIds);
        setValue("mfgIds", searchCriteria.mfgIds);
        setValue("productCategories", searchCriteria.productCategories);
        setValue("status", searchCriteria.status);
    }

    const statusOptions = useMemo(() => {
        let options: { label: string, value: any }[] = [
            { value: "ACTIVE", label: "ACTIVE" },
            { value: "INACTIVE", label: "INACTIVE" },
            { value: "TBD", label: "TBD" },
            { value: "INPROGRESS", label: "IN PROGRESS" },
        ];
        setValue("userStatus", ["Active"]);
        return options;
    }, []);

    const productOptions = useMemo(() => {
        let options: Option[] = [];
        if (productsByUserRole && productsByUserRole.length > 0) {
            if (productsByUserRole.length === 1) {
                setValue('productIds', [Number(productsByUserRole[0]?.productId)]);
            }
            const sortedProductList: ProductInfo[] = sortNames(productsByUserRole, 'productName');
            sortedProductList?.map((option) => {
                options.push({ label: option.productName, value: option.productId })
            });
        };
        return options;
    }, [productsByUserRole]);

    const manufacturesList = useMemo(() => {
        let options: { label: string; value: any }[] = [];
        manufacturerInfoList.map((item) => {
            options.push({
                label: item.manufacturerName,
                value: item.manufacturerId,
            });
        });
        return options;
    }, [manufacturerInfoList]);

    const productCategoryOptions = useMemo(() => {
        return productCategories.map(item => ({
            label: item.displayValue,
            value: item.keyName
        }));
    }, [productCategories]);

    const productsData: Row[] = useMemo(() => {
        let products: Row[] = [];
        if (productSearchList?.content) {
            products = productSearchList?.content?.map((product: any) => ({
                productId: product.productId,
                productName: product.productName,
                productDescription: product.productDescription,
                hcpcsCode: product.hcpcsCode,
                inactiveDate: product.inactiveDate,
                manufacturer: product.manufacturer.manufacturerName,
                productLogo: product.productLogo,
                productCategory: product.productCategory,
                productStatus: product.productStatus
            }));
        };
        return products;
    }, [productSearchList]);

    const onEditClick = (productId: string) => {
        navigate("/product?id=" + productId);
    };

    const searchProductsList = (searchCriteria: searchParamsProdcutsList, gridView:boolean) => {
        let combinedObj = {
            ...searchCriteria,
            isGridView: gridView,
        }
        setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_SEARCH + "SearchCriteria" + userInfo.userId, combinedObj);

        let dataToSearch = {
            ...searchCriteria
        }

        if (!dataToSearch.status || dataToSearch.status.length === 0) {
          dataToSearch.status = statusOptions && statusOptions.length > 0 ? statusOptions?.map(status => status.value) : [];
        }

        dispatch(fetchProductsList({ searchParams: dataToSearch }));
    }

    const onSubmit = (data: searchParamsProdcutsList) => {
        const data2: searchParamsProdcutsList = {
            productIds: data.productIds,
            mfgIds: data.mfgIds,
            status: data.status,
            productCategories: data.productCategories,
            currentPage: 1,
            itemsPerPage: ITEMS_PER_PAGE
        }
        setSearchCriteria(data2);

        //Below navigate line call to useEffect and search
        navigate("/products_list", { state: { productsListSearchCriteria: data2 } });
    }

    const handlePagination = (pageNumber: number) => {
        const data = {
            ...searchCriteria,
            currentPage: pageNumber + 1
        }
        setSearchCriteria(data);

        //Below navigate line call to useEffect and search
        navigate("/products_list", { state: { productsListSearchCriteria: data } });
    }

    const columns: Column[] = [
        { id: "productName", label: "Product Name", minWidth: 100 },
        { id: "productDescription", label: "Product Description", minWidth: 120 },
        { id: "manufacturer", label: "Manufacturer", minWidth: 100 },
        { id: "productCategory", label: "Product Category", minWidth: 100 },
        { id: "hcpcsCode", label: "HCPCS Code", minWidth: 100 },
        { id: "productStatus", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100, },
    ];

    const actionComponent = (row: any): JSX.Element => {
        return (
            <>
                <CustomIconButton
                    color="info"
                    size="small"
                    variant="outlined"
                    tooltipMessage="View"
                    tooltipPlacement="top"
                    onClick={() => onEditClick(row.productId)}
                >
                    <QrCodeOutlined fontSize="small" />
                </CustomIconButton>
            </>
        );
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        const data = {
            ...searchCriteria,
            currentPage: newPage + 1
        }
        setSearchCriteria(data);

        //Below navigate line call to useEffect and search
        navigate("/products_list", { state: { productsListSearchCriteria: data } });
    };

    const handleViewChange = (newView: boolean) => {
        setIsGridView(newView);
        let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_SEARCH + "SearchCriteria" + userInfo.userId);
        let combinedObj = {
            ...criteria,
            isGridView: newView,
        }
        setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_SEARCH + "SearchCriteria" + userInfo.userId, combinedObj);
    };

    return (
        <>
            <Box my="8px" ml="12px" mr="10px">
                <Header title="Product List" subtitle="Product Management" />
                <Box>
                    <Collapse in={showCard}>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1.5} mt={-3} mb={-1}>
                                    <Grid item md={2} xs={12}>
                                        <CustomMultipleDropDown label="Product Name" name="productIds" options={productOptions} control={control} />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <CustomMultipleDropDown control={control} name="mfgIds" label="Manufacturer" options={manufacturesList} />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <CustomMultipleDropDown control={control} name="productCategories" label="Product Category" options={productCategoryOptions} />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <CustomMultipleDropDown name="status" control={control} label="Status" options={statusOptions} />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Button fullWidth size="large" color="success" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Collapse>
                </Box>
                <Box mt={2}>
                    <Card>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2}>
                            <Grid item xs={12} md>
                                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                                    {showCard ? "Hide Filters" : "Show Filters"}
                                </Button>
                            </Grid>
                            {canAccessAddProduct &&
                            <Grid item xs={12} md="auto">
                                <Button fullWidth color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/product")} >
                                    Add Products
                                </Button>
                            </Grid>
                            }
                            <Grid item xs={12} md="auto">
                                <ToggleView
                                    isCalendarView={isGridView}
                                    onViewChange={handleViewChange}
                                />
                            </Grid>
                        </Grid>
                        {isGridView ? (
                            <Box sx={{ flexGrow: 1, mt: 2, mb: 5 }}>
                                <Grid container spacing={3}>
                                    {productsData.map((product) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={product.productId}>
                                            <ProductCard title={product.productName} subTitle={product.hcpcsCode}
                                                logo={
                                                    product.productLogo && product.productLogo.content
                                                        ? "data:image/png;base64," + product.productLogo.content : null
                                                }
                                                onClick={() => onEditClick(product.productId.toString())}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid item md={12} xs={12} mt={3}>
                                    <Divider
                                        orientation="horizontal"
                                        variant="middle"
                                        flexItem
                                        sx={{ padding: 0, margin: 0 }}
                                    />
                                </Grid>
                                <Box
                                    sx={{
                                        padding: 1,
                                        bottom: 0,
                                        background: colors.grey[900],
                                    }}
                                >
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md>
                                            <Box display="flex" justifyContent="center">
                                                <Pagination
                                                    variant="outlined"
                                                    shape="rounded"
                                                    count={productSearchList ? productSearchList.totalPages : 0}
                                                    page={searchCriteria.currentPage}
                                                    onChange={(event, value) => handleChangePage(event, value - 1)}
                                                    color={theme.palette.mode === "dark" ? "secondary" : "primary"}
                                                    showFirstButton
                                                    showLastButton
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box display="flex" justifyContent="flex-end">
                                                <label style={{ textWrap: "nowrap", fontSize: "13px", alignSelf: "center" }}>Records : {productSearchList?.totalElements}</label>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        ) : (
                            <Table
                                columns={columns}
                                rows={productsData}
                                actionComponent={(row: any) => actionComponent(row)}
                                totalPages={productSearchList?.totalPages}
                                totalElementText={"Records: " + (productSearchList?.totalElements ? productSearchList?.totalElements : "0")}
                                pageNumber={(thisPage: number) => handlePagination(thisPage)}
                                emptyText="No Product(s) Found"
                                currentPageNumber={searchCriteria.currentPage - 1}
                                showMenu={true}
                            />
                        )}
                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default ProductsList
