import React, { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "../../common/Card";
import Table from "../../common/CustomTable";
import Header from "../../common/Header";
import Button from "../../common/Button";
import DifferenceIcon from '@mui/icons-material/Difference';
import {
    AddCircleOutlineOutlined, AppRegistrationOutlined, DeleteOutlineOutlined, ListAltOutlined, ModeOutlined,
    SearchOutlined, SummarizeOutlined, VisibilityOutlined, EditNote, ViewInArOutlined, ContentCopyOutlined,
    EditNoteOutlined,
    RemoveShoppingCartOutlined,
    PictureAsPdfOutlined,
    TrackChanges
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../common/CustomTextField';
import { useNavigate } from 'react-router-dom';

const TemplateList: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { control, handleSubmit, reset, setValue, getValues } = useForm<any>();
    const [showCard, setShowCard] = useState(false);

    const onSubmit = (data: any) => {
        console.log("%c Line:360 🌰 data", "color:#e41a6a", data);

    }

    return (
        <>
            <Box my="8px" ml="12px" mr="10px">
                <Header title="Template List" subtitle="Template Management" />
                <Collapse in={showCard}>
                    <Card>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1.5} mt={-3} mb={-1}>
                                {/* <Grid item xs={12} sm={4}>
                  <CustomMultipleDropDown name="practiceId" label="Practice"
                    options={practiceOptions} control={control} disabled={practiceOptions?.length === 1} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomMultipleDropDown name="productId" label="Product"
                    options={productOptions} control={control} disabled={productOptions?.length === 1} />
                </Grid> */}
                                <Grid item xs={12} sm={2}>
                                    <CustomTextField controllerName="templateName" label="Template Name" control={control} />
                                </Grid>
                                {/* CS-382 Add IVR Created Date Range Filter By KS */}
                                {/* <Grid item xs={12} sm={3}>
                  <CustomDateRange
                    label="IVR Created Date Range"
                    control={control}
                    controllerName="dateRange"
                    onChange={handleDateRangeChange}
                    dateRangeMonths={6}
                    onClear={isClearDateRange}
                  />
                </Grid> */}
                                <Grid item xs={12} sm={2}>
                                    <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Collapse>
                <Box pt={2}>
                    <Card>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
                            <Grid item xs={12} md>
                                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                                    {showCard ? "Hide Filters" : "Show Filters"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button color="info" startIcon={<AddCircleOutlineOutlined />} fullWidth onClick={()=>{navigate("/template")}} >
                                    Add Template
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Table columns={columns} rows={formattedIVRInfo} pageNumber={handlePagination}
              totalPages={IVRListInfo?.totalPages} totalElementText={"Records: " + (IVRListInfo?.totalElements ? IVRListInfo?.totalElements : "0")}
              actionComponent={(row: IVRInfo) => actionComponent(row)} emptyText="No IVR(s) Found" currentPageNumber={searchCriteria.currentPage - 1} showMenu={true}
              sortableColumns={["ivrNumber", "createdDate", "dateCreated"]}/> */}
                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default TemplateList;