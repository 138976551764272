import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeProvidersById, savePracticeProvider, inactivePracticeProviders, fetchActivePracticeProvidersById, fetchActivePracticeProvidersByIds, fetchPracticeProviderByUserId, fetchAllUsedProviderIds, fetchAllProviderUsedPracticeIds, fetchIsLastProviderByUserId, providerSearchService } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";

export type PracticeProviderInfo = {
    providerId: number;
    practiceId: string;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    npi: string;
    ptan: string;
    taxId: string;
    createdDate: Date;
    inactiveDate: Date;
    billingType?: string;
    locationId?: number; 
    email: string;
  }

export type FetchActiveProvidersParams = {
  productId: string;
  onlyActiveRecords: boolean;
}

export type FetchActiveProvidersListParams = {
  practiceIds: number[];
  onlyActiveRecords: boolean;
}

export type SearchProviderRequest = {
  practiceIds: number[],
  firstName: string,
  lastName: string,
  email: string,
  currentPage: number;
  itemsPerPage: number;
};

export interface PaginationProviderList {
  content: any[];
  totalElements: number;
  totalPages: number;
  size: number;
}

type PracticeProviderState = {
    practiceProvidersInfo?: PracticeProviderInfo[] | null;
    practiceProvidersDataFetchStatus?: LoadingType
    practiceProvidersSaveStatus?: LoadingType
    practiceProvidersInactiveStatus?: LoadingType
    fetchProviderListStatus: LoadingType;
    practiceProviderInfo?: PracticeProviderInfo | null;
    usedPracticeProviderIds: number[];
    allProviderUsedPracticeIds: number[];
    isLastPracticeProvider: boolean;
    searchProviderList: PaginationProviderList | null;
  };

  const initialState: PracticeProviderState = {
    practiceProvidersInfo: undefined,
    practiceProvidersDataFetchStatus: "idle",
    practiceProvidersSaveStatus: "idle",
    practiceProvidersInactiveStatus: "idle",
    fetchProviderListStatus: 'idle',
    practiceProviderInfo: undefined,
    usedPracticeProviderIds: [],
    allProviderUsedPracticeIds: [],
    isLastPracticeProvider: false,
    searchProviderList: null
  };

  export const fetchPracticeProvidersData = createAsyncThunk("fetchPracticeProvidersData", async (practiceId: string) => {
    const response = await fetchPracticeProvidersById(practiceId);
    return response.data;
  });

  export const fetchPracticeProviderByUser = createAsyncThunk("fetchPracticeProviderByUser", async (userId: string) => {
    const response = await fetchPracticeProviderByUserId(userId);
    return response.data;
  });

  export const fetchIsLastProviderByUser = createAsyncThunk("fetchIsLastProviderByUser", async (userId: string) => {
    const response = await fetchIsLastProviderByUserId(userId);
    return response.data;
  });

  export const fetchUsedPracticeProviderIDs = createAsyncThunk("fetchUsedPracticeProviderIDs", async () => {
    const response = await fetchAllUsedProviderIds();
    return response.data;
  });
  
  export const fetchAllProviderUsedPracticeIDs = createAsyncThunk("fetchAllProviderUsedPracticeIDs", async () => {
    const response = await fetchAllProviderUsedPracticeIds();
    return response.data;
  });

  export const fetchActivePracticeProvidersData = createAsyncThunk("fetchActivePracticeProvidersData", async ({ productId, onlyActiveRecords }: FetchActiveProvidersParams) => {
    const response = await fetchActivePracticeProvidersById(productId, onlyActiveRecords);
    return response.data;
  });

  export const fetchActivePracticeProviders = createAsyncThunk("fetchActivePracticeProviders", async ({ practiceIds, onlyActiveRecords }: FetchActiveProvidersListParams) => {
    const response = await fetchActivePracticeProvidersByIds(practiceIds, onlyActiveRecords);
    return response.data;
  });

  export const savePracticeProviders = createAsyncThunk("savePracticeProvider", async (data: PracticeProviderInfo, { rejectWithValue }) => {
    try{
    const response = await savePracticeProvider(data);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const inactivePracticeProvider = createAsyncThunk("inactivePracticeProvider", async (data: any, { rejectWithValue }) => {
    console.log(data);
    try{
    const response = await inactivePracticeProviders(data.providerId, data.inactiveDate);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const searchProviders = createAsyncThunk("searchProviders", async (providerSearchRequest: SearchProviderRequest) => {
    const response = await providerSearchService(providerSearchRequest);
    const v3Response = response.data;
    return v3Response.data;
  });

  const practiceProvidersSlice = createSlice({
    name: "practiceProviders",
    initialState,
    reducers: {
      updatePracticeProvidersInfoObject: (state: PracticeProviderState, action) => {
        state.practiceProvidersInfo = action.payload;
      },
      resetPracticeProvidersInfo : (state) =>{
        state.practiceProvidersInfo = null;
      },
      resetSearchedProviderList : (state) => {
        state.searchProviderList = null;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPracticeProvidersData.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProvidersData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProvidersInfo = action.payload.data;
        })
        .addCase(fetchPracticeProvidersData.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchPracticeProviderByUser.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProviderByUser.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProviderInfo = action.payload.data;
        })
        .addCase(fetchPracticeProviderByUser.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchUsedPracticeProviderIDs.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchUsedPracticeProviderIDs.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.usedPracticeProviderIds = action.payload.data;
        })
        .addCase(fetchUsedPracticeProviderIDs.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchAllProviderUsedPracticeIDs.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchAllProviderUsedPracticeIDs.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.allProviderUsedPracticeIds = action.payload.data;
        })
        .addCase(fetchAllProviderUsedPracticeIDs.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchActivePracticeProvidersData.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchActivePracticeProvidersData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProvidersInfo = action.payload.data;
        })
        .addCase(fetchActivePracticeProvidersData.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(savePracticeProviders.pending, (state) => {
          state.practiceProvidersSaveStatus = "loading";
        })
        .addCase(savePracticeProviders.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersSaveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(savePracticeProviders.rejected, (state, action) => {
          state.practiceProvidersSaveStatus = 'error';
        })
        .addCase(inactivePracticeProvider.pending, (state) => {
          state.practiceProvidersInactiveStatus = "loading";
        })
        .addCase(inactivePracticeProvider.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersInactiveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(inactivePracticeProvider.rejected, (state, action) => {
          state.practiceProvidersInactiveStatus = 'error';
        })
        .addCase(fetchActivePracticeProviders.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchActivePracticeProviders.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProvidersInfo = action.payload.data;
        })
        .addCase(fetchActivePracticeProviders.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchIsLastProviderByUser.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchIsLastProviderByUser.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.isLastPracticeProvider = action.payload.data;
        })
        .addCase(fetchIsLastProviderByUser.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(searchProviders.pending, (state) => {
          state.fetchProviderListStatus = "loading";
        })
        .addCase(searchProviders.fulfilled, (state, action) => {
          state.fetchProviderListStatus = "success";
          state.searchProviderList = action.payload;
        })
        .addCase(searchProviders.rejected, (state, action) => {
          state.fetchProviderListStatus = 'error';
        })
    },
  });

export const { updatePracticeProvidersInfoObject, resetPracticeProvidersInfo, resetSearchedProviderList } = practiceProvidersSlice.actions;
export const usePracticeProvidersSlice = () => useAppSelector((state) => state.practiceProvidersSlice);
export default practiceProvidersSlice.reducer;